import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { styled } from "@material-ui/core/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useState } from "react";
import { ReactComponent as ExpandIcon } from "../../assets/icons/expand.svg";
import { useMobile } from "../../hooks";

const ExpandMoreIcon = (props: any) => <ExpandIcon />;

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    "&.MuiPaper-root": {
      backgroundColor: "transparent",
      background: "transparent",
      marginBottom: "1px",
      // borderBottom: "1px solid rgba(83, 89, 102, 0.60) !important",
    },
    // border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  // height: "180px",
  backgroundColor: "#27282C",
  background: "#27282C",
  position: "relative",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-45deg)",
    transformOrigin: "center",
  },
  // "& .MuiAccordionSummary-content": {
  //   marginLeft: theme.spacing(1),
  // },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "#27282C",
  background: "#27282C",
  // padding: theme.spacing(2),
  // borderTop: "1px solid #c7c8cc",
}));

const HomeQA = () => {
  const { isSmallScreen } = useMobile();

  const [expanded, setExpanded] = useState<string | false>("");

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ mb: isSmallScreen ? 6.25 : 18 }}>
      <Typography
        sx={{
          color: "#C7C8CC",
          fontFamily: "Neue Haas Grotesk Text Pro",
          fontSize: isSmallScreen ? "24px" : "54px",
          fontStyle: "normal",
          fontWeight: "700",
          mb: isSmallScreen ? 2.125 : 3.75,
        }}
        align="center"
      >
        <Trans>Establish a global integrated financial autonomous system based on A.</Trans>
      </Typography>
      <Typography
        sx={{
          color: "#C7C8CC",
          fontFamily: "Neue Haas Grotesk Text Pro",
          fontSize: isSmallScreen ? "12px" : "20px",
          fontStyle: "normal",
          fontWeight: "400",
          mb: isSmallScreen ? 1.25 : 3.75,
          lineHeight: isSmallScreen ? "28px" : "36px",
        }}
        align="center"
      >
        <Trans>
          A combines blockchain technology with the traditional financial system to introduce a new concept. It serves
          as a bridge connecting the entire financial ecosystem and provides a foundational infrastructure and more
          innovative possibilities for numerous financial businesses. In terms of its algorithm mechanism, to maintain
          the stability of its value, each A minted will be transferred from the national treasury in an equivalent
          value of USDT into the reserve pool, which is used to support the value of A and ensure its stability. Based
          on A, a global integrated financial autonomous system is constructed, which includes:
        </Trans>
      </Typography>
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            px: isSmallScreen ? 0 : 1.25,
            ml: isSmallScreen ? "0 !important" : "10px !important",
            height: isSmallScreen ? "149px" : "180px",
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "24px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            <Trans>Loan Agreement</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: isSmallScreen ? "0 !important" : 2,
            mb: isSmallScreen ? 5.625 : 5,
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              (1) Based on the treasury reserve lending agreement: After receiving the collateral, the treasury will
              lend out USDT in a corresponding proportion. 20% of the income generated by the lending agreement will be
              used for platform operations, 30% will be used for weighted distribution by DAO bankers, 50 % is used to
              mint and issue the privacy stablecoin A;
            </Trans>
          </Typography>
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              (2) Lending agreement based on user pledge: Users pledge value coins such as BTC\ETH on the platform, and
              will receive corresponding interest income when closing positions or settling on maturity.
            </Trans>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: "#1A1C1F",
          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
        }}
      />
      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          sx={{
            px: isSmallScreen ? 0 : 1.25,
            ml: isSmallScreen ? "0 !important" : "10px !important",
            height: isSmallScreen ? "149px" : "180px",
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "24px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            <Trans>Gateway protocol</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: isSmallScreen ? "0 !important" : 2,
            mb: isSmallScreen ? 5.625 : 5,
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              Based on the A gateway protocol, global currency exchange and circulation is realized. Users from all
              countries around the world can deposit A or other digital currencies of value, and at the same time obtain
              a bank card with digital assets of equal value. This bank card can be used to withdraw the legal currency
              of the corresponding country from all ATM machines around the world, or through online software and
              offline The flow of transactions among traders. All fees and other income generated from circulation
              transactions are used for platform operation and development.
            </Trans>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: "#1A1C1F",
          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
        }}
      />
      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          sx={{
            px: isSmallScreen ? 0 : 1.25,
            ml: isSmallScreen ? "0 !important" : "10px !important",
            height: isSmallScreen ? "149px" : "180px",
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "24px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            <Trans>A payment</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: isSmallScreen ? "0 !important" : 2,
            mb: isSmallScreen ? 5.625 : 5,
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              Create the world's first anonymous cryptocurrency payment tool, connect to more than 95% of the world's
              payment channels, online payment is aligned with Paypal, and offline payment is combined with the gateway
              protocol to open up bank card payment channels and currency exchange channels in various countries. Users
              who use A to pay can consume, shop, trade, invest, etc. around the world without any barriers.
            </Trans>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: "#1A1C1F",
          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
        }}
      />
      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <AccordionSummary
          aria-controls="panel4d-content"
          id="panel4d-header"
          sx={{
            px: isSmallScreen ? 0 : 1.25,
            ml: isSmallScreen ? "0 !important" : "10px !important",
            height: isSmallScreen ? "149px" : "180px",
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "24px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            <Trans>Perpetual contracts and second contracts</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: isSmallScreen ? "0 !important" : 2,
            mb: isSmallScreen ? 5.625 : 5,
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              The platform has built perpetual contract and second-second contract functional sections to meet the needs
              of more users. 20% of the contract section's handling fees and position income are used for platform
              operation and development, 30% is used for weighted distribution by DAO bankers, and 50% is used for
              casting. Issue privacy stablecoin A.
            </Trans>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: "#1A1C1F",
          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
        }}
      />
      <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
        <AccordionSummary
          aria-controls="panel5d-content"
          id="panel5d-header"
          sx={{
            px: isSmallScreen ? 0 : 1.25,
            ml: isSmallScreen ? "0 !important" : "10px !important",
            height: isSmallScreen ? "149px" : "180px",
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "24px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            <Trans>Coin deposit and financial management agreement</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: isSmallScreen ? "0 !important" : 2,
            mb: isSmallScreen ? 5.625 : 5,
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              Through the currency deposit and financial management agreement, users can store their own platform
              privacy stable currency A, other value stable coins and other value digital currencies on the financial
              management platform, and obtain corresponding monthly and annual returns.
            </Trans>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: "#1A1C1F",
          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
        }}
      />
      <Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
        <AccordionSummary
          aria-controls="panel6d-content"
          id="panel6d-header"
          sx={{
            px: isSmallScreen ? 0 : 1.25,
            ml: isSmallScreen ? "0 !important" : "10px !important",
            height: isSmallScreen ? "149px" : "180px",
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "24px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            <Trans> Financial trading platform</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: isSmallScreen ? "0 !important" : 2,
            mb: isSmallScreen ? 5.625 : 5,
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              The platform supports transactions between A and other financial currencies (such as stocks, bonds,
              futures, etc.). Through this platform, users can easily exchange assets and achieve diversification of the
              financial market.
            </Trans>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: "#1A1C1F",
          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
        }}
      />
      <Accordion expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
        <AccordionSummary
          aria-controls="panel7d-content"
          id="panel7d-header"
          sx={{
            px: isSmallScreen ? 0 : 1.25,
            ml: isSmallScreen ? "0 !important" : "10px !important",
            height: isSmallScreen ? "149px" : "180px",
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "24px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            <Trans>Financial derivatives market</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: isSmallScreen ? "0 !important" : 2,
            mb: isSmallScreen ? 5.625 : 5,
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              Based on the A stablecoin, various financial derivatives can be developed, such as futures, options, etc.
              These derivatives can meet the needs of more users, help users perform more financial operations, and
              improve the depth and breadth of the financial market.
            </Trans>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: "#1A1C1F",
          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
        }}
      />
      <Accordion expanded={expanded === "panel8"} onChange={handleChange("panel8")}>
        <AccordionSummary
          aria-controls="panel8d-content"
          id="panel8d-header"
          sx={{
            px: isSmallScreen ? 0 : 1.25,
            ml: isSmallScreen ? "0 !important" : "10px !important",
            height: isSmallScreen ? "149px" : "180px",
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "24px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            <Trans>RWA tokenization of real-world assets</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: isSmallScreen ? "0 !important" : 2,
            mb: isSmallScreen ? 5.625 : 5,
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              RWA is an innovative financial technology based on the algorithmic stable currency A. It converts physical
              assets into digital tokens based on blockchain technology and introduces the traditional asset market into
              the blockchain and cryptocurrency fields. This digital approach provides investors with a wider range of
              investment opportunities and brings revolutionary changes to the liquidity and trading of physical assets.
            </Trans>
          </Typography>
          {/* <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              For example, physical assets such as real estate, art and jewelry are relatively difficult to move and
              trade. Through RWA, these physical assets can be tokenized and traded and circulated based on the A
              platform.
            </Trans>
          </Typography> */}
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: "#1A1C1F",
          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
        }}
      />
      <Accordion expanded={expanded === "panel9"} onChange={handleChange("panel9")}>
        <AccordionSummary
          aria-controls="panel9d-content"
          id="panel9d-header"
          sx={{
            px: isSmallScreen ? 0 : 1.25,
            ml: isSmallScreen ? "0 !important" : "10px !important",
            height: isSmallScreen ? "149px" : "180px",
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "24px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            <Trans>Metaverse virtual world financial system</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: isSmallScreen ? "0 !important" : 2,
            mb: isSmallScreen ? 5.625 : 5,
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              Each user can obtain the KYC certification of the financial system of the Yuanverse virtual world by
              pledging tokens or holding A certificates, and enjoys the authority to participate in the ecosystem. All
              ecological sectors need to pledge tokens or use A to participate. 20% of the income from each ecological
              sector will be used for the future development of the ecosystem, and 80% will be used as ecological sector
              income dividends. The income dividend rights of each ecological sector will be auctioned, and users can
              use A or a corresponding proportion of tokens to participate in the auction, and then obtain the dividend
              income of the ecological sector. The ecological segment income dividend rights obtained by each user's
              auction will be automatically cast into NFT, which can be freely traded on the privacy NFT exchange in the
              privacy ecosystem.
            </Trans>
          </Typography>
          {/* <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              The top 21 users in each ecological sector's auction quota will form the DAO organization of this
              ecological sector, jointly participate in management operations and future development decisions, and
              obtain more weighted ecological sector revenue dividend rights.
            </Trans>
          </Typography> */}
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: "#1A1C1F",
          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
        }}
      />
      <Accordion expanded={expanded === "panel10"} onChange={handleChange("panel10")}>
        <AccordionSummary
          aria-controls="panel10d-content"
          id="panel10d-header"
          sx={{
            px: isSmallScreen ? 0 : 1.25,
            ml: isSmallScreen ? "0 !important" : "10px !important",
            height: isSmallScreen ? "149px" : "180px",
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "24px" : "32px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            <Trans>Globally integrated financial autonomous DAO organization</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: isSmallScreen ? "0 !important" : 2,
            mb: isSmallScreen ? 5.625 : 5,
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: isSmallScreen ? "14px" : "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "140%",
            }}
          >
            <Trans>
              Based on the comprehensive ranking of community token pledge volume, the top 21 users formed a global
              integrated financial autonomous DAO organization to jointly participate in management operations and
              future development decisions. Each member of the DAO organization pledges a certain amount of A, and
              through smart contract credit, they can obtain a hundred times the capital quota from the treasury. The
              capital quota obtained is used for ecological business such as lending agreement, gateway agreement, etc.,
              and the income obtained from participating in the ecosystem is 20% It will be used for the future
              development of the ecology, and 80% will be the income of DAO members.
            </Trans>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          background: "#1A1C1F",
          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
        }}
      />
    </Box>
  );
};

export default HomeQA;
