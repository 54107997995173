import { Box, Typography } from "@mui/material";
// import "./cardheader.scss";

// (aphex) removed children arg in favor of spread to silence compiler while refactoring parents
const CardHeader = ({ title, ...props }) => {
  return (
    <Box className={`card-header`}>
      <Typography
        sx={{ color: "#C7C8CC", fontFamily: "AGaramond LT", fontSize: "21px", fontStyle: "normal", fontWeight: "700" }}
      >
        {title}
      </Typography>
      {props.children}
    </Box>
  );
};

export default CardHeader;
