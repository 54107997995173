import { Box, Zoom, Paper, Typography, OutlinedInput, InputAdornment, Button } from "@mui/material";
import { Trans, t } from "@lingui/macro";
import React, { useState, useEffect } from "react";
import { useMobile } from "src/hooks";
import { shorten, toolNumber, trim } from "src/helpers";
import { LoadingButton } from "@mui/lab";
import InviteBgLeftMobile from "src/assets/icons/inviteBgLeftMobile.svg";
import InviteBgRightMobile from "src/assets/icons/inviteBgRightMobile.svg";
import { useAppSelector } from "src/hooks";
import InviteBgLeft from "src/assets/icons/inviteBgLeft.svg";
import InviteBgRight from "src/assets/icons/inviteBgRight.svg";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import TabList from "./TabList";
import { useDispatch } from "react-redux";
import { approveMintTUSDB, getMintAmount, getMintData, mintTUSDB, getMintRecordsBetween } from "src/slices/MintSlice";
import { error } from "../../slices/MessagesSlice";
import { getBalances } from "src/slices/AccountSlice";
import { getCommunityData } from "src/slices/CommunitySlice";
import { getMintSupplyData, loadAppDetails } from "src/slices/AppSlice";

const Mint = () => {
  const [mintAmount, setMintAmount] = useState("");
  const { provider, address, chainID, connected, connect } = useWeb3Context();
  const dispatch = useDispatch();

  const { isSmallScreen } = useMobile();
  const ohmBalance = useAppSelector(state => state.account.balances.ohm);
  const mintReward = useAppSelector(state => state.account.reward.mint);
  const turbineBal = useAppSelector(state => state.turbine.turbineBal);
  const destoryAmount = useAppSelector(state => state.mint.destoryAmount);
  const mintStatus = useAppSelector(state => state.mint.mintStatus);
  const isWhiteListed = useAppSelector(state => state.mint.isWhiteListed);

  const ohmAllowanceInMintTUSDB = useAppSelector(state => state.account.ohmAllowanceInMintTUSDB);
  const tusdTotalSupply = useAppSelector(state => state.app.tusdTotalSupply);
  console.log("mint tusdTotalSupply", tusdTotalSupply);
  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });
  useEffect(() => {
    dispatch(getMintRecordsBetween({ networkID: chainID, provider, page: 1, offset: 5 }));

    if (address) {
      dispatch(getCommunityData({ provider, networkID: chainID, address }));
      dispatch(getMintData({ address, provider, networkID: chainID }));
    }
  }, [address]);
  console.log("mint mintStatus", mintStatus);
  console.log("mint isWhiteListed", isWhiteListed);
  const onMintAmountChange = (event: any) => {
    let value = event.target.value.replace(/[^\d.]/g, "");
    if (value < 0) {
      setMintAmount("");
      return;
    }
    setMintAmount(value);
    console.log("onMintAmountChangev", value);
    dispatch(getMintAmount({ amount: value, provider, networkID: chainID }));
  };

  const setMax = () => {
    console.log("setMax");
    if (Number(ohmBalance) <= 0) {
      dispatch(error(t`Insufficient balance`));
      return;
    }
    const maxAmount = ohmBalance;
    setMintAmount(String(maxAmount));

    dispatch(getMintAmount({ amount: ohmBalance, provider, networkID: chainID }));
  };

  const handleApprove = async () => {
    console.log("handleApprove");
    await dispatch(
      approveMintTUSDB({
        address,
        provider,
        networkID: chainID,
        amount: mintAmount,
      }),
    );
  };

  const handleMint = async () => {
    if (mintStatus !== "processing") {
      dispatch(error(t`Please wait for the mint period to start!`));
      return;
    } else if (mintAmount === "") {
      // eslint-disable-next-line no-alert
      return dispatch(error(t`Please enter a value!`));
    } else if (Number(mintAmount) < 0) {
      return dispatch(error(t`Please enter a valid value!`));
    } else if (Number(mintAmount) > Number(ohmBalance)) {
      return dispatch(error(t`Insufficient balance!`));
    }
    await dispatch(
      mintTUSDB({
        address,
        amount: mintAmount,
        provider,
        networkID: chainID,
      }),
    );
    await dispatch(
      getBalances({
        address,
        provider,
        networkID: chainID,
      }),
    );
    await dispatch(
      getMintData({
        address,
        provider,
        networkID: chainID,
      }),
    );
    await dispatch(
      getMintSupplyData({
        provider,
        networkID: chainID,
      }),
    );
    dispatch(getMintRecordsBetween({ networkID: chainID, provider, page: 1, offset: 5 }));

    setMintAmount("");
    dispatch(getMintAmount({ amount: "0", provider, networkID: chainID }));
    // dispatch(
    //   loadAppDetails({
    //     provider,
    //     networkID: chainID,
    //   }),
    // );
  };

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        pt: isSmallScreen ? 2.5 : 0,
        px: isSmallScreen ? 1.875 : 0,
      }}
    >
      <Zoom in={true} timeout={1000}>
        <Paper
          className="ohm-card linear-card"
          sx={{
            mb: isSmallScreen ? 1.875 : 3.125,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
              overflow: "hidden",
              borderRadius: isSmallScreen ? "12px" : "24px",
              background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
              // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
            }}
          >
            <Box
              sx={{ py: 5.25 }}
              display="flex"
              alignItems={"center"}
              justifyContent="center"
              flexDirection={"column"}
            >
              <Box sx={{ mb: 0.75 }}>
                <Typography
                  align="center"
                  sx={{
                    color: "#868B8F",
                    fontFamily: "AGaramond LT",
                    fontSize: "26px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  <Trans>$8</Trans>(DAI)
                </Typography>
              </Box>
              <Box sx={{}}>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "40px",
                    fontStyle: "normal",
                    fontWeight: "700",
                  }}
                >
                  {trim(tusdTotalSupply, 4)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                backgroundImage: isSmallScreen ? `url(${InviteBgLeftMobile})` : `url(${InviteBgLeft})`,
                width: isSmallScreen ? "139px" : "298px",
                height: isSmallScreen ? "207px" : "222px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                backgroundImage: isSmallScreen ? `url(${InviteBgRightMobile})` : `url(${InviteBgRight})`,
                width: isSmallScreen ? "139px" : "298px",
                height: isSmallScreen ? "207px" : "222px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            />
          </Box>
        </Paper>
      </Zoom>
      {/* casting tusdb */}
      {/* {mintStatus === "processing" && ( */}
      <Zoom in={true} timeout={1000}>
        <Paper
          className="ohm-card linear-card"
          sx={{
            mb: isSmallScreen ? 1.875 : 3.125,
          }}
        >
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: isSmallScreen ? "12px" : "24px",
              background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
              // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
              pt: 4,
            }}
            // sx={{ pt: 5.75, pb: 3.75 }}
          >
            <Box sx={{ mb: 3.75 }}>
              <Typography
                sx={{
                  color: "#868B8F",
                  fontFamily: "AGaramond LT",
                  fontSize: isSmallScreen ? "18px" : "26px",
                  fontStyle: "normal",
                  fontWeight: "400",
                }}
                align="center"
              >
                <Trans>Casting A</Trans>
              </Typography>
            </Box>
            {/* <Box
              sx={{
                width: "100%",
                height: "1px",
                background: "#0B0C0A",
                boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                mb: 5.75,
              }}
            /> */}
            <Box
              sx={{ px: isSmallScreen ? 2.5 : 3.75, width: "100%" }}
              display={"flex"}
              flexDirection={"column"}
              // alignItems="center"
              justifyContent="center"
            >
              <Box display="flex" sx={{ mb: 1.25 }}>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  <Trans>Destruction and Minting of Privacy Stablecoin A with LGNS Tokens</Trans>
                </Typography>
              </Box>
              <Box display="flex" sx={{ width: "100%", mb: 0.75 }}>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  <Trans>Balance:</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  {trim(Number(ohmBalance), 4)}LGNS
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection={isSmallScreen ? "column" : "row"}
                justifyContent={"space-between"}
                sx={{ mb: 6.25 }}
              >
                <Box sx={{ width: "100%", mr: isSmallScreen ? 0 : 2.5, mb: isSmallScreen ? 2.5 : 0 }}>
                  <OutlinedInput
                    value={mintAmount}
                    onChange={onMintAmountChange}
                    type="number"
                    sx={{
                      height: "42px",
                      borderRadius: "60px",
                      border: "1px solid rgba(138, 147, 160, 0.50)",
                      background: "#3C3F48",
                      color: "#c7c8cc",
                      width: "100%",
                      mb: 1.25,
                      boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#B50C05 !important",
                        borderWidth: "1px !important",
                      },
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <Button
                          variant="text"
                          sx={{
                            color: "#B50C05",
                            fontFamily: "Euclid Square",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            "&:hover": {
                              background: "none",
                            },
                          }}
                          onClick={setMax}
                        >
                          <Trans>Max</Trans>
                        </Button>
                      </InputAdornment>
                    }
                  />
                  <Box sx={{ width: "100%" }} display={"flex"} justifyContent={"flex-end"}>
                    <Typography
                      sx={{
                        color: "#C7C8CC",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      <Trans>You will get:</Trans>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#C7C8CC",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      {trim(destoryAmount, 2)}A
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: isSmallScreen ? "100%" : "auto",
                  }}
                >
                  {!connected ? (
                    <LoadingButton
                      variant="contained"
                      onClick={connect}
                      className="main-btn"
                      sx={{
                        width: isSmallScreen ? "100%" : "240px",
                        height: "40px",
                        borderRadius: "30px",
                        border: "1.3px solid #B50C05",
                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#C7C8CC",
                          fontFamily: "AGaramond LT",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "700",
                        }}
                      >
                        <Trans>Connect Wallet</Trans>
                      </Typography>
                    </LoadingButton>
                  ) : (
                      mintAmount
                        ? Number(ohmAllowanceInMintTUSDB) > Number(mintAmount)
                        : Number(ohmAllowanceInMintTUSDB) > 0
                    ) ? (
                    <LoadingButton
                      variant="contained"
                      className="main-btn"
                      onClick={handleMint}
                      disabled={isPendingTxn(pendingTransactions, "mint_tusdb")}
                      loading={isPendingTxn(pendingTransactions, "mint_tusdb")}
                      loadingPosition="end"
                      sx={{
                        width: isSmallScreen ? "100%" : "240px",
                        height: "40px",
                        borderRadius: "30px",
                        border: "1.3px solid #B50C05",
                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#C7C8CC",
                          fontFamily: "AGaramond LT",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "700",
                        }}
                      >
                        {txnButtonText(pendingTransactions, "mint_tusdb", t`Casting`)}
                        {/* <Trans>Silence</Trans> */}
                      </Typography>
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      variant="contained"
                      className="main-btn"
                      onClick={handleApprove}
                      disabled={isPendingTxn(pendingTransactions, "approve_mint_tusdb")}
                      loading={isPendingTxn(pendingTransactions, "approve_mint_tusdb")}
                      loadingPosition="end"
                      sx={{
                        width: isSmallScreen ? "100%" : "240px",
                        height: "40px",
                        borderRadius: "30px",
                        border: "1.3px solid #B50C05",
                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#C7C8CC",
                          fontFamily: "AGaramond LT",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "700",
                        }}
                      >
                        {txnButtonText(pendingTransactions, "approve_mint_tusdb", t`Approve`)}
                        {/* <Trans>Approve</Trans> */}
                      </Typography>
                    </LoadingButton>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Zoom>
      {/* )} */}
      <TabList />
    </Box>
  );
};

export default Mint;
