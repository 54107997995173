import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses } from "../constants";
import TurbineJson from "../abi/Turbine.json";
import TUSDBJson from "../abi/TUSDB.json";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { setAll } from "../helpers";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import ierc20Json from "../abi/IERC20.json";
import { error, info } from "./MessagesSlice";
import { FuseProxy, IERC20, SOhmv2, WsOHM, OlympusStakingv2 } from "src/typechain";
import { trim } from "../helpers";
import { erc20ABI, useAccount } from "wagmi";
import { t } from "@lingui/macro";
import { fetchAccountSuccess, getBalances } from "./AccountSlice";
import { loadAppDetails } from "./AppSlice";
import dayjs from "dayjs";
const TurbineAbi = TurbineJson.abi;
const ierc20ABI = ierc20Json.abi;
const tusdbAbi = TUSDBJson.abi;

export const getMintData = createAsyncThunk(
  "mint/getMintData",
  async ({ address, networkID, provider }: any, { dispatch }: any) => {
    const tusdbContract = new ethers.Contract(addresses[networkID].TUSDB_ADDRESS as string, tusdbAbi, provider) as any;
    try {
      //  get currentPeriod
      const currentPeriod = await tusdbContract.currentPeriod();
      console.log("currentPeriod", currentPeriod);
      // get mintInfos from currentPeriod
      const mintInfos = await tusdbContract.mintInfos(currentPeriod);
      console.log("currentPeriod mintInfos", mintInfos);
      // 判断是否是当前周期 通过当前时间与mintInfos中的startTime和endTime进行比较
      const startTime = mintInfos.startTime;
      const endTime = mintInfos.endTime;
      const currentTime = Math.floor(Date.now() / 1000);
      const isWhiteListedMint = mintInfos.isWhiteListedMint;
      console.log("startTime", startTime, "endTime", endTime, "currentTime", currentTime);
      // currentTime < startTime 说明还未开始
      // currentTime > endTime 说明已经结束
      // startTime < currentTime < endTime 说明正在进行
      let status = "pending",
        isWhiteListed = false;
      if (currentTime < startTime) {
        status = "pending";
      } else if (currentTime > endTime) {
        status = "finished";
      } else {
        status = "processing";
        if (isWhiteListedMint) {
          // status = "whitelist";
          isWhiteListed = await tusdbContract.whiteList(address);
        }
      }
      console.log("status", status);

      return {
        mintStatus: status,
        isWhiteListed,
        // records: castingRecords,
        // recordsLength: mintRecords[0],
      };
    } catch (error) {
      console.log("error", error);
    }
  },
);

export const getMintRecordsBetween = createAsyncThunk(
  "mint/getMintRecordsBetween",
  async ({ networkID, provider, page, offset }: any) => {
    const tusdbContract = new ethers.Contract(addresses[networkID].TUSDB_ADDRESS as string, tusdbAbi, provider) as any;
    // get getMintRecordsLength
    let mintRecordsLength = await tusdbContract.getMintRecordsLength();
    console.log(
      "mintRecordsLength",
      mintRecordsLength,
      Number(mintRecordsLength),
      page,
      offset,

      mintRecordsLength >= offset * page ? mintRecordsLength - offset * page : 0,
      mintRecordsLength >= offset * page
        ? offset
        : page == 1
        ? mintRecordsLength
        : offset * page - mintRecordsLength + 1,
    );
    let mintRecords: any[] = [];
    mintRecordsLength = Number(mintRecordsLength);
    mintRecords = await tusdbContract.mintRecordsBetween(
      mintRecordsLength >= offset * page ? mintRecordsLength - offset * page : 0,
      mintRecordsLength >= offset * page
        ? offset
        : page == 1
        ? mintRecordsLength
        : offset * page - mintRecordsLength + 1,
    );
    console.log("mintRecords", mintRecords);
    const castingRecords = mintRecords[1]
      .map((item: any) => ({
        ...item,
        destoryAmount: ethers.utils.formatUnits(item.destoryAmount, "9"),
        mintAmount: ethers.utils.formatUnits(item.mintAmount, "9"),
        time: dayjs(item.time * 1000).format("YYYY-MM-DD HH:mm:ss"),
      }))
      .reverse();
    console.log("mintRecords", mintRecords);
    return {
      records: castingRecords,
      recordsLength: mintRecords[0],
    };
  },
);

export const getMintAmount = createAsyncThunk("mint/getMintAmount", async ({ amount, networkID, provider }: any) => {
  const tusdbContract = new ethers.Contract(addresses[networkID].TUSDB_ADDRESS as string, tusdbAbi, provider) as any;
  console.log("mint amount", amount, ethers.utils.parseUnits(String(trim(amount, 9)), "9"));
  try {
    const destoryAmount = await tusdbContract.getCurrentPeriodMintAmount(
      ethers.utils.parseUnits(String(trim(amount, 9)), "9"),
    );
    // const silenceAmount = await turbineContract.getSilenceUSDTAmount(String(ethers.utils.parseUnits(amount)));
    console.log("destoryAmount", destoryAmount);

    return {
      destoryAmount: ethers.utils.formatUnits(destoryAmount, "9"),
    };
  } catch (error) {
    console.log("error", error);
  }
});

export const approveMintTUSDB = createAsyncThunk(
  "mint/approveMintTUSDB",
  async ({ provider, networkID, address, amount }: any, { dispatch }: any) => {
    // const { isConnected } = useAccount();
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    // const signer = provider.getSigner();
    const signer = provider;
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    let approveTx;
    try {
      const approveAmount =
        Number(amount) > 100000
          ? ethers.utils.parseUnits(String(trim(amount, 9)), "9")
          : ethers.utils.parseUnits("100000", "9");
      const estimateGas = await ohmContract.estimateGas.approve(addresses[networkID].TUSDB_ADDRESS, approveAmount);
      console.log("estimateGas", estimateGas, estimateGas.add(ethers.utils.parseUnits("100000", "wei")));
      approveTx = await ohmContract.approve(addresses[networkID].TUSDB_ADDRESS, approveAmount, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_mint_tusdb";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
      }
    } catch (e: unknown) {
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }

    const ohmAllowanceInMintTUSDB = await ohmContract.allowance(address, addresses[networkID].TUSDB_ADDRESS);
    return dispatch(
      fetchAccountSuccess({
        ohmAllowanceInMintTUSDB,
      }),
    );
  },
);

export const mintTUSDB = createAsyncThunk(
  "turbine/mintTUSDB",
  async ({ provider, networkID, address, amount }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    const signer = provider;
    const tusdbContract = new ethers.Contract(addresses[networkID].TUSDB_ADDRESS as string, tusdbAbi, signer);
    let silenceTx;
    // console.log("slipperAmount", amount);
    try {
      const estimateGas = await tusdbContract.estimateGas.mint(ethers.utils.parseUnits(String(trim(amount, 9)), "9"));

      // console.log("amount", ethers.utils.parseUnits(String(slipperAmount), "9"), slipperAmount);
      silenceTx = await tusdbContract.mint(ethers.utils.parseUnits(String(trim(amount, 9)), "9"), {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      // console.log("silenceTx", silenceTx);
      const text = "Mint";
      const pendingTxnType = "mint_tusdb";
      if (silenceTx) {
        dispatch(fetchPendingTxns({ txnHash: silenceTx.hash, text, type: pendingTxnType }));
        await silenceTx.wait();
        dispatch(loadAppDetails({ networkID, provider }));
        return;
      }
    } catch (e: unknown) {
      console.log("error", e);
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (silenceTx) {
        dispatch(clearPendingTxn(silenceTx.hash));
      }
    }
  },
);

export const turbineRedeem = createAsyncThunk(
  "turbine/turbineRedeem",
  async ({ provider, networkID, address, id }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    // const signer = provider.getSigner();
    const signer = provider;
    const turbineContract = new ethers.Contract(addresses[networkID].TURBINE_ADDRESS as string, TurbineAbi, signer);
    let silenceTx;
    try {
      const estimateGas = await turbineContract.estimateGas.redeemSilence(address, id);

      silenceTx = await turbineContract.redeemSilence(address, id, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      // console.log("silenceTx", silenceTx);
      const text = "Redeem";
      const pendingTxnType = "turbine_redeem";
      if (silenceTx) {
        dispatch(fetchPendingTxns({ txnHash: silenceTx.hash, text, type: pendingTxnType }));
        await silenceTx.wait();
        return;
      }
    } catch (e: unknown) {
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (silenceTx) {
        dispatch(clearPendingTxn(silenceTx.hash));
      }
    }
  },
);

interface IMintSlice {
  mintStatus: string;
  isWhiteListed: boolean;
  destoryAmount: number;
  records: any[];
  recordsLength: number;
}

const initialState: IMintSlice = {
  mintStatus: "pending",
  isWhiteListed: false,
  destoryAmount: 0,
  records: [],
  recordsLength: 0,
};

const mintSlice = createSlice({
  name: "mint",
  initialState,
  reducers: {
    fetchMintSuccess(state: any, action: { payload: any }) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getMintData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getMintData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getMintData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getMintRecordsBetween.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getMintRecordsBetween.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getMintRecordsBetween.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getMintAmount.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getMintAmount.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getMintAmount.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default mintSlice.reducer;

export const { fetchMintSuccess } = mintSlice.actions;

const baseInfo = (state: RootState) => state.mint;

export const getMintState = createSelector(baseInfo, mint => mint);
