import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import ierc20ABIJson from "../abi/IERC20.json";
import { t } from "@lingui/macro";

import RouterABIJson from "../abi/RouterContract.json";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { error, info } from "./MessagesSlice";
import { IERC20, OlympusStakingv2, StakingHelper } from "src/typechain";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError } from "./interfaces";
import { setAll, formatMoney, getTokenDecimals } from "../helpers";
import { RootState } from "src/store";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { getBalances } from "./AccountSlice";

const ierc20ABI = ierc20ABIJson.abi;
const RouterABI = RouterABIJson.abi;

export const approveSwap = createAsyncThunk(
  "ido/approveSwap",
  async ({ provider, address, networkID, topToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    console.log("approve thunk");
    const signer = provider;
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    // const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const DAIContract = new ethers.Contract(addresses[networkID].USDT_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const USDTContract = new ethers.Contract(
      addresses[networkID].USDT_REAL_ADDRESS as string,
      ierc20ABI,
      signer,
    ) as IERC20;
    console.log("approve thunk 1");

    let approveTx;
    try {
      if (topToken == "LGNS") {
        const estimateGas = await ohmContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "9").toString(),
        );

        approveTx = await ohmContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "9").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      } else if (topToken == "DAI") {
        const estimateGas = await DAIContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000").toString(),
        );
        approveTx = await DAIContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000").toString(),

          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      } else if (topToken == "USDT") {
        const estimateGas = await USDTContract.estimateGas.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "6").toString(),
        );
        approveTx = await USDTContract.approve(
          addresses[networkID].pancakeRouter,
          ethers.utils.parseUnits("1000000000", "6").toString(),

          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      }
      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_swap";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        return;
        // return {
        //   busdAllowance: "2000",
        // };
      }
    } catch (e: unknown) {
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

export const getAmountsOut = createAsyncThunk(
  "swap/getAmountsOut",
  async ({ provider, address, networkID, amountsIn, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    try {
      const signer = provider;
      const routerContract = new ethers.Contract(
        addresses[networkID].pancakeRouter as string,
        RouterABI,
        signer,
      ) as any;
      const decimal = topToken != "LGNS" ? (topToken !== "USDT" ? "18" : "6") : "9";
      const amountIn = ethers.utils.parseUnits(amountsIn, decimal);
      console.log("getAmountsOut amountIn", networkID, amountIn, topToken);
      let amountsOut;

      // const addressArr =
      //   topToken == "LGNS"
      //     ? [addresses[networkID][`OHM_ADDRESS`], addresses[networkID][`USDT_ADDRESS`]]
      //     :bottomToken == "LGNS"?

      //     : [addresses[networkID][`USDT_ADDRESS`], addresses[networkID][`OHM_ADDRESS`]]
      let addressArr = [];
      if (topToken == "LGNS") {
        if (bottomToken == "USDT") {
          addressArr = [
            addresses[networkID][`OHM_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`USDT_REAL_ADDRESS`],
          ];
        } else {
          addressArr = [addresses[networkID][`OHM_ADDRESS`], addresses[networkID][`USDT_ADDRESS`]];
        }
      } else if (bottomToken == "LGNS") {
        if (topToken == "USDT") {
          addressArr = [
            addresses[networkID][`USDT_REAL_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`OHM_ADDRESS`],
          ];
        } else {
          addressArr = [addresses[networkID][`USDT_ADDRESS`], addresses[networkID][`OHM_ADDRESS`]];
        }
      } else {
        if (topToken == "USDT") {
          addressArr = [addresses[networkID][`USDT_REAL_ADDRESS`], addresses[networkID][`USDT_ADDRESS`]];
        } else {
          addressArr = [addresses[networkID][`USDT_ADDRESS`], addresses[networkID][`USDT_REAL_ADDRESS`]];
        }
      }
      console.log("getAmountsOut addressArr", addressArr, routerContract);
      const amounts = await routerContract.getAmountsOut(amountIn, addressArr);
      console.log("avc getAmountsOut amounts", amounts);
      const amount = amounts[amounts.length - 1];

      const decimalOut = bottomToken == "LGNS" ? "9" : bottomToken === "USDT" ? "6" : "18";

      amountsOut = ethers.utils.formatUnits(String(amount), decimalOut);
      console.log("getAmountsOut amountsOut", amountsOut);
      return {
        amountsOut,
      };
    } catch (error) {
      console.log("getAmountsOut error", error);
    }
  },
);

export const clearAmount = createAsyncThunk("swap/clearAmount", async () => {
  return {
    amountsOut: "",
    amountsIn: "",
  };
});

export const getSelectToken = createAsyncThunk(
  "swap/getSelectToken",
  async ({ provider, address, networkID, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider;
    let topBal, bottomBal;
    let tToken = topToken === "LGNS" ? "OHM" : topToken === "USDT" ? "USDT_REAL" : topToken;
    let bToken = bottomToken === "LGNS" ? "OHM" : bottomToken === "USDT" ? "USDT_REAL" : bottomToken;

    console.log(
      "topToken",
      tToken,
      bToken,
      topToken,
      bottomToken,
      addresses[networkID][`${tToken}_ADDRESS`],
      addresses[networkID][`${bToken}_ADDRESS`],
    );

    const topTokenContract = new ethers.Contract(
      addresses[networkID][`${tToken}_ADDRESS`] as string,
      ierc20ABI,
      signer,
    ) as IERC20;

    const bottomTokenContract = new ethers.Contract(
      addresses[networkID][`${bToken}_ADDRESS`] as string,
      ierc20ABI,
      signer,
    ) as IERC20;
    console.log("topToken", topTokenContract, bottomTokenContract);
    const [tDecimals, bDecimals] = await Promise.all([
      getTokenDecimals(addresses[networkID][`${tToken}_ADDRESS`] as string, networkID),
      getTokenDecimals(addresses[networkID][`${bToken}_ADDRESS`] as string, networkID),
    ]);
    console.log("topToken", tDecimals, bDecimals, address);
    try {
      topBal = await topTokenContract.balanceOf(address);
      console.log("topToken topBal", topBal);
      bottomBal = await bottomTokenContract.balanceOf(address);
      console.log("topToken topBal", topBal, bottomBal);
      topBal = ethers.utils.formatUnits(topBal, tDecimals);
      bottomBal = ethers.utils.formatUnits(bottomBal, bDecimals);
      console.log("topToken topBal", topBal, bottomBal);
      return { topBal, bottomBal };
    } catch (error) {
      console.log("topToken err", error);
    }
  },
);

export const swapToken = createAsyncThunk(
  "swap/swapToken",
  async ({ provider, address, networkID, amountsIn, amountsOut, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    // console.log(provider, address, networkID, amountsIn, amountsOut, type, "yt");

    try {
      const slippage: number = 10;
      console.log("avc slippage", slippage);
      const signer = provider;
      const amountIn = ethers.utils.parseUnits(amountsIn, topToken === "LGNS" ? "9" : topToken === "USDT" ? "6" : "18");

      const amountOut = amountsOut * (1 - slippage / 100);
      console.log("amountOut", amountOut);
      // const amountOutMin = ethers.utils.parseUnits(String(amountOut), bottomToken == "LGNS" ? "9" : "18");
      const amountOutMin = ethers.utils.parseUnits(
        String(amountOut.toFixed(6)),
        bottomToken == "LGNS" ? "9" : bottomToken === "USDT" ? "6" : "18",
      );
      const routerContract = new ethers.Contract(
        addresses[networkID].pancakeRouter as string,
        RouterABI,
        signer,
      ) as any;
      let addressArr = [];
      // const addressArr = type
      //   ? [addresses[networkID].DAI_ADDRESS, addresses[networkID].OHM_ADDRESS]
      //   : [addresses[networkID].OHM_ADDRESS, addresses[networkID].DAI_ADDRESS];
      // const addressArr =
      //   topToken == "LGNS"
      //     ? [addresses[networkID][`OHM_ADDRESS`], addresses[networkID][`USDT_ADDRESS`]]
      //     : [addresses[networkID][`USDT_ADDRESS`], addresses[networkID][`OHM_ADDRESS`]];
      if (topToken == "LGNS") {
        if (bottomToken == "USDT") {
          addressArr = [
            addresses[networkID][`OHM_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`USDT_REAL_ADDRESS`],
          ];
        } else {
          addressArr = [addresses[networkID][`OHM_ADDRESS`], addresses[networkID][`USDT_ADDRESS`]];
        }
      } else if (bottomToken == "LGNS") {
        if (topToken == "USDT") {
          addressArr = [
            addresses[networkID][`USDT_REAL_ADDRESS`],
            addresses[networkID][`USDT_ADDRESS`],
            addresses[networkID][`OHM_ADDRESS`],
          ];
        } else {
          addressArr = [addresses[networkID][`USDT_ADDRESS`], addresses[networkID][`OHM_ADDRESS`]];
        }
      } else {
        if (topToken == "USDT") {
          addressArr = [addresses[networkID][`USDT_REAL_ADDRESS`], addresses[networkID][`USDT_ADDRESS`]];
        } else {
          addressArr = [addresses[networkID][`USDT_ADDRESS`], addresses[networkID][`USDT_REAL_ADDRESS`]];
        }
      }
      let approveTx;
      const deadline: number = Number(localStorage.getItem("deadline")) || 5;
      try {
        approveTx = await routerContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
          amountIn,
          amountOutMin,
          addressArr,
          address,
          Date.now() + 1000 * 60 * deadline,
        );

        const text = "Swap";
        const pendingTxnType = "Swap_TOKEN";
        if (approveTx) {
          dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
          await approveTx.wait();
          await dispatch(getBalances({ address, provider, networkID }));
          return;
        }
      } catch (e: unknown) {
        if ((e as any).code == "ACTION_REJECTED") {
          dispatch(error(t`User denied transaction signature.`));
          // dispatch(error((e as any).message));
        } else if (e == "cancel") {
          dispatch(error(t`User denied transaction signature.`));
        } else {
          // dispatch(error((e as any).message));
          dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
        }
        return;
      } finally {
        if (approveTx) {
          dispatch(clearPendingTxn(approveTx.hash));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
  },
);

export interface ISwapSlice {
  busdAllowance: string;
  loading: boolean;
  isWhiteeListed: boolean;
  showBoughtSuccessful: boolean;
  totalAmount: string;
  remaining: string;
  totalSubscription: string;
  axphAllowance: string;
  aXPHBalance: string;
  XPHBalance: string;
  isSubscriptionSuccessful: boolean;
  amountsIn: string;
  amountsOut: string;
  topBal: string;
  bottomBal: string;
}

const initialState: ISwapSlice = {
  busdAllowance: "",
  loading: false,
  isWhiteeListed: false,
  showBoughtSuccessful: false,
  isSubscriptionSuccessful: false,
  totalAmount: "",
  remaining: "",
  totalSubscription: "",
  axphAllowance: "",
  aXPHBalance: "",
  XPHBalance: "",
  amountsIn: "",
  amountsOut: "",
  topBal: "",
  bottomBal: "",
};

const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(swapToken.pending, state => {
        state.loading = true;
      })
      .addCase(swapToken.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(swapToken.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getAmountsOut.pending, state => {
        state.loading = true;
      })
      .addCase(getAmountsOut.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAmountsOut.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getSelectToken.pending, state => {
        state.loading = true;
      })
      .addCase(getSelectToken.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getSelectToken.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(clearAmount.pending, state => {
        state.loading = true;
      })
      .addCase(clearAmount.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(clearAmount.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

export default swapSlice.reducer;
const baseInfo = (state: RootState) => state.swap;
export const getIdoState = createSelector(baseInfo, swap => swap);
