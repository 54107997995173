import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses } from "../constants";
import StakeExAbi from "../abi/stakeex.json";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { setAll } from "../helpers";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import ierc20Json from "../abi/IERC20.json";
import { error, info } from "./MessagesSlice";
import { FuseProxy, IERC20, SOhmv2, WsOHM, OlympusStakingv2 } from "src/typechain";
import { trim } from "../helpers";
import { useAccount } from "wagmi";
import { t } from "@lingui/macro";
import { fetchAccountSuccess, getBalances } from "./AccountSlice";
import { loadAppDetails } from "./AppSlice";
import { formatUnits } from "@ethersproject/units";

const ierc20ABI = ierc20Json.abi;

export const getStakeExInfo = createAsyncThunk(
    "stakeex/getStakeExInfo",
    async ({ address, networkID, provider }: any) => {
        try {
            const stakeExContract = new ethers.Contract(
                addresses[networkID].STAKING_EX_ADDRESS as string,
                StakeExAbi,
                provider,
            ) as any;

            const unitCost = await stakeExContract.unitCost();
            const period = await stakeExContract.period();
            const available = await stakeExContract.available();
            const stock = await stakeExContract.stock();

            return {
                unitCost: Number(unitCost),
                stock,
                available: Number(available),
                period: Number(period),
                allowanceDAI: 0,
                // turbineBal: ethers.utils.formatUnits(turbineBal, "9"),
                stakeList: [],
            };
        } catch (error) {
            console.log("[DEBUG]stakex error", error);
        }
    },
)

export const getStakeExData = createAsyncThunk(
    "stakeex/getStakeExData",
    async ({ address, networkID, provider }: any) => {
        try {
            const stakeExContract = new ethers.Contract(
                addresses[networkID].STAKING_EX_ADDRESS as string,
                StakeExAbi,
                provider,
            ) as any;
            const daiContract = new ethers.Contract(
                addresses[networkID].DAI_ADDRESS as string,
                ierc20ABI,
                provider,
            ) as IERC20;
            const unitCost = await stakeExContract.unitCost();
            const period = await stakeExContract.period();
            const available = await stakeExContract.available();
            const stock = await stakeExContract.stock();
            const stakeExCount = await stakeExContract.getStakesCount(address);


            const allowanceDAI = await daiContract.allowance(address, addresses[networkID].STAKING_EX_ADDRESS);

            let claimList = [];
            if (stakeExCount > 0) {
                const works = [];
                for (let i = 0; i < stakeExCount; i++) {
                    works.push(stakeExContract.getStakeInfo(address, i).then((ret: any) => {
                        return {
                            quantity: ret.quantity,
                            deposit: formatUnits(ret.deposit, 9),
                            expiry: Number(ret.expiry),
                            id: i
                        }
                    }));
                }
                claimList = await Promise.all(
                    works
                );
            }

            return {
                unitCost: Number(unitCost),
                stock,
                available: Number(available),
                period: Number(period),
                allowanceDAI: ethers.utils.formatUnits(allowanceDAI, "18"),
                // turbineBal: ethers.utils.formatUnits(turbineBal, "9"),
                stakeList: claimList,
            };
        } catch (error) {
            console.log("[DEBUG]stakex error", error);
        }
    },
);

export const exStakeApprove = createAsyncThunk(
    "stakeex/exStakeApprove",
    async ({ provider, networkID, referer, quantity }: any, { dispatch }: any) => {
        if (!provider) {
            dispatch(error(t`Please connect your wallet!`));
            return;
        }
        const daiContract = new ethers.Contract(
            addresses[networkID].DAI_ADDRESS as string,
            ierc20ABI,
            provider,
        ) as IERC20;
        let tx;
        try {
            const estimateGas = await daiContract.estimateGas.approve(
                addresses[networkID].STAKING_EX_ADDRESS,
                ethers.utils.parseUnits("1000000000", "18").toString(),
            );

            tx = await daiContract.approve(
                addresses[networkID].STAKING_EX_ADDRESS,
                ethers.utils.parseUnits("1000000000", "18").toString(),
                {
                    gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
                },
            );
            const text = "StakeExApproving";
            const pendingTxnType = "StakeExApproving";
            if (tx) {
                dispatch(fetchPendingTxns({ txnHash: tx.hash, text, type: pendingTxnType }));
                await tx.wait();
                return;
            }
        } catch (e: unknown) {
            // dispatch(error((e as any).message));
            if ((e as any).code == "ACTION_REJECTED") {
                dispatch(error(t`User denied transaction signature.`));
                // dispatch(error((e as any).message));
            } else if (e == "cancel") {
                dispatch(error(t`User denied transaction signature.`));
            } else {
                // dispatch(error((e as any).message));
                dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
            }
            return;
        } finally {
            if (tx) {
                dispatch(clearPendingTxn(tx.hash));
            }
        }
    },
);


export const exStake = createAsyncThunk(
    "stakeex/exStake",
    async ({ provider, networkID, referer, quantity }: any, { dispatch }: any) => {
        if (!provider) {
            dispatch(error(t`Please connect your wallet!`));
            return;
        }
        const stakeExContract = new ethers.Contract(
            addresses[networkID].STAKING_EX_ADDRESS as string,
            StakeExAbi,
            provider,
        ) as any;
        let tx;
        try {
            const estimateGas = await stakeExContract.estimateGas.stakeDAI(quantity);
            tx = await stakeExContract.stakeDAI(quantity, {
                gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
            });
            const text = "ExStaking";
            const pendingTxnType = "ExStaking";
            if (tx) {
                dispatch(fetchPendingTxns({ txnHash: tx.hash, text, type: pendingTxnType }));
                await tx.wait();
                return;
            }
        } catch (e: unknown) {
            // dispatch(error((e as any).message));
            if ((e as any).code == "ACTION_REJECTED") {
                dispatch(error(t`User denied transaction signature.`));
                // dispatch(error((e as any).message));
            } else if (e == "cancel") {
                dispatch(error(t`User denied transaction signature.`));
            } else {
                // dispatch(error((e as any).message));
                dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
            }
            return;
        } finally {
            if (tx) {
                dispatch(clearPendingTxn(tx.hash));
            }
        }
    },
);



interface IStakeExSlice {
    loading: boolean;
    unitCost: number;
    period: number;
    available: number;
    stock: number;
    allowanceDAI: string;
    stakeList: any[];
}

const initialState: IStakeExSlice = {
    loading: false,
    unitCost: 0,
    period: 0,
    available: 0,
    stock: 0,
    allowanceDAI: '0',
    stakeList: [],
};

const stakeExSlice = createSlice({
    name: "stakeex",
    initialState,
    reducers: {
        fetchTurbineSuccess(state: any, action: { payload: any }) {
            setAll(state, action.payload);
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(getStakeExData.pending, (state: { loading: boolean }) => {
                state.loading = true;
            })
            .addCase(getStakeExData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getStakeExData.rejected, (state: { loading: boolean }, { error }: any) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(getStakeExInfo.pending, (state: { loading: boolean }) => {
                state.loading = true;
            })
            .addCase(getStakeExInfo.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getStakeExInfo.rejected, (state: { loading: boolean }, { error }: any) => {
                state.loading = false;
                console.log(error);
            });
    },
});


export default stakeExSlice.reducer;