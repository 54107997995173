import { styled } from "@material-ui/core";
import { Pagination } from "@mui/material";

export const CustormPagination = styled(Pagination)(() => ({
  "& .MuiPaginationItem-root": {
    borderRadius: "4px",
    color: "#F7F8FA",
    height: "32px",
    border: "1px solid #5F6674",
    background: "linear-gradient(225deg, #535966 0%, #2E3138 96.09%)",
    boxShadow: "0px 2px 8px 0px rgba(26, 28, 31, 0.80)",
    "&.Mui-selected": {
      border: "1px solid #CC5125",
      background: "linear-gradient(180deg, #B8230C 0%, #E95611 100%)",
      boxShadow: "2px 4px 9px 0px #070707, -3px -2px 16px 0px #455059",
    },
  },
}));
