import React from "react";
import { Box, Typography } from "@mui/material";
import HomeData from "./HomeData";
import HomeParticipate from "./HomeParticipate";
import HomeDesc from "./HomeDesc";
import Privacy from "./Privacy";
import HomeQA from "./HomeQA";
import { Trans } from "@lingui/macro";
import Footer from "../../components/Footer/Footer";
import { useMobile } from "../../hooks";
import HomeDataCardList from "./HomeDataCardList";
import ReactPlayer from "react-player";
import BannerVideo from "../../assets/images/home.mp4";
import BannerBg from "../../assets/images/home.png";
import { useAppSelector } from "../../hooks";
import { toolNumber } from "src/helpers";
import HomeRules from "./HomeRules";
import { PullToRefresh } from "src/components/PullToRefresh";
import { useDispatch } from "react-redux";
import { loadAppDetails } from "src/slices/AppSlice";
import { ethers } from "ethers";
import { DEFAULT_PROVIDER } from "src/hooks/web3Context";

const Home = ({ handleScrollTop }: any) => {
  const dispatch = useDispatch();
  const { isSmallScreen } = useMobile();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const treasuryMarketValue = useAppSelector(state => state.app.treasuryMarketValue);
  const marketValue = useAppSelector(state => state.app.marketValue);
  // const stakingAPY = useAppSelector(state => state.app.stakingAPY);
  const stakingAPY = useAppSelector(state => {
    return state.app.stakingAPY ? toolNumber(state.app.stakingAPY) : 0;
  });
  const tokenBalInStaking = useAppSelector(state => state.app.tokenBalInStaking);
  const trimmedStakingAPY = stakingAPY * 100;

  return (
    <PullToRefresh onRefresh={async () => {
      const defaultProvider = DEFAULT_PROVIDER;
      return dispatch(loadAppDetails({ networkID: 137, provider: defaultProvider }))
    }}>


      <Box display={"flex"} flexDirection={"column"} alignItems="center" sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            // maxWidth: "1440px",
            maxWidth: "100%",
            maxHeight: isSmallScreen ? "500px" : "800px",
            left: "50%",
            top: isSmallScreen ? "0" : "-188px",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            transform: "translateX(-50%)",
            zIndex: 0,
            "& .react-player": {
              width: "100% !important",
              height: "100% !important",
              "& video": {
                width: "100% !important",
                height: "100% !important",
                objectFit: "cover",
              },
            },
          }}
        >
          {isSmallScreen ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                background: `url(${BannerBg}) no-repeat center center`,
                backgroundSize: "cover",
              }}
            />
          ) : (
            <ReactPlayer
              url={BannerVideo}
              playing={isPlaying}
              onReady={() => {
                setIsPlaying(true);
              }}
              loop
              muted
              className="react-player"
            />
          )}
        </Box>
        <Box
          sx={{ maxWidth: "1280px", width: "100%", height: "auto", position: "relative", px: isSmallScreen ? 1.875 : 0 }}
        >
          <Box sx={{ height: isSmallScreen ? "290px" : "550px", pt: isSmallScreen ? 10 : 20 }}>
            <Typography
              align="center"
              sx={{
                color: "#EBEBEB",
                textShadow: "0px 4px 4px rgba(0, 0, 0, 0.67)",
                fontFamily: "Neue Haas Grotesk Text Pro",
                fontSize: isSmallScreen ? "30px" : "66px",
                fontStyle: "normal",
                fontWeight: "700",
              }}
            >
              <Trans>Future Privacy Reserve Currency</Trans>
            </Typography>
            <Box
              sx={{
                px: isSmallScreen ? 2.5 : 0,
              }}
            >
              <Typography
                align="center"
                sx={{
                  color: "#EBEBEB",
                  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.67)",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: isSmallScreen ? "15px" : "36px",
                  fontStyle: "normal",
                  fontWeight: "400",
                }}
              >
                <Trans>
                  Establish a global integrated privacy financial autonomous system based on the algorithmically stable
                  coin A.
                </Trans>
              </Typography>
            </Box>
          </Box>
          {isSmallScreen ? (
            <HomeDataCardList
              treasuryMarketValue={treasuryMarketValue}
              stakingAPY={trimmedStakingAPY}
              tokenBalInStaking={tokenBalInStaking}
              marketValue={marketValue}
            />
          ) : (
            <HomeData
              treasuryMarketValue={treasuryMarketValue}
              stakingAPY={trimmedStakingAPY}
              tokenBalInStaking={tokenBalInStaking}
              marketValue={marketValue}
            />
          )}
          {/* How to Participate */}
          <HomeParticipate />
          {/* rule */}
          <HomeRules />
          {/* Privacy Stablecoin A Minting Machine */}

          {/* <HomeDesc /> */}
          {/* Building a Privacy Ecosystem */}
          <Privacy />
          {/* Q & A */}
          <HomeQA />
        </Box>
        <Footer handleScrollTop={handleScrollTop} />
      </Box>
    </PullToRefresh>
  );
};

export default Home;
