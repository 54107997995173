import React from "react";
import {
  Box,
  Paper,
  Zoom,
  Typography,
  Button,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  CircularProgress,
} from "@mui/material";
import { useMobile } from "src/hooks";
import { useDispatch } from "react-redux";
import { useWeb3Context } from "src/hooks/web3Context";
import NoData from "src/assets/images/noData.svg";
import { trim, prettyVestingPeriod } from "src/helpers";
import { t, Trans } from "@lingui/macro";
import { useAppSelector } from "src/hooks";
import { CustormPagination } from "src/components";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";

const diffPeriod = (dest:number)=>{
    
    const curr = Math.trunc(new Date().valueOf()/1000);
    if(dest<=curr)return '00:00:00';

    const d = Math.trunc((dest-curr)/86400);
    const h = Math.trunc((dest-curr-d*86400)/3600);
    const m = Math.trunc((dest-curr-d*86400-h*3600)/60);
    const s = (dest-curr)%60;

    return `${d} ${h}:${m}:${s}`
}


const WebKeyClaimCard = ({ item, handleRedeem }: any) => {
    const currentTimestamp = Math.trunc(new Date().valueOf()/1000);
  const currentBlock = useAppSelector(state => {
    return state.app.currentBlock;
  });
  const vestingPeriod = (vestingBlock: any) => {
    return prettyVestingPeriod(currentBlock as number, vestingBlock);
  };
  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });
  return (
    <Box
      sx={{
        py: 3.75,
      }}
    >
      <Box display="flex" sx={{ width: "100%", mb: 1.875 }} alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            color: "#868B8F",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <Trans>Amount of silences</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          {trim(item?.deposit,4)} LGNS
        </Typography>
      </Box>
      {/* <Box display="flex" sx={{ width: "100%", mb: 1.875 }} alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            color: "#868B8F",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <Trans>Silent Rewards</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          {trim(item?.rewardBalance, 4)} LGNS
        </Typography>
      </Box> */}
      <Box display="flex" sx={{ width: "100%", mb: 1.875 }} alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            color: "#868B8F",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <Trans>Release countdown timer</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          {diffPeriod(item.expiry)}
        </Typography>
      </Box>
      <Box>
        <LoadingButton
          variant="contained"
          // loading={item.loading}
          // disabled={item.loading}
          disabled={
            isPendingTxn(pendingTransactions, "webkey_redeem") || Number(item.expiry) > Number(currentTimestamp)
          }
          // disabled
          loading={isPendingTxn(pendingTransactions, "webkey_redeem")}
          loadingPosition="end"
          onClick={() => handleRedeem(item.id)}
          sx={{
            width: "100%",
            borderRadius: "30px",
            border: "1.3px solid #B50C05",
            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
          }}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "AGaramond LT",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              textTransform: "none",
            }}
          >
            {txnButtonText(pendingTransactions, "webkey_redeem", t`Claim`)}
            {/* <Trans>Claim</Trans> */}
          </Typography>
        </LoadingButton>
      </Box>
    </Box>
  );
};

const WebKeyCaims = ({ claimList, pageCount, handleRedeem, changePage }: any) => {
  const { isSmallScreen } = useMobile();
  const currentBlock = useAppSelector(state => {
    return state.app.currentBlock;
  });
  
  const vestingPeriod = (vestingBlock: any) => {
    return prettyVestingPeriod(currentBlock as number, vestingBlock);
  };
  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });
  return (
    <Zoom in={true} timeout={1000}>
      <Paper
        className="ohm-card linear-card"
        sx={{
          mb: 3.125,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: isSmallScreen ? "12px" : "24px",
            background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
            // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
          }}
        >
          <Box
            sx={{
              px: 3.75,
              py: 2.625,
            }}
            display="flex"
            alignItems="center"
          >
            <Typography
              sx={{
                color: "#C7C8CC",
                fontFamily: "AGaramond LT",
                fontSize: "21px",
                fontStyle: "normal",
                fontWeight: "700",
              }}
            >
              <Trans>List of Receivers</Trans>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "1px",
              background: "#0B0C0A",
              boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
            }}
          />
          <Box sx={{ px: 1.625 }}>
            {isSmallScreen ? (
              !claimList ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress sx={{ color: "#868B8F" }} />
                </Box>
              ) : claimList.length === 0 ? (
                <Box sx={{ height: "300px", width: "100%" }}>
                  <Box
                    sx={{ width: "100%", height: "100%" }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <img src={NoData} alt="No Data" />
                    <Typography
                      sx={{
                        pt: 2,
                        color: "#C7C8CC",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      <Trans>No data</Trans>
                    </Typography>
                  </Box>
                </Box>
              ) : (
                claimList?.map((claimItem: any, index: number) => (
                  <Box key={index}>
                    <WebKeyClaimCard item={claimItem} handleRedeem={handleRedeem} />
                    {index < claimList.length - 1 && (
                      <Box
                        sx={{
                          width: "100%",
                          height: "1px",
                          background: "#0B0C0A",
                          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                        }}
                      />
                    )}
                  </Box>
                ))
              )
            ) : (
              <TableContainer sx={{ minHeight: "330px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          color: "#868B8F",
                          fontFamily: "AGaramond LT",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          borderBottom: "none",
                          p: 1,
                        }}
                      >
                        <Trans>Amount of Staking</Trans>
                      </TableCell>
                      {/* <TableCell
                        align="center"
                        sx={{
                          color: "#868B8F",
                          fontFamily: "AGaramond LT",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          borderBottom: "none",
                          p: 1,
                        }}
                      >
                        <Trans>Silent Rewards</Trans>
                      </TableCell> */}
                      <TableCell
                        align="center"
                        sx={{
                          color: "#868B8F",
                          fontFamily: "AGaramond LT",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          borderBottom: "none",
                          p: 1,
                        }}
                      >
                        <Trans>Release countdown timer</Trans>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          color: "#868B8F",
                          fontFamily: "AGaramond LT",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          borderBottom: "none",
                          p: 1,
                        }}
                      >
                        <Trans>Operation</Trans>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {claimList.length === 0 ? (
                      <TableRow>
                      <TableCell
                        colSpan={4}
                        sx={{
                          height: "300px",
                          borderBottom: "none",
                        }}
                      >
                        <Box
                          sx={{ width: "100%", height: "100%" }}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                        >
                          <img src={NoData} alt="No Data" />
                          <Typography
                            sx={{
                              pt: 2,
                              color: "#C7C8CC",
                              fontFamily: "AGaramond LT",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                            }}
                          >
                            <Trans>No data</Trans>
                          </Typography>
                        </Box>
                      </TableCell>
                      </TableRow>
                    ) : (
                        claimList?.map((claimItem: any) => (
                        <TableRow
                          sx={{
                            height: "56px",
                            cursor: "pointer",
                            "&:hover": {
                              "& > .MuiTableCell-root": {
                                background: "linear-gradient(180deg, #131418 0%, #242528 100%)",
                                "&:first-of-type": {
                                  borderRadius: "16px 0 0 16px",
                                  overflow: "hidden",
                                },
                                "&:last-child": {
                                  borderRadius: "0 16px 16px 0",
                                  overflow: "hidden",
                                },
                              },
                            },
                          }}
                        >
                          <TableCell sx={{ borderBottom: "none", p: 1 }} align="left">
                            <Box display="flex" alignItems={"center"}>
                              <Box sx={{ height: "24px" }}>
                                <Typography
                                  sx={{
                                    color: "#C7C8CC",
                                    fontFamily: "AGaramond LT",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                  }}
                                >
                                  {trim(Number(claimItem?.quantity), 4)} /{trim(claimItem?.deposit,4)} LGNS
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          {/* <TableCell sx={{ borderBottom: "none", p: 1 }} align="center">
                            <Typography
                              sx={{
                                color: "#C7C8CC",
                                fontFamily: "AGaramond LT",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                              }}
                            >
                              {claimItem?.rewardBalance} LGNS
                            </Typography>
                          </TableCell> */}
                          <TableCell sx={{ borderBottom: "none", p: 1 }} align="center">
                            <Box display={"flex"} alignItems="center" justifyContent={"center"}>
                              <Typography
                                sx={{
                                  color: "#898C90",
                                  fontFamily: "AGaramond LT",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                }}
                              >
                                {diffPeriod(claimItem.expiry)}
                              </Typography>
                              {/* <Typography
                          sx={{
                            color: "#898C90",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            mr: 0.5,
                          }}
                        >
                          H
                        </Typography>
                        <Typography
                          sx={{
                            color: "#C7C8CC",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            mr: 0.5,
                          }}
                        >
                          23
                        </Typography>
                        <Typography
                          sx={{
                            color: "#898C90",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            mr: 0.5,
                          }}
                        >
                          M
                        </Typography>
                        <Typography
                          sx={{
                            color: "#C7C8CC",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            mr: 0.5,
                          }}
                        >
                          22
                        </Typography>
                        <Typography
                          sx={{
                            color: "#898C90",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            mr: 0.5,
                          }}
                        >
                          S
                        </Typography>
                        <Typography
                          sx={{
                            color: "#C7C8CC",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                          }}
                        >
                          55
                        </Typography> */}
                            </Box>
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none", p: 1 }} align="right">
                            <LoadingButton
                              variant="contained"
                              disabled={
                                isPendingTxn(pendingTransactions, "webkey_redeem") ||
                                claimItem.turbineRedeemBlock > Number(currentBlock)
                              }
                              loading={isPendingTxn(pendingTransactions, "webkey_redeem")}
                              loadingPosition="end"
                              onClick={() => handleRedeem(claimItem.id)}
                              sx={{
                                borderRadius: "30px",
                                border: "1.3px solid #B50C05",
                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#C7C8CC",
                                  fontFamily: "AGaramond LT",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  textTransform: "none",
                                }}
                              >
                                {txnButtonText(pendingTransactions, "turbine_redeem", t`Claim`)}
                                {/* <Trans>Claim</Trans> */}
                              </Typography>
                            </LoadingButton>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {claimList.length > 0 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  my: 3,
                }}
              >
                <CustormPagination
                  siblingCount={isSmallScreen ? 0 : 1}
                  boundaryCount={1}
                  count={pageCount}
                  onChange={changePage}
                />
              </Box>
            )}
            )
          </Box>
        </Box>
      </Paper>
    </Zoom>
  );
};

export default WebKeyCaims;
