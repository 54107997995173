import { t, Trans } from "@lingui/macro";
import {
    Box,
    Paper,
    Zoom,
    Typography,
    Button,
    OutlinedInput,
    InputAdornment,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { trim, prettyVestingPeriod } from "src/helpers";
import TurbineHeader from "./TurbineHeader";
import NoData from "src/assets/images/noData.svg";
import {
    getTurbineData,
    getSilenceAmount,
    approveTurbine,
    turbineSilence,
    turbineRedeem,
    syncSilenceAmount,
} from "src/slices/TurbineSlice";
import { useDispatch } from "react-redux";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import { useAppSelector } from "src/hooks";
import { getBalances } from "src/slices/AccountSlice";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useMobile } from "src/hooks";
import TurbineClaims from "./TurbineClaims";
import { useAccount } from "wagmi";
import { error } from "../../slices/MessagesSlice";
import useDebounce from "../../hooks/Debounce";
import { TurbineHistory } from "./TurbineHistory";
import { PullToRefresh } from "src/components/PullToRefresh";
import { useAuthContext } from "src/auth/use-auth";
import { SignForm } from "../auth/SignForm";


const Turbine = () => {
    const dispatch = useDispatch();
    const { provider, address, chainID, connected, connect } = useWeb3Context();
    const SECONDS_TO_REFRESH = 60;

    const { isSmallScreen } = useMobile();
    const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);

    const [currentPage, setCurrentPage] = useState(1);
    const [slippage, setSlippage] = useState<number | string>(localStorage.getItem("turbineSlippage") || 0.1);
    const [deadline, setDeadline] = useState<number | string>(localStorage.getItem("turbineDeadline") || 10);
    const [isBtn, setIsBtn] = useState(localStorage.getItem("isBtnTurbine") || "btn");
    const [turbineAmount, setTurbineAmount] = useState("");
    const turbineBal = useAppSelector(state => state.turbine.turbineBal);
    const claimList = useAppSelector(state => state.turbine.claimList);
    
    const usdtAmount = useAppSelector(state => state.turbine.silenceAmount);

    const usdtBal = useAppSelector(state => state.account.balances.usdt);
    const daiBal = useAppSelector(state => state.account.balances.dai);
    const usdtAllowanceInTurbine = useAppSelector(state => state.account.usdtAllowanceInTurbine);
    const isAccountLoading: boolean = useAppSelector(state => state.account.loading);
    // console.log("usdtAllowanceInTurbine", usdtAllowanceInTurbine);
    const pendingTransactions = useAppSelector(state => {
        return state.pendingTransactions;
    });

    useEffect(() => {
        if (address) {
            dispatch(getTurbineData({ address, provider, networkID: chainID }));
        }
    }, [address]);

    const silenceDebounce = useDebounce(turbineAmount, 1000);

    useEffect(() => {
        if (!turbineAmount) return;
        dispatch(getSilenceAmount({ amount: turbineAmount, provider, networkID: chainID }));
    }, [silenceDebounce]);

    useEffect(() => {
        let interval: any = null;
        if (secondsToRefresh > 0) {
            interval = setInterval(() => {
                setSecondsToRefresh(secondsToRefresh => secondsToRefresh - 1);
            }, 1000);
        } else {
            clearInterval(interval);
            dispatch(getSilenceAmount({ amount: turbineAmount, provider, networkID: chainID }));
            setSecondsToRefresh(SECONDS_TO_REFRESH);
        }
        return () => clearInterval(interval);
    }, [secondsToRefresh, turbineAmount]);

    const onSlippageChange = (value: string, isBtn: string): void => {
        if (isBtn == "ipt") {
            if (value == "0.1" || value == "0.5" || value == "1.0") {
                setIsBtn("btn");
                setSlippage(value);
                localStorage.setItem("turbineSlippage", value);
                localStorage.setItem("isBtnTurbine", "btn");
                return;
            } else {
                localStorage.setItem("isBtnTurbine", isBtn);
                localStorage.setItem("turbineSlippage", value);

                setIsBtn(isBtn);
                return setSlippage(value);
            }
        } else {
            localStorage.setItem("isBtnTurbine", isBtn);
            localStorage.setItem("turbineSlippage", value);

            setIsBtn(isBtn);
            return setSlippage(value);
        }
    };

    const onSlippageBlur = (value: any) => {
        if (value <= 0) {
            setIsBtn("btn");
            setSlippage("0.1");
            localStorage.setItem("turbineSlippage", "0.1");
            localStorage.setItem("isBtn", "btn");
        }
    };

    const onTurbineAmountChange = (event: any) => {
        let value = event.target.value.replace(/[^\d.]/g, "");
        if (value < 0) {
            setTurbineAmount("");
            return;
        }
        setTurbineAmount(value);
        // console.log(value);
        dispatch(getSilenceAmount({ amount: value, provider, networkID: chainID }));
    };

    const onDeadlineChange = (value: any) => {
        // console.log(value);
        localStorage.setItem("turbineDeadline", value);
    };

    const setMax = async () => {
        // console.log("slippage", slippage, turbineBal, String(turbineBal * (1 + Number(slippage) / 100)));
        if (Number(turbineBal) <= 0) {
            dispatch(error(t`Insufficient turbine credit`));
            return;
        }
        try {
            const enamount: any = await dispatch(
                getSilenceAmount({ amount: String(1 * (1 + Number(slippage) / 100)), provider, networkID: chainID }),
            )
            // console.log('[debug]turbinesetmax:',enamount);

            const namount = enamount && enamount.payload && enamount.payload.silenceAmount;

            const n2amount = Number(daiBal) / Number(namount);
            const maxAmount = trim(turbineBal, 4);
            const ramount = Math.min(n2amount, Number(maxAmount));

            setTurbineAmount(String(Math.trunc((ramount || 0) * 9990) / 10000));

            dispatch(
                // getSilenceAmount({ amount: String(turbineBal * (1 + Number(slippage) / 100)), provider, networkID: chainID }),
                getSilenceAmount({ amount: String(ramount * (1 + Number(slippage) / 100)), provider, networkID: chainID }),
            );
        }
        catch (err) {

            const maxAmount = trim(turbineBal * (1 + Number(slippage) / 100), 4);
            setTurbineAmount(String(maxAmount));
            dispatch(
                // getSilenceAmount({ amount: String(turbineBal * (1 + Number(slippage) / 100)), provider, networkID: chainID }),
                getSilenceAmount({ amount: String(turbineBal * (1 + Number(slippage) / 100)), provider, networkID: chainID }),
            );
        }

    };

    const handleApprove = async () => {
        // console.log("turbineAmount", turbineAmount);
        await dispatch(
            approveTurbine({
                address,
                provider,
                networkID: chainID,
                amount: turbineAmount,
            }),
        );
    };

    const handleRedeem = async (id: number) => {
        await dispatch(
            turbineRedeem({
                provider,
                networkID: chainID,
                address,
                id,
            }),
        );
        await dispatch(
            getTurbineData({
                address,
                provider,
                networkID: chainID,
            }),
        );
    };

    const handleSilence = async () => {
        // if(Number(usdtAmount) > Number(usdtBal) || usdtAmount < 0) {
        //   dispatch(error({ message: "Insufficient balance" })
        //   return;
        // }

        const r1= await syncSilenceAmount(turbineAmount,chainID,provider);
        const usdtAmount = Number(r1?.silenceAmount);

        console.log('[debug]turbine:',usdtAmount,String(trim(usdtAmount, 18)));
        

        if (isNaN(usdtAmount) || usdtAmount === 0) {
            // eslint-disable-next-line no-alert
            return dispatch(error(t`Please enter a value!`));
        } else if (Number(usdtAmount) < 0) {
            return dispatch(error(t`Please enter a valid value!`));
        } else if (Number(usdtAmount) > Number(daiBal)) {
            return dispatch(error(t`Insufficient balance!`));
        }
        await dispatch(
            turbineSilence({
                address,
                usdtAmount: usdtAmount,
                provider,
                networkID: chainID,
            }),
        );
        await dispatch(
            getBalances({
                address,
                provider,
                networkID: chainID,
            }),
        );
        await dispatch(
            getTurbineData({
                address,
                provider,
                networkID: chainID,
            }),
        );
        setTurbineAmount("");
        dispatch(getSilenceAmount({ amount: "0", provider, networkID: chainID }));
    };

    const changePage = (event: any, page: any) => {
        setCurrentPage(page);
    };

    const currentTurbineList = claimList.slice((currentPage - 1) * 5, currentPage * 5);
    const pageCount = Math.ceil(claimList.length / 5);

    const { login, user }: any = useAuthContext();

    // console.log('[debug]user:', user, address,(user?.address || '').toLowerCase() != address?.toLowerCase());
    
    if (!address) {
        return <Box>
        </Box>
    }
    else if (!user || (user.address || '').toLowerCase() != address.toLowerCase()) {
        return <SignForm/>
    }
  
    return (
        <PullToRefresh onRefresh={async () => {
            const works = [];
            works.push(dispatch(getTurbineData({ address, provider, networkID: chainID })));
            if (turbineAmount) works.push(dispatch(getSilenceAmount({ amount: turbineAmount, provider, networkID: chainID })))
            return await Promise.all(works);

        }}>
            <Box
                display="flex"
                flexDirection={"column"}
                alignItems="center"
                sx={{
                    pt: isSmallScreen ? 2.5 : 0,
                    px: isSmallScreen ? 1.875 : 0,
                }}
            >
                <Zoom in={true} timeout={1000}>
                    <Paper
                        className="ohm-card linear-card"
                        sx={{
                            mb: isSmallScreen ? 1.875 : 3.125,
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                                position: "relative",
                                p: 3.75,
                            }}
                        >
                            <Box
                                sx={{
                                    position: "relative",
                                    zIndex: 1,
                                }}
                                display="flex"
                                flexDirection={isSmallScreen ? "column" : "row"}
                            >
                                <Box
                                    display="flex"
                                    flexDirection={isSmallScreen ? "row" : "column"}
                                    sx={{
                                        width: isSmallScreen ? "100%" : "240px",
                                        mr: isSmallScreen ? 0 : 5.25,
                                        mb: isSmallScreen ? 2 : 0,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            mb: isSmallScreen ? 0 : 2.5,
                                            width: isSmallScreen ? "100%" : "50px",
                                            height: isSmallScreen ? "32px" : "50px",
                                            maxWidth: isSmallScreen ? "32px" : "50px",
                                            borderRadius: "50%",
                                            border: isSmallScreen
                                                ? "1.829px solid rgba(28, 30, 33, 0.60)"
                                                : "2.857px solid rgba(28, 30, 33, 0.60)",
                                            background: "linear-gradient(136deg, #363B40 9.64%, #1D1E23 89.75%)",
                                            boxShadow: isSmallScreen
                                                ? "1.52381px 3.04762px 6.85714px 0px #070707, -2.28571px -1.52381px 12.19048px 0px #455059"
                                                : "2.38095px 4.7619px 10.71428px 0px #070707, -3.57143px -2.38095px 19.04762px 0px #455059",
                                        }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "50%",
                                                background: "linear-gradient(136deg, #131416 9.64%, #32373A 89.75%)",
                                                boxShadow: isSmallScreen
                                                    ? "0px 0px 3.04762px 0px #33383C inset"
                                                    : "0px 0px 4.7619px 0px #33383C inset",
                                            }}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "12.8px" : "20px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                01
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            ml: isSmallScreen ? 1.875 : 0,
                                        }}
                                    >
                                        <Box sx={{ mb: 1.75 }}>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <Trans>Get the turbocharger from the following 3 places</Trans>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: "#84898D",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                <Trans>1. Cobweb Award</Trans>
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#84898D",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                <Trans>2. DAO Community Award</Trans>
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#84898D",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                <Trans>3. Purchase Bond Incentive</Trans>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection={isSmallScreen ? "row" : "column"}
                                    sx={{ width: isSmallScreen ? "100%" : "220px", mr: isSmallScreen ? 0 : 8, mb: isSmallScreen ? 2 : 0 }}
                                >
                                    <Box
                                        sx={{
                                            mb: isSmallScreen ? 0 : 2.5,
                                            width: isSmallScreen ? "100%" : "50px",
                                            height: isSmallScreen ? "32px" : "50px",
                                            maxWidth: isSmallScreen ? "32px" : "50px",
                                            borderRadius: "50%",
                                            border: isSmallScreen
                                                ? "1.829px solid rgba(28, 30, 33, 0.60)"
                                                : "2.857px solid rgba(28, 30, 33, 0.60)",
                                            background: "linear-gradient(136deg, #363B40 9.64%, #1D1E23 89.75%)",
                                            boxShadow: isSmallScreen
                                                ? "1.52381px 3.04762px 6.85714px 0px #070707, -2.28571px -1.52381px 12.19048px 0px #455059"
                                                : "2.38095px 4.7619px 10.71428px 0px #070707, -3.57143px -2.38095px 19.04762px 0px #455059",
                                        }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "50%",
                                                background: "linear-gradient(136deg, #131416 9.64%, #32373A 89.75%)",
                                                boxShadow: isSmallScreen
                                                    ? "0px 0px 3.04762px 0px #33383C inset"
                                                    : "0px 0px 4.7619px 0px #33383C inset",
                                            }}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "12.8px" : "20px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                02
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            ml: isSmallScreen ? 1.875 : 0,
                                        }}
                                    >
                                        <Box sx={{ mb: 1.75, pt: isSmallScreen ? 0.75 : 0 }}>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <Trans>Assistive Turbine</Trans>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: "#84898D",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                <Trans>
                                                    When purchasing LGNS at the corresponding value, the contract will automatically pledge and
                                                    remain silent for 24 hours.
                                                </Trans>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection={isSmallScreen ? "row" : "column"}
                                    sx={{ width: isSmallScreen ? "100%" : "220px", mb: isSmallScreen ? 2 : 0 }}
                                >
                                    <Box
                                        sx={{
                                            mb: isSmallScreen ? 0 : 2.5,
                                            width: isSmallScreen ? "100%" : "50px",
                                            height: isSmallScreen ? "32px" : "50px",
                                            maxWidth: isSmallScreen ? "32px" : "50px",
                                            borderRadius: "50%",
                                            border: isSmallScreen
                                                ? "1.829px solid rgba(28, 30, 33, 0.60)"
                                                : "2.857px solid rgba(28, 30, 33, 0.60)",
                                            background: "linear-gradient(136deg, #363B40 9.64%, #1D1E23 89.75%)",
                                            boxShadow: isSmallScreen
                                                ? "1.52381px 3.04762px 6.85714px 0px #070707, -2.28571px -1.52381px 12.19048px 0px #455059"
                                                : "2.38095px 4.7619px 10.71428px 0px #070707, -3.57143px -2.38095px 19.04762px 0px #455059",
                                        }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "50%",
                                                background: "linear-gradient(136deg, #131416 9.64%, #32373A 89.75%)",
                                                boxShadow: isSmallScreen
                                                    ? "0px 0px 3.04762px 0px #33383C inset"
                                                    : "0px 0px 4.7619px 0px #33383C inset",
                                            }}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "12.8px" : "20px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                03
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            ml: isSmallScreen ? 1.875 : 0,
                                        }}
                                    >
                                        <Box sx={{ mb: 1.75, pt: isSmallScreen ? 0.75 : 0 }}>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <Trans>Claim rewards</Trans>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: "#84898D",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                <Trans>
                                                    The 24-hour silent period has ended. Please claim your staked LGNS and corresponding rewards.
                                                </Trans>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            {!isSmallScreen ? (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 0,
                                        left: "0",
                                        top: "53px",
                                        width: "100%",
                                        height: "1px",
                                        background: "#0B0C0A",
                                        boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                                    }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 0,
                                        left: "42px",
                                        top: "0",
                                        width: "1px",
                                        height: "100%",
                                        background: "#0B0C0A",
                                        boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                                    }}
                                />
                            )}
                        </Box>
                    </Paper>
                </Zoom>
                <Zoom in={true} timeout={1000}>
                    <Paper
                        className="ohm-card linear-card"
                        sx={{
                            mb: isSmallScreen ? 1.875 : 3.125,
                        }}
                    >
                        <Box
                            display={"flex"}
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                            }}
                        // sx={{ pt: 5.75, pb: 3.75 }}
                        >
                            <TurbineHeader
                                slippage={slippage}
                                handleSlippageChange={onSlippageChange}
                                onSlippageBlur={onSlippageBlur}
                                isBtn={isBtn}
                                deadline={deadline}
                                onDeadlineChange={onDeadlineChange}
                            />
                            <Box sx={{ mb: 3.75 }}>
                                <Typography
                                    sx={{
                                        color: "#868B8F",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "18px" : "26px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                    }}
                                    align="center"
                                >
                                    <Trans>Turbocharger</Trans>
                                </Typography>

                                <Typography
                                    sx={{
                                        color: "#C7C8CC",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "28px" : "36px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                    }}
                                >
                                    {trim(turbineBal, 4)} LGNS
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "1px",
                                    background: "#0B0C0A",
                                    boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                                    mb: 5.75,
                                }}
                            />
                            <Box
                                sx={{ px: isSmallScreen ? 2.5 : 3.75, width: "100%" }}
                                display={"flex"}
                                flexDirection={"column"}
                                // alignItems="center"
                                justifyContent="center"
                            >
                                <Box display="flex" sx={{ mb: 1.25 }}>
                                    <Typography
                                        sx={{
                                            color: "#C7C8CC",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}
                                    >
                                        <Trans>Balance:</Trans>
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#C7C8CC",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}
                                    >
                                        {trim(Number(daiBal), 2)} DAI
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection={isSmallScreen ? "column" : "row"}
                                    justifyContent={"space-between"}
                                    sx={{ mb: 6.25 }}
                                >
                                    <Box sx={{ width: "100%", mr: isSmallScreen ? 0 : 2.5, mb: isSmallScreen ? 2.5 : 0 }}>
                                        <OutlinedInput
                                            value={turbineAmount}
                                            onChange={onTurbineAmountChange}
                                            type="number"
                                            sx={{
                                                height: "42px",
                                                borderRadius: "60px",
                                                border: "1px solid rgba(138, 147, 160, 0.50)",
                                                background: "#3C3F48",
                                                color: "#c7c8cc",
                                                width: "100%",
                                                mb: 1.25,
                                                boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#B50C05 !important",
                                                    borderWidth: "1px !important",
                                                },
                                            }}
                                            endAdornment={
                                                <InputAdornment
                                                    position="end"
                                                    sx={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: '12px', color: '#aaa' }}>
                                                        LGNS
                                                    </Typography>
                                                    <Button
                                                        variant="text"
                                                        sx={{
                                                            color: "#B50C05",
                                                            fontFamily: "Euclid Square",
                                                            fontSize: "16px",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                            "&:hover": {
                                                                background: "none",
                                                            },
                                                        }}
                                                        onClick={setMax}
                                                    >
                                                        <Trans>Max</Trans>
                                                    </Button>
                                                </InputAdornment>
                                            }
                                        />
                                        <Box sx={{ width: "100%" }} display={"flex"} justifyContent={"flex-end"}>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: "12px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                <Trans>Need to pay:</Trans>
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: "12px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                }}
                                            >
                                                {trim(usdtAmount, 2)}DAI
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: isSmallScreen ? "100%" : "auto",
                                        }}
                                    >
                                        {!connected ? (
                                            <LoadingButton
                                                variant="contained"
                                                onClick={connect}
                                                className="main-btn"
                                                sx={{
                                                    width: isSmallScreen ? "100%" : "240px",
                                                    height: "40px",
                                                    borderRadius: "30px",
                                                    border: "1.3px solid #B50C05",
                                                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                    boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "#C7C8CC",
                                                        fontFamily: "AGaramond LT",
                                                        fontSize: "18px",
                                                        fontStyle: "normal",
                                                        fontWeight: "700",
                                                    }}
                                                >
                                                    <Trans>Connect Wallet</Trans>
                                                </Typography>
                                            </LoadingButton>
                                        ) :
                                            Number(turbineAmount)
                                                ?
                                                (
                                                    (Number(usdtAllowanceInTurbine) >= Number(usdtAmount)) ?
                                                        (
                                                            <LoadingButton
                                                                variant="contained"
                                                                className="main-btn"
                                                                onClick={handleSilence}
                                                                disabled={isPendingTxn(pendingTransactions, "turbine_silence")}
                                                                loading={isPendingTxn(pendingTransactions, "turbine_silence")}
                                                                loadingPosition="end"
                                                                sx={{
                                                                    width: isSmallScreen ? "100%" : "240px",
                                                                    height: "40px",
                                                                    borderRadius: "30px",
                                                                    border: "1.3px solid #B50C05",
                                                                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                                    boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        color: "#C7C8CC",
                                                                        fontFamily: "AGaramond LT",
                                                                        fontSize: "18px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: "700",
                                                                    }}
                                                                >
                                                                    {txnButtonText(pendingTransactions, "turbine_silence", t`Silence`)}
                                                                    {/* <Trans>Silence</Trans> */}
                                                                </Typography>
                                                            </LoadingButton>
                                                        ) : (
                                                            <LoadingButton
                                                                variant="contained"
                                                                className="main-btn"
                                                                onClick={handleApprove}
                                                                disabled={isPendingTxn(pendingTransactions, "approve_turbine")}
                                                                loading={isPendingTxn(pendingTransactions, "approve_turbine")}
                                                                loadingPosition="end"
                                                                sx={{
                                                                    width: isSmallScreen ? "100%" : "240px",
                                                                    height: "40px",
                                                                    borderRadius: "30px",
                                                                    border: "1.3px solid #B50C05",
                                                                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                                    boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        color: "#C7C8CC",
                                                                        fontFamily: "AGaramond LT",
                                                                        fontSize: "18px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: "700",
                                                                    }}
                                                                >
                                                                    {txnButtonText(pendingTransactions, "approve_turbine", t`Approve`)}
                                                                    {/* <Trans>Approve</Trans> */}
                                                                </Typography>
                                                            </LoadingButton>
                                                        ))
                                                : (
                                                    <LoadingButton
                                                        variant="contained"
                                                        className="main-btn"
                                                        onClick={handleApprove}
                                                        disabled={true}
                                                        loading={isPendingTxn(pendingTransactions, "approve_turbine")}
                                                        loadingPosition="end"
                                                        sx={{
                                                            width: isSmallScreen ? "100%" : "240px",
                                                            height: "40px",
                                                            borderRadius: "30px",
                                                            border: "1.3px solid #B50C05",
                                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: "#C7C8CC",
                                                                fontFamily: "AGaramond LT",
                                                                fontSize: "18px",
                                                                fontStyle: "normal",
                                                                fontWeight: "700",
                                                            }}
                                                        >
                                                            {txnButtonText(pendingTransactions, "turbine_silence", t`Silence`)}
                                                            {/* <Trans>Approve</Trans> */}
                                                        </Typography>
                                                    </LoadingButton>
                                                )
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Zoom>
                <TurbineClaims
                    claimList={claimList}
                    currentTurbineList={currentTurbineList}
                    handleRedeem={handleRedeem}
                    pageCount={pageCount}
                    changePage={changePage}
                />
                <TurbineHistory isSmallScreen={isSmallScreen} />
            </Box>
        </PullToRefresh>
    );
};

export default Turbine;
