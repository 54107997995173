import React from "react";
import {
  Typography,
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Button,
  Skeleton,
} from "@mui/material";
import { useAppSelector } from "src/hooks";
import { t, Trans } from "@lingui/macro";
import { ReactComponent as XIcon } from "src/assets/icons/x.svg";
import { ReactComponent as InviteIcon } from "src/assets/icons/logo.svg";
import { useMobile } from "src/hooks";
import { useWeb3Context } from "src/hooks";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";

const InviteModal = ({ open, handleClose, inviteAddr, handleJoin, isInvited }: any) => {
  const { isSmallScreen } = useMobile();
  const { connected, connect } = useWeb3Context();
  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });
  return (
    <Modal
      id="invite-modal"
      open={open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        px: isSmallScreen ? 1.875 : 0,
      }}
      // open
      onClose={handleClose}
      // hideBackdrop
    >
      <Paper
        className="ohm-card linear-card"
        sx={{
          width: isSmallScreen ? "100%" : "auto",
          maxWidth: isSmallScreen ? "100%" : "512px",
          height: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: isSmallScreen ? "12px" : "24px",
            background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
            // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
            px: isSmallScreen ? 2.125 : 3.75,
            py: 2.5,
          }}
        >
          <Box display="flex" justifyContent={"space-between"} alignItems="center">
            <Typography
              sx={{
                color: "#C7C8CC",
                fontFamily: "AGaramond LT",
                fontSize: isSmallScreen ? "14px" : "20px",
                fontStyle: "normal",
                fontWeight: "700",
                // width: "86%",
              }}
              align="center"
              // variant="h3"
            >
              <Trans>Origin invitation</Trans>
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                p: 0,
              }}
            >
              <SvgIcon sx={{ color: "#C7C8CC" }} component={XIcon} />
            </IconButton>
          </Box>
          <Box
            sx={{
              pt: isSmallScreen ? 0 : 1.875,
            }}
          >
            <Box
              sx={{
                py: 4,
              }}
              display="flex"
              alignItems="center"
              justifyContent={"center"}
            >
              <SvgIcon
                color="primary"
                component={InviteIcon}
                viewBox="0 0 186 52"
                style={{ minWidth: "186px", minHeight: "52px", width: isSmallScreen ? "125px" : "186px" }}
              />
            </Box>
            <Box
              sx={{
                px: isSmallScreen ? 2 : 3,
                py: isSmallScreen ? 2.625 : 4,
                mb: isSmallScreen ? 2.5 : 3.75,
                borderRadius: isSmallScreen ? "8px" : "12px",
                border: "1px solid #5F6674",
                background: isSmallScreen
                  ? "linear-gradient(0deg, #464B55 0%, #101114 100%)"
                  : "linear-gradient(0deg, #464B55 0%, #101114 100%)",
                boxShadow: isSmallScreen
                  ? "0px 1.34766px 4.7168px 0px rgba(0, 0, 0, 0.50)"
                  : "0px 2px 7px 0px rgba(0, 0, 0, 0.50)",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#FFF",
                    fontSize: isSmallScreen ? "13px" : "20px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    wordBreak: "break-all",
                  }}
                  align="center"
                >
                  {inviteAddr ?? <Skeleton width={"200px"} />}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              {connected ? (
                <LoadingButton
                  variant="contained"
                  onClick={handleJoin}
                  disabled={isInvited || isPendingTxn(pendingTransactions, "community_join") || !connected}
                  loading={isPendingTxn(pendingTransactions, "community_join")}
                  loadingPosition="end"
                  sx={{
                    width: "100%",
                    height: "40px",
                    opacity: isInvited ? 0.5 : 1,
                    borderRadius: "30px",
                    border: "1.3px solid #B50C05",
                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                    boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                    "&:hover": {
                      background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                      boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      textAlign: "center",
                      fontSize: isSmallScreen ? "18px" : "18px",
                      fontStyle: "normal",
                      fontWeight: "700",
                    }}
                  >
                    {txnButtonText(pendingTransactions, "community_join", t`Join`)}
                  </Typography>
                </LoadingButton>
              ) : (
                <LoadingButton
                  onClick={connect}
                  sx={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "30px",
                    border: "1.3px solid #B50C05",
                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                    boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                    "&:hover": {
                      background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                      boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      textAlign: "center",
                      fontSize: isSmallScreen ? "12px" : "18px",
                      fontStyle: "normal",
                      fontWeight: "700",
                    }}
                  >
                    <Trans>Connect wallet</Trans>
                  </Typography>
                </LoadingButton>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default InviteModal;
