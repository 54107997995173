import React, { useState } from "react";
import {
  Box,
  Typography,
  Zoom,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  CircularProgress,
  Link,
  SvgIcon,
} from "@mui/material";
import { useMobile, useWeb3Context } from "src/hooks";
import { trim, shorten } from "src/helpers";
import dayjs from "dayjs";
import NoData from "src/assets/images/noData.svg";
import { ReactComponent as ArrowLinkIcon } from "src/assets/icons/link.svg";
import { useAppSelector } from "src/hooks";

import { Trans } from "@lingui/macro";
import { CustormPagination } from "src/components";
import { useDispatch } from "react-redux";
import { getMintRecordsBetween } from "src/slices/MintSlice";

const CastingRecordsCard = ({ item }: any) => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          mb: "15px",
        }}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            color: "#868B8F",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <Trans>Time</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          {item.time}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          mb: "15px",
        }}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            color: "#868B8F",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <Trans>Destroy LGNS</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          {trim(item.destoryAmount, 4)}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          mb: "15px",
        }}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            color: "#868B8F",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <Trans>Casting A</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          {trim(item.mintAmount, 4)}
        </Typography>
      </Box>
    </Box>
  );
};

const BuybackRecordsCard = ({ item }: any) => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          mb: "15px",
        }}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            color: "#868B8F",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <Trans>Assets</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          {shorten(item.account)}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          mb: "15px",
        }}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            color: "#868B8F",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <Trans>Type</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          {item.type}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          mb: "15px",
        }}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            color: "#868B8F",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <Trans>Blockchain</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          {item.blockchain}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          mb: "15px",
        }}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            color: "#868B8F",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <Trans>Value(USDT)</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          {trim(item.value, 4)}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        sx={{
          width: "100%",
          mb: "15px",
        }}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography
          sx={{
            color: "#868B8F",
            fontFamily: "AGaramond LT",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <Trans>Hash</Trans>
        </Typography>
        <Link
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          target="_blank"
          href={`https://etherscan.io/tx/${item.hash}`}
        >
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "AGaramond LT",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              mr: 1,
            }}
          >
            {shorten(item.hash)}
            {/* {dayjs(item.timeNode).format("YYYY/MM/DD  ")} */}
            {/* {dayjs(item.timeNode).format("YYYY/MM/DD  ")} */}
          </Typography>
          <SvgIcon
            viewBox="0 0 15 8"
            sx={{
              width: "15px",
            }}
            component={ArrowLinkIcon}
            // htmlColor="#C7C8CC"
          />
        </Link>
      </Box>
    </Box>
  );
};

const TabList = () => {
  const { provider, address, chainID, connected, connect } = useWeb3Context();

  const dispatch = useDispatch();
  const { isSmallScreen } = useMobile();
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const castingRecords = useAppSelector(state => state.mint.records);
  const [castingRecordsPage, setCastingRecordsPage] = useState(1);
  console.log("castingRecords", castingRecords);
  const recordsLength = useAppSelector(state => state.mint.recordsLength);
  const pageCount = Math.ceil(recordsLength / 5);
  console.log("recordsLength", recordsLength);

  const changeCastingPage = async (event: React.ChangeEvent<unknown>, value: number) => {
    console.log(value);
    setCastingRecordsPage(value);
    await dispatch(
      getMintRecordsBetween({
        page: value,
        offset: 5,
        provider,
        networkID: chainID,
      }),
    );
  };

  const [buybackRecords, setBuybackRecords] = useState([
    // {
    //   account: "0x1234567890123456789012345678901234567890",
    //   type: "回购",
    //   blockchain: "ETH",
    //   value: "100",
    //   hash: "0x1234567890123456789012345678901234567890",
    // },
    // {
    //   account: "0x1234567890123456789012345678901234567890",
    //   type: "回购",
    //   blockchain: "ETH",
    //   value: "100",
    //   hash: "0x1234567890123456789012345678901234567890",
    // },
    // {
    //   account: "0x1234567890123456789012345678901234567890",
    //   type: "回购",
    //   blockchain: "ETH",
    //   value: "100",
    //   hash: "0x1234567890123456789012345678901234567890",
    // },
    // {
    //   account: "0x1234567890123456789012345678901234567890",
    //   type: "回购",
    //   blockchain: "ETH",
    //   value: "100",
    //   hash: "0x1234567890123456789012345678901234567890",
    // },
    // {
    //   account: "0x1234567890123456789012345678901234567890",
    //   type: "回购",
    //   blockchain: "ETH",
    //   value: "100",
    //   hash: "0x1234567890123456789012345678901234567890",
    // },
  ]);
  const top3Array = ["#FFC700", "#fff", "#ED7B13"];

  return (
    <Box
      sx={{
        width: "100%",
      }}
      display="flex"
      alignItems={"center"}
      flexDirection="column"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", maxWidth: "833px", mb: isSmallScreen ? 1.875 : 2.5 }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "392px",
            borderRadius: "44px",
            border: "1px solid #3E434E",
            background: "#2E3138",
            boxShadow: "2px 4px 8px 0px #1A1C1F inset, -1px -1px 4px 0px #3E434E inset",
            p: 0.675,
          }}
          display={"flex"}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              width: "100%",
              height: isSmallScreen ? "100%" : "28px",
              py: isSmallScreen ? 0.5 : 0,
              px: isSmallScreen && activeTab == 0 ? 2 : 0,
              borderRadius: isSmallScreen ? "50px" : "15px",
              border: activeTab == 0 ? "1px solid #5F6674" : "none",
              background: activeTab == 0 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
              boxShadow: activeTab == 0 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
              cursor: isSmallScreen ? "none" : "pointer",
            }}
            onClick={() => setActiveTab(0)}
            display={"flex"}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              sx={{
                color: "#C7C8CC",
                fontFamily: "AGaramond LT",
                fontSize: isSmallScreen ? "10px" : "13px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
              align="center"
            >
              <Trans>Casting Records</Trans>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: isSmallScreen ? "100%" : "28px",
              py: isSmallScreen ? 0.5 : 0,
              px: isSmallScreen && activeTab == 1 ? 2 : 0,
              borderRadius: isSmallScreen ? "50px" : "15px",
              border: activeTab == 1 ? "1px solid #5F6674" : "none",
              background: activeTab == 1 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
              boxShadow: activeTab == 1 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
              cursor: isSmallScreen ? "none" : "pointer",
            }}
            onClick={() => setActiveTab(1)}
            display={"flex"}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              sx={{
                color: "#C7C8CC",
                fontFamily: "AGaramond LT",
                fontSize: isSmallScreen ? "10px" : "13px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
              align="center"
            >
              <Trans>Buyback LGNS Records</Trans>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Zoom in={true} timeout={1000}>
        <Paper
          className="ohm-card linear-card"
          sx={{
            mb: 5,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: isSmallScreen ? "12px" : "24px",
              background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
              // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
            }}
          >
            <Box display="flex" alignItems="center" sx={{ px: isSmallScreen ? 2.5 : 3.75, height: "66px" }}>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "AGaramond LT",
                  fontSize: "21px",
                  fontStyle: "normal",
                  fontWeight: "700",
                }}
              >
                {activeTab === 0 ? <Trans>Casting Records</Trans> : <Trans>Buyback LGNS Records</Trans>}
              </Typography>
            </Box>
            {!isSmallScreen && (
              <Box
                sx={{
                  width: "100%",
                  height: "1px",
                  background: "#0B0C0A",
                  boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                }}
              />
            )}
            <Box>
              {activeTab === 0 ? (
                <Box>
                  {!isSmallScreen ? (
                    <TableContainer sx={{ minHeight: "330px" }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                              sx={{
                                color: "#868B8F",
                                fontFamily: "AGaramond LT",
                                fontSize: isSmallScreen ? "12px" : "16px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                borderBottom: "none",
                                pl: isSmallScreen ? 5.25 : 7,
                              }}
                            >
                              <Trans>Time</Trans>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "#868B8F",
                                fontFamily: "AGaramond LT",
                                fontSize: isSmallScreen ? "12px" : "16px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                borderBottom: "none",
                              }}
                            >
                              <Trans>Destroy LGNS</Trans>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                color: "#868B8F",
                                fontFamily: "AGaramond LT",
                                fontSize: isSmallScreen ? "12px" : "16px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                borderBottom: "none",
                              }}
                            >
                              <Trans>Casting A</Trans>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {isLoading ? (
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                sx={{
                                  height: "300px",
                                  borderBottom: "none",
                                }}
                              >
                                <Box
                                  sx={{ width: "100%", height: "100%" }}
                                  display="flex"
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  flexDirection={"column"}
                                >
                                  <CircularProgress />
                                </Box>
                              </TableCell>
                            </TableRow>
                          ) : castingRecords && castingRecords.length > 0 ? (
                            castingRecords.map((item: any, index: number) => (
                              <TableRow sx={{ height: "56px" }} key={index}>
                                <TableCell sx={{ borderBottom: "none", p: 1.25 }}>
                                  <Box display="flex" alignItems={"center"}>
                                    <Box sx={{ height: "24px" }}>
                                      <Typography
                                        sx={{
                                          color: "#C7C8CC",
                                          fontFamily: "AGaramond LT",
                                          fontSize: isSmallScreen ? "12px" : "16px",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {item.time}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none", p: 1.25, width: "40%" }} align="center">
                                  <Typography
                                    sx={{
                                      color: "#C7C8CC",
                                      fontFamily: "AGaramond LT",
                                      fontSize: isSmallScreen ? "12px" : "16px",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {trim(item.destoryAmount, 4)}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none", p: 1.25 }} align="right">
                                  <Typography
                                    sx={{
                                      color: "#C7C8CC",
                                      fontFamily: "AGaramond LT",
                                      fontSize: isSmallScreen ? "12px" : "16px",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {trim(item.mintAmount, 4)}
                                    {/* {dayjs(item.timeNode).format("YYYY/MM/DD  ")} */}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                sx={{
                                  height: "300px",
                                  borderBottom: "none",
                                }}
                              >
                                <Box
                                  sx={{ width: "100%", height: "100%" }}
                                  display="flex"
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  flexDirection={"column"}
                                >
                                  <img src={NoData} alt="No Data" />
                                  <Typography
                                    sx={{
                                      pt: 2,
                                      color: "#C7C8CC",
                                      fontFamily: "AGaramond LT",
                                      fontSize: "12px",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <Trans>No data</Trans>
                                  </Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        px: "20px",
                      }}
                    >
                      {castingRecords.map((item: any, index: number) => (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            mb: "20px",
                          }}
                        >
                          <CastingRecordsCard item={item} />
                          <Box
                            sx={{
                              width: "100%",
                              height: "1px",
                              background: "#0B0C0A",
                              boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                  {castingRecords && castingRecords.length > 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        my: 3,
                      }}
                    >
                      <CustormPagination
                        siblingCount={isSmallScreen ? 0 : 1}
                        boundaryCount={1}
                        count={pageCount}
                        onChange={changeCastingPage}
                      />
                    </Box>
                  )}
                </Box>
              ) : !isSmallScreen ? (
                <TableContainer sx={{ minHeight: "330px" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            color: "#868B8F",
                            fontFamily: "AGaramond LT",
                            fontSize: isSmallScreen ? "12px" : "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            borderBottom: "none",
                            pl: isSmallScreen ? 5.25 : 7,
                          }}
                        >
                          <Trans>Assets</Trans>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#868B8F",
                            fontFamily: "AGaramond LT",
                            fontSize: isSmallScreen ? "12px" : "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            borderBottom: "none",
                          }}
                        >
                          <Trans>Type</Trans>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#868B8F",
                            fontFamily: "AGaramond LT",
                            fontSize: isSmallScreen ? "12px" : "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            borderBottom: "none",
                          }}
                        >
                          <Trans>Blockchain</Trans>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#868B8F",
                            fontFamily: "AGaramond LT",
                            fontSize: isSmallScreen ? "12px" : "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            borderBottom: "none",
                          }}
                        >
                          <Trans>Value(USDT)</Trans>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#868B8F",
                            fontFamily: "AGaramond LT",
                            fontSize: isSmallScreen ? "12px" : "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            borderBottom: "none",
                          }}
                        >
                          <Trans>Hash</Trans>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            sx={{
                              height: "300px",
                              borderBottom: "none",
                            }}
                          >
                            <Box
                              sx={{ width: "100%", height: "100%" }}
                              display="flex"
                              alignItems={"center"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                            >
                              <CircularProgress />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : buybackRecords && buybackRecords.length > 0 ? (
                        buybackRecords.map((item: any, index: number) => (
                          <TableRow sx={{ height: "56px" }} key={index}>
                            <TableCell sx={{ borderBottom: "none", p: 1.25 }}>
                              <Box display="flex" alignItems={"center"}>
                                <Box sx={{ height: "24px" }}>
                                  <Typography
                                    sx={{
                                      color: "#C7C8CC",
                                      fontFamily: "AGaramond LT",
                                      fontSize: isSmallScreen ? "12px" : "16px",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {shorten(item.account)}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none", p: 1.25 }} align="center">
                              <Typography
                                sx={{
                                  color: "#C7C8CC",
                                  fontFamily: "AGaramond LT",
                                  fontSize: isSmallScreen ? "12px" : "16px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                }}
                              >
                                {item.type}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none", p: 1.25 }} align="center">
                              <Typography
                                sx={{
                                  color: "#C7C8CC",
                                  fontFamily: "AGaramond LT",
                                  fontSize: isSmallScreen ? "12px" : "16px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                }}
                              >
                                {item.blockchain}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none", p: 1.25 }} align="center">
                              <Typography
                                sx={{
                                  color: "#C7C8CC",
                                  fontFamily: "AGaramond LT",
                                  fontSize: isSmallScreen ? "12px" : "16px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                }}
                              >
                                {item.value}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none", p: 1.25 }} align="right">
                              <Link
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  cursor: "pointer",
                                }}
                                target="_blank"
                                href={`https://etherscan.io/tx/${item.hash}`}
                              >
                                <Typography
                                  sx={{
                                    color: "#C7C8CC",
                                    fontFamily: "AGaramond LT",
                                    fontSize: isSmallScreen ? "12px" : "16px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    mr: 1,
                                  }}
                                >
                                  {shorten(item.hash)}
                                  {/* {dayjs(item.timeNode).format("YYYY/MM/DD  ")} */}
                                  {/* {dayjs(item.timeNode).format("YYYY/MM/DD  ")} */}
                                </Typography>
                                <SvgIcon
                                  viewBox="0 0 15 8"
                                  sx={{
                                    width: "15px",
                                  }}
                                  component={ArrowLinkIcon}
                                  // htmlColor="#C7C8CC"
                                />
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            sx={{
                              height: "300px",
                              borderBottom: "none",
                            }}
                          >
                            <Box
                              sx={{ width: "100%", height: "100%" }}
                              display="flex"
                              alignItems={"center"}
                              justifyContent={"center"}
                              flexDirection={"column"}
                            >
                              <img src={NoData} alt="No Data" />
                              <Typography
                                sx={{
                                  pt: 2,
                                  color: "#C7C8CC",
                                  fontFamily: "AGaramond LT",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                }}
                              >
                                <Trans>No data</Trans>
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    px: "20px",
                  }}
                >
                  {buybackRecords.map((item: any, index: number) => (
                    <Box
                      key={index}
                      sx={{
                        width: "100%",
                        mb: "20px",
                      }}
                    >
                      <BuybackRecordsCard item={item} />
                      <Box
                        sx={{
                          width: "100%",
                          height: "1px",
                          background: "#0B0C0A",
                          boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Paper>
      </Zoom>
    </Box>
  );
};

export default TabList;
