import { Paper, Box, Grid, Typography, Skeleton } from "@mui/material";
import React from "react";
import CardBg from "../../assets/images/homeDataBg.png";
import { Trans } from "@lingui/macro";
import { ReactComponent as PointIcon } from "../../assets/icons/home-card-point.svg";
import { useMobile } from "../../hooks";
import { trim } from "src/helpers";

const HomeData = ({ treasuryMarketValue, stakingAPY, tokenBalInStaking, marketValue }: any) => {
  return (
    <Paper
      className="linear-card"
      sx={{
        // borderRadius: "24px",
        // border: "1.5px solid #42454A",
        // background: "linear-gradient(180deg, #32373B 0%, #18191D 123.52%, #1D1E22 123.52%)",
        // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
        height: "438px",
        overflow: "hidden",
        mb: 15,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          px: 11.25,
          py: 7.5,
          borderRadius: "24px",
          // border: "2px solid #434952",
          background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
          // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
        }}
      >
        <Box sx={{ position: "relative", zIndex: 1 }} display={"flex"}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{}}>
              <Box>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "20px",
                    fontWeight: "400",
                    mb: 2,
                  }}
                >
                  <Trans>Total LGNS Staked</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "66px",
                    fontWeight: "500",
                  }}
                >
                  {tokenBalInStaking ? trim(tokenBalInStaking, 2) : <Skeleton width="200px" />}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{}}>
              <Box>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "20px",
                    fontWeight: "400",
                    mb: 2,
                  }}
                >
                  <Trans>Treasury Balance</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "66px",
                    fontWeight: "500",
                  }}
                >
                  {typeof treasuryMarketValue == "number" ? (
                    `${new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }).format(treasuryMarketValue)}`
                  ) : (
                    <Skeleton width="200px" />
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{}}>
              <Box>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "20px",
                    fontWeight: "400",
                    mb: 2,
                  }}
                >
                  <Trans>MarketCap</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "66px",
                    fontWeight: "500",
                  }}
                >
                  {marketValue ? (
                    `${new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }).format(marketValue)}`
                  ) : (
                    <Skeleton width="200px" />
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{}}>
              <Box>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "20px",
                    fontWeight: "400",
                    mb: 2,
                  }}
                >
                  <Trans>Current APY</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "66px",
                    fontWeight: "500",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    wordWrap: "break-word",
                  }}
                >
                  {stakingAPY ? (
                    `${new Intl.NumberFormat("en-US").format(Number(stakingAPY))}%`
                  ) : (
                    <Skeleton width="200px" />
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/* <Box sx={{ width: "50%" }}>1</Box>
          <Box sx={{ width: "50%" }}>2</Box> */}
        </Box>
        <Box sx={{ position: "absolute", right: "-10px", bottom: "-30px", zIndex: "0" }}>
          <img src={CardBg} />
        </Box>
        <Box sx={{ position: "absolute", left: "39px", top: "34px", zIndex: "0" }}>
          <PointIcon />
        </Box>
      </Box>
    </Paper>
  );
};

export default HomeData;
