import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import muiTheme from "./themes/muiGlobal";
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import useTheme from "./hooks/useTheme";
import useBonds, { IAllBondData } from "./hooks/Bonds";
import { DEFAULT_PROVIDER, useAddress, useWeb3Context } from "./hooks/web3Context";
import useSegmentAnalytics from "./hooks/useSegmentAnalytics";
import { segmentUA } from "./helpers/userAnalyticHelpers";
import { shouldTriggerSafetyCheck } from "./helpers";
import { ethers } from "ethers";
import { darkTheme as rainbowDarkTheme, RainbowKitProvider, Theme } from "@rainbow-me/rainbowkit";
import { calcBondDetails } from "./slices/BondSlice";
import { loadAppDetails } from "./slices/AppSlice";
import { loadAccountDetails, calculateUserBondDetails, calculateUserDirectBondDetails } from "./slices/AccountSlice";
import { info } from "./slices/MessagesSlice";
import { useIsStakeV2, useIsSwapV2, useIsV2 } from "./hooks";
import { Stake, ChooseBond, Bond, TreasuryDashboard, Community, Invite, Turbine, Reward, Mint, Swap, WebKey } from "./views";
import Sidebar from "./components/Sidebar/Sidebar.jsx";
import TopBar from "./components/TopBar/TopBar.jsx";
import NavDrawer from "./components/Sidebar/NavDrawer.jsx";
import Messages from "./components/Messages/Messages";
import Footer from "./components/Footer/Footer";
import NotFound from "./views/404/NotFound";
import { chains } from "src/hooks/wagmi";
import merge from "lodash.merge";
import { useBlockNumber } from "wagmi";
import { dark as darkTheme } from "./themes/dark.js";
import { light as lightTheme } from "./themes/light.js";
import { girth as gTheme } from "./themes/girth.js";
import "./style.scss";
import Home from "./views/Home/Home";
import { DEFAULT_INVITE_ADDRESS } from "./constants";
import { communityJoin, getCommunityData } from "src/slices/CommunitySlice";
import InviteModal from "./components/InviteModal";
import { useAppSelector } from "src/hooks";
import SmoothScroll from "smooth-scroll";
import { AuthProvider } from "./auth/auth-provider";

// import { Bond as IBond } from "./lib/Bond";
// import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics";

// 😬 Sorry for all the console logging
const DEBUG = false;

// 🛰 providers
if (DEBUG) console.log("📡 Connecting to Mainnet Polygon");
// 🔭 block explorer URL
// const blockExplorer = targetNetwork.blockExplorer;

const drawerWidth = 280;
const transitionDuration = 969;

function App() {
  // useSegmentAnalytics();
  // useGoogleAnalytics();
  const location = useLocation();
  const [isLoad, setIsLoad] = useState(false);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInviteAddr, setModalInviteAddr] = useState("");
  const [theme, toggleTheme, mounted] = useTheme();
  const currentPath = location.pathname + location.search + location.hash;
  // const classes = useStyles(location.pathname == "/stakev2");

  // const useStyles = makeStyles((theme: any) => ({
  //   drawer: {
  //     [theme.breakpoints.up("md")]: {
  //       width: drawerWidth,
  //       flexShrink: 0,
  //     },
  //   },
  //   content: {
  //     flexGrow: 1,
  //     // padding: theme.spacing(1),
  //     transition: theme.transitions.create("margin", {
  //       easing: theme.transitions.easing.sharp,
  //       duration: transitionDuration,
  //     }),
  //     height: "100%",
  //     overflow: "auto",
  //     position: "relative",
  //     // background: ,
  //   },
  //   contentShift: {
  //     transition: theme.transitions.create("margin", {
  //       easing: theme.transitions.easing.easeOut,
  //       duration: transitionDuration,
  //     }),
  //     marginLeft: 0,
  //   },
  //   // necessary for content to be below app bar
  //   toolbar: theme.mixins.toolbar,
  //   drawerPaper: {
  //     width: drawerWidth,
  //   },
  // }));
  const scrollTarget = useRef(null);
  const scroll = useMemo(() => new SmoothScroll(), []);

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  // const classes = useStyles();
  const { provider, chainID, connected, address } = useWeb3Context();
  const isInvited = useAppSelector(state => state.community.isInvited);
  const referrer = useAppSelector(state => state.community.referrer);

  // const { isConnected: connected, address } = useAccount();
  // const {
  //   chain: { id: chainID },
  // } = useNetwork();
  // const chainID = useChainId();

  // const provider = useProvider();
  // const chainID = "97";

  // const address = useAddress();
  // console.log("chain", chain);
  // console.log("provider", provider);
  const [walletChecked, setWalletChecked] = useState(false);

  // TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
  const { bonds } = useBonds(chainID);
  // console.log("bonds", bonds);

  async function loadDetails(whichDetails) {
    // NOTE (unbanksy): If you encounter the following error:
    // Unhandled Rejection (Error): call revert exception (method="balanceOf(address)", errorArgs=null, errorName=null, errorSignature=null, reason=null, code=CALL_EXCEPTION, version=abi/5.4.0)
    // it's because the initial provider loaded always starts with chainID=1. This causes
    // address lookup on the wrong chain which then throws the error. To properly resolve this,
    // we shouldn't be initializing to chainID=1 in web3Context without first listening for the
    // network. To actually test rinkeby, change setChainID equal to 4 before testing.

    if (whichDetails === "app") {

      loadApp(DEFAULT_PROVIDER);
    }
    // console.log("loading", whichDetails === "account" && address && connected && isLoad);
    // don't run unless provider is a Wallet...
    if (whichDetails === "account" && address && connected && isLoad) {
      loadAccount(provider);
      // loadAccount(loadProvider);
    }
  }

  const loadApp = useCallback(
    loadProvider => {
      dispatch(loadAppDetails({ networkID: chainID, provider: loadProvider }));

      bonds.map(bond => {
        // console.log("bond app", chainID);
        dispatch(calcBondDetails({ bond, value: "", provider: loadProvider, networkID: chainID }));
      });
      setIsLoad(true);
    },
    [connected, address, currentPath, chainID],
  );

  const loadAccount = useCallback(
    loadProvider => {
      dispatch(loadAccountDetails({ networkID: chainID, address, provider: provider }));
      dispatch(getCommunityData({ provider, networkID: chainID, address }));
      bonds.map(bond => {
        // console.log("bond", bond);
        dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
        dispatch(calculateUserDirectBondDetails({ address, bond, provider, networkID: chainID }));
        // chainID == 56 && dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
      });
      // expiredBonds.map(bond => {
      //   dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
      // });
    },
    [connected, address],
  );

  // this useEffect fires on state change from above. It will ALWAYS fire AFTER
  useEffect(() => {
    // don't load ANY details until wallet is Checked

    // if (walletChecked) {
    loadDetails("app");
    // }
  }, []);

  // this useEffect picks up any time a user Connects via the button
  useEffect(() => {
    // don't load ANY details until wallet is Connected
    if (connected && isLoad) {
      loadDetails("account");
    }
  }, [connected, isLoad, address]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    // if (mobileOpen) {

    // setIsSidebarExpanded(false);
    // }
  };

  const handleSidebarClose = () => {
    setIsSidebarExpanded(false);
  };

  let themeMode = theme === "light" ? lightTheme : theme === "dark" ? darkTheme : gTheme;

  useEffect(() => {
    themeMode = theme === "light" ? lightTheme : darkTheme;
  }, [theme]);

  useEffect(() => {
    if (isSidebarExpanded) handleSidebarClose();
    setMobileOpen(false);
    // console.log("location.pathname", isSidebarExpanded);
  }, [location.pathname]);

  const customTheme = merge(
    rainbowDarkTheme({
      accentColor: "#676B74",
      borderRadius: "medium",
    }),
    {
      colors: {
        modalBackground: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
        modalBorder: "#434952",
      },
      shadows: {
        dialog: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
      },
    },
  );

  useEffect(() => {
    if (connected && referrer !== "0x0000000000000000000000000000000000000000") return;
    if (location.search.indexOf("code") > -1) {
      let obj = {};
      let params = location.search.substring(1).split("&");

      params.map(_ => {
        obj[_.split("=")[0]] = _.split("=")[1];
      });

      setModalInviteAddr(obj.code);
      setModalOpen(true);
    }
    //  else if (!connected || referrer == "0x0000000000000000000000000000000000000000") {
    //   setModalInviteAddr(DEFAULT_INVITE_ADDRESS);
    //   setModalOpen(true);
    // }
  }, [address, connected, location.search, referrer]);

  const handleJoin = async () => {
    if (modalInviteAddr?.toLowerCase() === address?.toLowerCase()) {
      return dispatch(error(t`You can't invite yourself!`));
    }
    await dispatch(
      communityJoin({
        provider,
        referer: modalInviteAddr,
        networkID: chainID,
      }),
    );
    setModalOpen(false);
    dispatch(getCommunityData({ provider, networkID: chainID, address }));
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleScrollTop = () => {
    scroll.animateScroll(scrollTarget.current, undefined, {
      easing: "easeInOutCubic",
    });
  };
  return (
    <RainbowKitProvider chains={chains} theme={customTheme}>
        <AuthProvider>
      <ThemeProvider theme={themeMode}>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          {/* {isAppLoading && <LoadingSplash />} */}
          <div
            ref={scrollTarget}
            className={`app ${isSmallerScreen && "tablet"} ${isSmallScreen && "mobile"} ${theme} `}
          >
            <Messages />
            <TopBar theme={theme} toggleTheme={toggleTheme} handleDrawerToggle={handleDrawerToggle} />
            <NavDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />

            {/* <nav className={classes.drawer}>
          {isSmallerScreen ? (
          ) :
            <Sidebar
              theme={theme}
              isSidebarExpanded={isSidebarExpanded}
              setIsSidebarExpanded={setIsSidebarExpanded}
            />
        }
        </nav> */}

            <Box
              sx={{
                pt: isSmallScreen ? 8.5 : 0,
              }}
            >
              {/* <div className={`${classes.content} ${isSmallerScreen && classes.contentShift}`}> */}
              <Switch>
                <Route exact path="/dashboard">
                  <TreasuryDashboard />
                </Route>
                <Route exact path="/home">
                  <Home handleScrollTop={handleScrollTop} />
                </Route>
                <Route path="/webkey">
                  <WebKey />
                </Route>
                <Route path="/stake">
                  <Stake />
                </Route>
                <Route path="/community">
                  <Community />
                </Route>
                <Route path="/invite">
                  <Invite />
                </Route>
                <Route path="/turbine">
                  <Turbine />
                </Route>
                <Route path="/reward">
                  <Reward />
                </Route>
                <Route path="/mint">
                  <Mint />
                </Route>
                <Route path="/swap">
                  <Swap theme={theme} />
                </Route>
                <Route path="/bonds">
                  {bonds.map(bond => {
                      if(bond?.name?.toLowerCase()!='eth'&&bond?.name?.toLowerCase()!='btc')
                      return (
                        <Route exact key={bond.name} path={`/bonds/${bond.name}`}>
                          <Bond bond={bond} theme={theme} />
                        </Route>
                      );
                  })}
                  <ChooseBond />
                </Route>
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
                <Route component={NotFound} />
              </Switch>
              {/* <div className={`${location.pathname.replace("/", "")}Bg  rightBg`}></div> */}
            </Box>
            <InviteModal
              open={modalOpen}
              handleClose={handleClose}
              inviteAddr={modalInviteAddr}
              isInvited={isInvited}
              handleJoin={handleJoin}
            />
          </div>
        </MuiThemeProvider>
      </ThemeProvider>
      </AuthProvider>
    </RainbowKitProvider>
  );
}

export default App;
