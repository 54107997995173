import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Grid, Slider, OutlinedInput, Paper, Typography } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { trim, toolNumber } from "../../helpers";
import { changeApproval, changeStake, cliamWarmupBalance } from "../../slices/StakeThunk";
import "./stake.scss";
import { Skeleton } from "@mui/material";
import { formatMoney } from "src/helpers";
import { useAppSelector } from "src/hooks";
import { styled } from "@material-ui/core";
import { useMobile } from "../../hooks";

const CustomSlider = styled(Slider)(({ theme }) => ({
  // color: "yellow !important",
  height: "9px !important",
  borderRadius: "100px !important",
  padding: "1px 2px 2px !important",
  "& > .MuiSlider-track": {
    // border: "1px solid red !important",
    // backgroundColor: "red !important",
    background: "linear-gradient(90deg, #D54C18 0%, #BD9C33 100%) !important",
    border: "none",
  },
  "& > .MuiSlider-thumb": {
    width: "28px",
    height: "28px",
    color: "rgb(184, 140, 60) !important",
    border: "10px solid #868B8F",
    padding: "7px",
    "&:hover": {
      boxShadow: "4px 6px 20px rgba(0, 0, 0, 0.35) !important",
    },
    "&.Mui-focusVisible": {
      boxShadow: "4px 6px 20px rgba(0, 0, 0, 0.35) !important",
    },
  },
  // "& .MuiSlider-valueLabel": {
  //   color: "yellow !important",
  // },
  "& .MuiSlider-rail": {
    color: "#202226 !important",
    boxShadow: "-2px -2px 2px 0px #4E5154 inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset",
  },
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface ICalcualtor {
  stakingRebasePercentage: string;
}
function Calculator({ stakingRebasePercentage }: ICalcualtor) {
  const { isSmallScreen } = useMobile();
  const dispatch = useDispatch();
  // const { provider, address, connected, connect, chainID } = useWeb3Context();

  const [activeBtn, setActiveBtn] = useState(0);
  const [calValue, setCalValue] = useState<string | number>("");
  const [sliderValue, setSliderValue] = useState(stakingRebasePercentage);
  const [calBUSDBal, setCalBUSDBal] = useState(0);
  const [calTokenBal, setCalTokenBal] = useState<number | string>(0);
  const [calTokenPrice, setCalTokenPrice] = useState<number | string>(0);
  const activeBtns = [7, 30, 180, 365];

  useEffect(() => {
    setSliderValue(stakingRebasePercentage);
  }, [stakingRebasePercentage]);

  const [zoomed, setZoomed] = useState(false);
  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState<any>();

  const isAppLoading = useAppSelector(state => state.app.loading);
  const currentIndex = useAppSelector(state => {
    return state.app.currentIndex;
  });
  const fiveDayRate = useAppSelector(state => {
    return state.app.fiveDayRate;
  });
  const ohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.ohm;
  });
  const gonsBal = useAppSelector(state => {
    return state.account.balances && state.account.balances.gonsBal;
  });
  const isLocked = useAppSelector(state => {
    return state.account && state.account.isLocked;
  });

  // console.log("gonsBal", gonsBal);

  const oldSohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.oldsohm;
  });
  const sohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.sOHM;
  });
  const fsohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.fsohm;
  });

  const wsohmAsSohm = useAppSelector(state => {
    return state.account.balances && state.account.balances.wsohmAsSohm;
  });
  const stakeAllowance = useAppSelector(state => {
    return (state.account.staking && state.account.staking.ohmStake) || 0;
  });

  const unstakeAllowance = useAppSelector(state => {
    return (state.account.staking && state.account.staking.ohmUnstake) || 0;
  });
  // const stakingRebase = useAppSelector(state => {
  //   return state.app.stakingRebase || 0;
  // });

  const stakingAPY = useAppSelector(state => {
    // return state.app.stakingAPY || 0;
    return state.app.stakingAPY ? toolNumber(state.app.stakingAPY) : 0;
  });
  const marketPrice = useAppSelector(state => {
    return state.app.marketPrice;
  });

  const setMax = () => {
    if (view === 0) {
      setQuantity(Number(ohmBalance));
    } else {
      setQuantity(Number(sohmBalance));
    }
  };

  const trimmedBalance = Number(
    [sohmBalance, fsohmBalance, wsohmAsSohm]
      .filter(Boolean)
      .map(balance => Number(balance))
      .reduce((a, b) => a + b, 0)
      .toFixed(4),
  );

  const calculate = (value: any, active: number = 0) => {
    setCalValue(value.trim());
    const _tokenBal =
      Math.pow(1 + Number(sliderValue) / 100, 3 * activeBtns[typeof active === "number" ? active : activeBtn]) * value;
    console.log(
      "_tokenBal",
      trim(toolNumber(_tokenBal), 4),
      toolNumber(toolNumber(_tokenBal) * Number(trim(marketPrice, 8))),
    );
    setCalTokenBal(trim(toolNumber(_tokenBal) as number, 4));
    marketPrice &&
      setCalTokenPrice(trim(toolNumber(toolNumber(_tokenBal) * Number(trim(marketPrice, 8))) as number, 2));
  };

  const sliderChange = (value: any) => {
    // console.log("a", value);
    setSliderValue(value);
    calculate(calValue, activeBtn);
  };

  return (
    <Paper className={`ohm-card token-card linear-card`} sx={{}}>
      <Box
        sx={{
          borderRadius: isSmallScreen ? "12px" : "24px",
          // border: "2px solid #434952",
          background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
          // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
          px: isSmallScreen ? 2.5 : 3.75,
          py: isSmallScreen ? 1.875 : 2.5,
        }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <div className="card-header">
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "AGaramond LT",
                  fontSize: "21px",
                  fontStyle: "normal",
                  fontWeight: "700",
                }}
              >
                <Trans>Calculator</Trans>
              </Typography>
            </div>
          </Grid>

          <Grid item>
            <Box className="stake-top-metrics" sx={{ mt: isSmallScreen ? 0 : 1.25 }}>
              <Grid container rowSpacing={2.5} alignItems="flex-end">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="stake-apy">
                    <Typography
                      sx={{
                        color: "#C7C8CC",
                        fontFamily: "AGaramond LT",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        width: "100%",
                      }}
                      align={isSmallScreen ? "center" : "left"}
                    >
                      <Trans>Estimate return</Trans>
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <OutlinedInput
                    onChange={e => calculate(e.target.value, activeBtn)}
                    style={{ width: "100%" }}
                    value={calValue}
                    type="text"
                    placeholder={t`Enter the amount of $LGNS`}
                    className="cal-input"
                    sx={{
                      height: "40px",
                      borderRadius: "60px",
                      border: "1px solid rgba(138, 147, 160, 0.50)",
                      background: "#3C3F48",
                      color: "#c7c8cc",
                      boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      mb: 2.5,
                      borderRadius: "44px",
                      height: "40px",
                      border: "1px solid #3E434E",
                      background: "#2E3138",
                      boxShadow: "2px 4px 8px 0px #1A1C1F inset, -1px -1px 4px 0px #3E434E inset",
                      p: 0.75,
                    }}
                  >
                    <Button
                      variant={activeBtn == 0 ? "contained" : "text"}
                      size="small"
                      onClick={() => {
                        setActiveBtn(0);
                        calculate(calValue, 0);
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "15px",
                        color: "#c7c8cc",
                        fontSize: isSmallScreen ? "12px" : "16px",
                        fontWeight: "400",
                        height: "28px",
                        border: activeBtn == 0 ? "1px solid #5F6674" : "none",
                        background: activeBtn == 0 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                        boxShadow: activeBtn == 0 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
                        // background: "linear-gradient(225deg, rgba(83, 89, 102, 0.01) 0%, rgba(46, 49, 56, 0.01) 96.09%)",
                        "&:hover": {
                          background:
                            activeBtn == 0 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                        },
                      }}
                    >
                      {t`7 Days`}
                    </Button>
                    <Button
                      variant={activeBtn == 1 ? "contained" : "text"}
                      size="small"
                      onClick={() => {
                        setActiveBtn(1);
                        calculate(calValue, 1);
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "15px",
                        color: "#c7c8cc",
                        fontSize: isSmallScreen ? "12px" : "16px",
                        fontWeight: "400",
                        height: "28px",
                        border: activeBtn == 1 ? "1px solid #5F6674" : "none",
                        background: activeBtn == 1 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                        boxShadow: activeBtn == 1 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
                        // background: "linear-gradient(225deg, rgba(83, 89, 102, 0.01) 0%, rgba(46, 49, 56, 0.01) 96.09%)",
                        "&:hover": {
                          background:
                            activeBtn == 1 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                        },
                      }}
                    >
                      {t`1 Month`}
                    </Button>
                    <Button
                      variant={activeBtn == 2 ? "contained" : "text"}
                      size="small"
                      onClick={() => {
                        setActiveBtn(2);
                        calculate(calValue, 2);
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "15px",
                        color: "#c7c8cc",
                        fontSize: isSmallScreen ? "12px" : "16px",
                        fontWeight: "400",
                        height: "28px",
                        border: activeBtn == 2 ? "1px solid #5F6674" : "none",
                        background: activeBtn == 2 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                        boxShadow: activeBtn == 2 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
                        // background: "linear-gradient(225deg, rgba(83, 89, 102, 0.01) 0%, rgba(46, 49, 56, 0.01) 96.09%)",
                        "&:hover": {
                          background:
                            activeBtn == 2 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                        },
                      }}
                    >
                      {t`6 Month`}
                    </Button>
                    <Button
                      variant={activeBtn == 3 ? "contained" : "text"}
                      size="small"
                      onClick={() => {
                        setActiveBtn(3);
                        calculate(calValue, 3);
                      }}
                      sx={{
                        width: "100%",
                        borderRadius: "15px",
                        color: "#c7c8cc",
                        fontSize: isSmallScreen ? "12px" : "16px",
                        fontWeight: "400",
                        height: "28px",
                        border: activeBtn == 3 ? "1px solid #5F6674" : "none",
                        background: activeBtn == 3 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                        boxShadow: activeBtn == 3 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
                        // background: "linear-gradient(225deg, rgba(83, 89, 102, 0.01) 0%, rgba(46, 49, 56, 0.01) 96.09%)",
                        "&:hover": {
                          background:
                            activeBtn == 3 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
                        },
                      }}
                    >
                      {t`1 Year`}
                    </Button>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    sx={{
                      color: "#C7C8CC",
                      fontFamily: "AGaramond LT",
                      fontSize: isSmallScreen ? "20px" : "21px",
                      fontStyle: "normal",
                      fontWeight: "400",
                    }}
                    align="left"
                  >
                    <Trans>Next Reward Yield</Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection={isSmallScreen ? "column" : "row"}
                  >
                    {/* {Number(1) <= 0 ? ( */}
                    {Number(stakingRebasePercentage) <= 0 ? (
                      <Typography>
                        <Skeleton width="200px" />
                      </Typography>
                    ) : (
                      <>
                        <CustomSlider
                          step={0.0001}
                          min={0.0001}
                          max={5}
                          defaultValue={Number(stakingRebasePercentage)}
                          aria-label="Default"
                          onChange={(e: any, value: any) => sliderChange(value)}
                          sx={{
                            mb: isSmallScreen ? 2.5 : 0,
                            mr: isSmallScreen ? 0 : 2.875,
                          }}
                        />
                        <Box
                          sx={{
                            width: isSmallScreen ? "100%" : "auto",
                            px: isSmallScreen ? 1.25 : 2.5,
                            height: "40px",
                            borderRadius: "60px",
                            py: 1.25,
                            border: "1px solid rgba(138, 147, 160, 0.50)",
                            background: "linear-gradient(0deg, #464B55 0%, #101114 100%)",
                            boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                            mb: isSmallScreen ? 2.5 : 0,
                          }}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography
                            sx={{
                              color: "#C7C8CC",
                              fontFamily: "AGaramond LT",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                            }}
                            align="center"
                            className="slider-value"
                          >
                            {`${sliderValue}%`}
                          </Typography>
                        </Box>
                        {isSmallScreen && (
                          <Box
                            sx={{
                              width: "100%",
                              height: "1px",
                              background: "#0B0C0A",
                              boxShadow: "0px -1px 0px 0px rgba(83, 89, 102, 0.60)",
                            }}
                          />
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      borderRadius: "26px",
                      minHeight: "40px",
                      border: "1px solid #5F6674",
                      background: "linear-gradient(0deg, #464B55 0%, #101114 100%)",
                      boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.50)",
                      px: 2.625,
                      py: 1.25,
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{
                        color: "#C7C8CC",
                        fontFamily: "AGaramond LT",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      align="left"
                    >
                      <Trans>$LGNS Balance</Trans>
                    </Typography>
                    <Typography style={{ color: "white", fontSize: "16px", wordBreak: "break-all" }} align="right">
                      {calTokenBal}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "30px" }}>
                  <Box
                    sx={{
                      borderRadius: "26px",
                      minHeight: "40px",
                      border: "1px solid #5F6674",
                      background: "linear-gradient(0deg, #464B55 0%, #101114 100%)",
                      boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.50)",
                      px: 2.625,
                      py: 1.25,
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{
                        color: "#C7C8CC",
                        fontFamily: "AGaramond LT",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      align="left"
                    >
                      <Trans>Total USD</Trans>
                    </Typography>
                    <Typography style={{ color: "white", fontSize: "16px", wordBreak: "break-all" }} align="right">
                      ${calTokenPrice}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default Calculator;
