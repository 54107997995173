import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import PrivacyBg from "../../assets/images/privacyBg.png";
import PaperCard1 from "../../assets/icons/encrytedWallet.svg";
import PaperCard2 from "../../assets/icons/hardwareWallet.svg";
import PaperCard3 from "../../assets/icons/transferSystem.svg";
import PaperCard4 from "../../assets/icons/crossChain.svg";
import PaperCard5 from "../../assets/icons/dex.svg";
import PaperCard6 from "../../assets/icons/stableTausd.svg";
import PaperCard7 from "../../assets/icons/smartContract.svg";
import PaperCard8 from "../../assets/icons/zrc20.svg";
import EncryptedWalletBg from "../../assets/images/encryptedWalletBg.png";
import HardwareWalletBg from "../../assets/images/hardwareWalletBg.png";
import TransferSystemBg from "../../assets/images/transferSystemBg.png";
import CrossChainBg from "../../assets/images/crossChainBg.png";
import DexBg from "../../assets/images/dexBg.png";
import StableTAUSDBg from "../../assets/images/stableTausdBg.png";
import SmartContractBg from "../../assets/images/smartContractBg.png";
import Zrc20Bg from "../../assets/images/zrc20Bg.png";
import { t, Trans } from "@lingui/macro";
import { useMobile } from "../../hooks";

const PaperCard = (props: any) => {
  const { isSmallScreen } = useMobile();
  return (
    <Paper
      className="linear-card"
      sx={{
        borderRadius: isSmallScreen ? "8px !important" : "14px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          // height: "100%",
          position: "relative",
          height: isSmallScreen ? "104px" : "184px",
          // border: isSmallScreen ? "1px solid $434952" : "2px solid #434952",
          background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
          // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
          borderRadius: isSmallScreen ? "6px" : "12px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            py: isSmallScreen ? 2.5 : 3.5,
            position: "relative",
            zIndex: "1",
            height: "100%",
            pt: isSmallScreen ? 2.5 : 5,
          }}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
        >
          <Box
            display={"flex"}
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: "100%", width: "100%" }}
          >
            <Box
              sx={{
                width: isSmallScreen ? "28px" : "50px",
                height: isSmallScreen ? "28px" : "50px",
                borderRadius: "50%",
                background: "linear-gradient(136deg, #363B40 9.64%, #1D1E23 89.75%)",
                boxShadow: "2px 4px 9px 0px #070707, -3px -2px 16px 0px #455059",
                border: " 2.4px solid rgba(28, 30, 33, 0.60)",
                "&>img": { width: isSmallScreen ? "17px" : "30px", height: isSmallScreen ? "17px" : "30px" },
              }}
              display={"flex"}
              alignItems="center"
              justifyContent="center"
            >
              <img src={props.icon} alt="" />
            </Box>
            <Typography
              align="center"
              sx={{
                color: "#C7C8CC",
                fontFamily: "Neue Haas Grotesk Text Pro",
                fontSize: isSmallScreen ? "10px" : "16px",
                fontStyle: "normal",
                fontWeight: "500",
              }}
            >
              {props.title}
            </Typography>
          </Box>
          {/* {props.children} */}
        </Box>
        <Box
          sx={{
            position: "absolute",
            left: `${props.left}`,
            top: isSmallScreen ? "0" : "-6px",
            transform: "translateX(-50%)",
            width: isSmallScreen ? "97px" : "174px",
            height: isSmallScreen ? "97px" : "174px",
            zIndex: 0,
            background: `url(${props.bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
      </Box>
    </Paper>
  );
};

const Privacy = () => {
  const { isSmallScreen } = useMobile();

  return (
    <Box sx={{ mb: isSmallScreen ? 6.25 : 15 }}>
      <Typography
        sx={{
          color: "#C7C8CC",
          fontFamily: "Neue Haas Grotesk Text Pro",
          fontSize: isSmallScreen ? "24px" : "54px",
          fontStyle: "normal",
          fontWeight: "700",
          mb: isSmallScreen ? 2.5 : 7.5,
        }}
        align="center"
      >
        <Trans>Building a Privacy Ecosystem</Trans>
      </Typography>
      <Paper
        className="linear-card"
        sx={
          {
            // background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
            // border: isSmallScreen ? "1px solid #434952" : "2px solid #434952",
            // boxShadow: "drop-shadow(14px 14px 100px rgba(0, 0, 0, 0.40))",
            // borderRadius: isSmallScreen ? "12px" : "24px",
            // overflow: "hidden",
          }
        }
      >
        <Box
          sx={{
            border: isSmallScreen ? "1px solid #434952" : "2px solid $434952",
            borderRadius: isSmallScreen ? "12px" : "24px",
            background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
            // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
            position: "relative",
            width: "100%",
            height: isSmallScreen ? "auto" : "730px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ px: isSmallScreen ? 2.5 : 10, py: isSmallScreen ? 3.75 : 7.5, position: "relative", zIndex: "1" }}>
            <Typography
              sx={{
                color: "#C7C8CC",
                fontFamily: "Neue Haas Grotesk Text Pro",
                fontSize: isSmallScreen ? "14px" : "20px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: isSmallScreen ? "20px" : "34px",
                mb: isSmallScreen ? 3.75 : 5,
              }}
              align="center"
            >
              <Trans>
                Based on zks (zk-snark is a type of cryptographic proof technology that can hide important blockchain
                transaction data, ensuring that sensitive information such as senders, recipients, and amounts in
                transactions are not publicly exposed), we are building the world's first privacy public chain platform
                and constructing a blockchain full privacy ecosystem based on the privacy public chain.
              </Trans>
            </Typography>
            <Grid
              container
              rowSpacing={isSmallScreen ? 1 : 2}
              columnSpacing={isSmallScreen ? 1.25 : 2}
              sx={
                {
                  // "&>.MuiGrid-item:nth-child(2n-1)": {
                  //   pl: 0,
                  // },
                }
              }
            >
              <Grid item xs={6} md={3}>
                <PaperCard icon={PaperCard1} title={t`Privacy encrypted wallet`} bg={EncryptedWalletBg} left="50%" />
              </Grid>
              <Grid item xs={6} md={3}>
                <PaperCard icon={PaperCard2} title={t`Privacy hardware wallet`} bg={HardwareWalletBg} left="55%" />
              </Grid>
              <Grid item xs={6} md={3}>
                <PaperCard icon={PaperCard3} title={t`Privacy Transfer System`} bg={TransferSystemBg} left="55%" />
              </Grid>
              <Grid item xs={6} md={3}>
                <PaperCard icon={PaperCard4} title={t`Privacy Cross-Chain Function`} bg={CrossChainBg} left="50%" />
              </Grid>
              <Grid item xs={6} md={3}>
                <PaperCard icon={PaperCard5} title={t`Private Decentralized Exchange`} bg={DexBg} left="50%" />
              </Grid>
              <Grid item xs={6} md={3}>
                <PaperCard icon={PaperCard6} title={t`Privacy Stablecoin A`} bg={StableTAUSDBg} left="50%" />
              </Grid>
              <Grid item xs={6} md={3}>
                <PaperCard icon={PaperCard7} title={t`Privacy Smart Contract`} bg={SmartContractBg} left="55%" />
              </Grid>
              <Grid item xs={6} md={3}>
                <PaperCard icon={PaperCard8} title={t`Privacy token ZRC-20`} bg={Zrc20Bg} left="50%" />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "-105px",
              right: "-100px",
              width: "990px",
              height: "758px",
              zIndex: 0,
              background: `url(${PrivacyBg})`,
              // backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default Privacy;
