import { Button } from "@mui/material";
import { useWeb3Context } from "src/hooks/web3Context";
import { Trans } from "@lingui/macro";
import { useMobile } from "src/hooks";
const ConnectButton = () => {
  const { connect } = useWeb3Context();
  const { isSmallScreen } = useMobile();
  return (
    <Button
      variant="contained"
      className="connect-button"
      onClick={connect}
      sx={{
        width: "100%",
        maxWidth: isSmallScreen ? "100%" : "240px",
        borderRadius: "30px",
        border: "1.3px solid #B50C05",
        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
        "&:hover": {
          background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
        },
      }}
    >
      <Trans>Connect Wallet</Trans>
    </Button>
  );
};

export default ConnectButton;
