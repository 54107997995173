import {
  Typography,
  Box,
  Modal,
  Dialog,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import { Skeleton } from "@material-ui/lab";
import { trim, formatMoney, formatCurrency } from "../../helpers";
import { useAppSelector, useMobile } from "src/hooks";
import { ReactComponent as XIcon } from "src/assets/icons/x.svg";
import { ReactComponent as DAIIcon } from "src/assets/tokens/DAI.svg";
import { ReactComponent as USDTIcon } from "src/assets/tokens/USDT_swap.svg";
import { ReactComponent as OHMIcon } from "src/assets/tokens/token.svg";

import "./tokenlist.scss";
interface Iprops {
  open: boolean;
  inOrOut: string;
  topToken: string;
  bottomToken: string;
  handleClose: () => void;
  selectToken: (token: string, tokenIcon: any, bal: string, inOrOut: string) => void;
}
const TokenList = ({ open, handleClose, selectToken, inOrOut, topToken, bottomToken }: Iprops) => {
  const { isSmallScreen } = useMobile();

  const ohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.ohm;
  });
  const daiBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.dai;
  });
  const usdtBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.usdt;
  });

  return (
    <Modal id="hades" open={open} onClose={handleClose} hideBackdrop>
      <Paper
        className="ohm-card ohm-popover"
        sx={{
          maxWidth: isSmallScreen ? "345px !important" : "500px",
        }}
      >
        <Box display="flex" alignItems={"center"}>
          <IconButton onClick={handleClose}>
            <SvgIcon color="primary" component={XIcon} />
          </IconButton>
          <Typography
            sx={{
              fontSize: "20px",
              width: "80%",
            }}
            align="center"
          >
            Select a Token
          </Typography>
        </Box>
        <Box className="card-content">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="token-item"
            style={{ opacity: topToken == "LGNS" || bottomToken == "LGNS" ? "0.5" : "1" }}
            onClick={() => {
              if ((inOrOut == "in" && topToken == "LGNS") || (inOrOut == "out" && bottomToken == "LGNS")) {
                return;
              }
              selectToken("LGNS", OHMIcon, ohmBalance, inOrOut);
            }}
          >
            <Box display="flex" alignItems="center">
              <SvgIcon
                color="primary"
                component={OHMIcon}
                style={{ width: "32px", height: "32px", marginRight: "10px" }}
                viewBox="0 0 40 40"
              />
              <Typography variant="h5">LGNS</Typography>
            </Box>
            {ohmBalance ? (
              <Typography variant="h5">{trim(Number(ohmBalance), 4)}</Typography>
            ) : (
              <Skeleton width="100px" />
            )}
          </Box>

          {/* <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="token-item"
            style={{ opacity: topToken == "USDT" || bottomToken == "USDT" ? "0.5" : "1" }}
            onClick={() => {
              if ((inOrOut == "in" && topToken == "USDT") || (inOrOut == "out" && bottomToken == "USDT")) {
                return;
              }
              selectToken("USDT", USDTIcon, usdtBalance, inOrOut);
            }}
          >
            <Box display="flex" alignItems="center">
              <SvgIcon
                color="primary"
                component={USDTIcon}
                style={{ width: "32px", height: "32px", marginRight: "10px" }}
                viewBox="0 0 40 40"
              />
              <Typography variant="h5">USDT</Typography>
            </Box>
            {usdtBalance ? (
              <Typography variant="h5">{trim(Number(usdtBalance), 4)}</Typography>
            ) : (
              <Skeleton width="100px" />
            )}
          </Box> */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="token-item"
            style={{ opacity: topToken == "DAI" || bottomToken == "DAI" ? "0.5" : "1" }}
            onClick={() => {
              if ((inOrOut == "in" && topToken == "DAI") || (inOrOut == "out" && bottomToken == "DAI")) {
                return;
              }
              selectToken("DAI", DAIIcon, daiBalance, inOrOut);
            }}
          >
            <Box display="flex" alignItems="center">
              <SvgIcon
                color="primary"
                component={DAIIcon}
                style={{ width: "32px", height: "32px", marginRight: "10px" }}
                viewBox="0 0 40 40"
              />
              <Typography variant="h5">DAI</Typography>
            </Box>
            {daiBalance ? (
              <Typography variant="h5">{trim(Number(daiBalance), 4)}</Typography>
            ) : (
              <Skeleton width="100px" />
            )}
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default TokenList;
