import { LoadingButton } from "@mui/lab"
import { Box, Paper, Typography, Zoom } from "@mui/material"
import { t, Trans } from "@lingui/macro";
import { useMobile } from "src/hooks";
import detectEthereumProvider from "@metamask/detect-provider";
import { useWeb3Context } from "src/hooks/web3Context";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { error } from "src/slices/MessagesSlice";
import { str2hex } from "src/auth/utils";
import { WEB3_SIGN_MESSAGE } from "src/auth/auth-web3";
import { useAuthContext } from "src/auth/use-auth";

export const SignForm = (props) => {
    const dispatch = useDispatch();
    const { isSmallScreen } = useMobile();
    const { provider, address, connected, connect, chainID, signMessage } = useWeb3Context();
    const { login, user } = useAuthContext();

    // console.log('[debug]user:', user, address,(user?.address || '').toLowerCase() != address?.toLowerCase());
    const handleSign = useCallback(async () => {
        console.log(address, provider);
        if (address && provider) {
            let sig;
            try {
                sig = await signMessage()

                if (sig instanceof Error || sig.code === 4001) {
                    dispatch(error(`Error occurd while signing message!`));
                }
            }
            catch (err) {
                console.error(err);
                dispatch(error(`Error occurd while signing message!`));
                return;
            }

            login(address, sig)
        }

    }, [provider, address]);
    return (<Box
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        sx={{
            pt: isSmallScreen ? 2.5 : 0,
            px: isSmallScreen ? 1.875 : 0,
        }}
    ><Zoom in={true} timeout={1000}>
            <Paper
                className="ohm-card linear-card"
                sx={{
                    mb: isSmallScreen ? 1.875 : 3.125,
                    display: 'flex', justifyContent: 'center'
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: isSmallScreen ? "12px" : "24px",
                        background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                        // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                        py: 6,
                        display: 'flex', justifyContent: 'center'
                    }}
                >
                    <LoadingButton
                        variant="contained"
                        onClick={() => handleSign()}

                        sx={{

                            borderRadius: "30px",
                            border: "1.3px solid #B50C05",
                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                            "&:hover": {
                                background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
                                boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#FFF",
                                fontFamily: "AGaramond LT",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                textTransform: "none",
                            }}
                        >
                            <Trans>Sign by Wallet</Trans>

                        </Typography>
                    </LoadingButton>
                </Box>
            </Paper>
        </Zoom>
    </Box>
    )
}