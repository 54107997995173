import InfoTooltip from "../InfoTooltip/InfoTooltip";
import { Box, Backdrop, Modal, Paper, SvgIcon, Typography, Fade, useMediaQuery } from "@mui/material";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { ResponsiveContainer } from "recharts";
import { useMobile } from "src/hooks";
function ExpandedChart({
  open,
  handleClose,
  renderChart,
  data,
  infoTooltipMessage,
  headerText,
  headerSubText,
  runwayExtraInfo,
}) {
  const { isSmallScreen } = useMobile();
  const verySmallScreen = useMediaQuery("(max-width: 379px)");

  return (
    <Modal open={open} onClose={handleClose}>
      <Backdrop open={true}>
        <Fade in={true}>
          <Paper
            className="ohm-card ohm-popover"
            sx={{
              px: 2.5,
              py: 2.5,
              borderRadius: isSmallScreen ? "12px" : "24px",
              border: isSmallScreen ? "1px solid #434952" : "2px solid #434952",
              background: "linear-gradient(180deg, #34393f 0%, #18191d 100%, #181a1d 100%)",
              boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.4)",
            }}
          >
            <div className="chart-card-header">
              <Box display="flex">
                <Box display="flex" alignItems="center" style={{ width: "max-content", whiteSpace: "nowrap" }}>
                  <Typography
                    sx={{
                      color: "#868B8F",
                      fontFamily: "AGaramond LT",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    {headerText}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ width: "100%", marginLeft: "5px" }}
                >
                  {/* <Typography variant="h6" style={{ cursor: "pointer" }}>
                    <InfoTooltip message={infoTooltipMessage} />
                  </Typography> */}
                  <Typography variant="h6" style={{ cursor: "pointer" }}>
                    <SvgIcon component={XIcon} color="primary" onClick={handleClose} />
                  </Typography>
                </Box>
                {/* <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ width: "100%", marginLeft: "5px" }}
                >
                  <Typography variant="h6" style={{ cursor: "pointer" }}>
                    <InfoTooltip message={infoTooltipMessage} />
                  </Typography>
                  <Typography variant="h6" style={{ cursor: "pointer" }}>
                    <SvgIcon component={XIcon} color="primary" onClick={handleClose} />
                  </Typography>
                </Box> */}
              </Box>

              <Box display="flex" flexWrap="wrap">
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "21px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    mr: 0.5,
                  }}
                  style={{ fontWeight: 600, marginRight: 5 }}
                >
                  {headerSubText}
                </Typography>
                {runwayExtraInfo}
                <Typography
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "21px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    mr: 0.5,
                  }}
                >
                  Today
                </Typography>
              </Box>
            </div>

            <Box minWidth={300} width="100%">
              {data && data.length > 0 && (
                <ResponsiveContainer minHeight={260} minWidth={300}>
                  {renderChart}
                </ResponsiveContainer>
              )}
            </Box>
            {/* <Box display="flex" style={{ width: "100%", margin: "15px" }}>
              <Typography
                sx={{
                  color: "#868B8F",
                  fontFamily: "AGaramond LT",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                {infoTooltipMessage}
              </Typography>
            </Box> */}
          </Paper>
        </Fade>
      </Backdrop>
    </Modal>
  );
}

export default ExpandedChart;
