import { i18n } from "@lingui/core";
import { en, fr, ko, tr, zh, ja, vi, es, id, ar, de, ru, it,hi } from "make-plural/plurals";

// Declare locales
interface ILocale {
  flag: string;
  plurals: (n: number | string, ord?: boolean) => "zero" | "one" | "two" | "few" | "many" | "other";
}
interface ILocales {
  [locale: string]: ILocale;
}
// 阿拉伯语：ar
// 德语：de
// 法语：fr
// 俄语：ru
// 意大利语：it
export const locales: ILocales = {
  en: { flag: "gb", plurals: en },
  zh: { flag: "hk", plurals: zh },
  // fr: { flag: "fr", plurals: fr },
  ko: { flag: "kr", plurals: ko }, // "ko" is the language code for Korean
  ja: { flag: "jp", plurals: ja }, // "ja" is the language code for Japanese
  es: { flag: "es", plurals: es }, // "es" is the language code for Spanish 西班牙
  ar: { flag: "ae", plurals: ar }, //  "ar" 阿拉伯
  de: { flag: "de", plurals: de }, // "de"
  fr: { flag: "fr", plurals: fr }, // "fr"
  ru: { flag: "ru", plurals: ru },
  it: { flag: "it", plurals: it },
  // tr: { flag: "tr", plurals: tr }, // "tr" is the language code for Turkish 土耳其
  vi: { flag: "vn", plurals: vi }, // "vi" is the language code for Vietnamese 越南
  id: { flag: "id", plurals: id }, // "id" is the language code for Indonesian 印尼
  hi: {flag:"in", plurals: hi}
};

// Load locale data
for (var [key, locale] of Object.entries(locales)) {
  i18n.loadLocaleData(key, { plurals: locale.plurals });
}

async function fetchLocale(locale: string = "en") {
  const { messages } = await import(
    /* webpackChunkName: "[request]" */ `../locales/translations/olympus-frontend/${locale}/messages`
  );
  i18n.load(locale, messages);
  i18n.activate(locale);
}
export function selectLocale(locale: string) {
  window.localStorage.setItem("locale", locale);
  return fetchLocale(locale);
}
export function initLocale() {
  var locale = window.localStorage.getItem("locale") as string;
  if (!Object.keys(locales).includes(locale)) locale = "en";
  fetchLocale(locale);
}
