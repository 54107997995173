import LTBOLD from "../assets/fonts/AGaramond_LT_Bold.ttf";
import LTRegular from "../assets/fonts/AGaramond_LT_Regular.ttf";

const ltBold = {
  fontFamily: "AGaramond LT",
  fontStyle: "bold",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
		local('ADOBE_GARAMOND-Bold'),
		local('ADOBE_GARAMOND-Bold'),
		url(${LTBOLD}) format('ttf')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const ltRegular = {
  fontFamily: "AGaramond LT",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
		local('ADOBE_GARAMOND'),
		local('ADOBE_GARAMOND-Regular'),
		url(${LTRegular}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const fonts = [ltBold, ltRegular];

export default fonts;
