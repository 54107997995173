import { t, Trans } from "@lingui/macro";
import {
    Box,
    Paper,
    Zoom,
    Typography,
    Button,
    OutlinedInput,
    InputAdornment,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    TextField,
    Skeleton,
    Grid,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { trim, prettyVestingPeriod } from "src/helpers";

import NoData from "src/assets/images/noData.svg";
import {
    getTurbineData,
    getSilenceAmount,
    approveTurbine,
    turbineSilence,
    turbineRedeem,
    syncSilenceAmount,
} from "src/slices/TurbineSlice";
import { useDispatch } from "react-redux";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import { useAppSelector } from "src/hooks";
import { getBalances } from "src/slices/AccountSlice";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useMobile } from "src/hooks";

import { useAccount } from "wagmi";
import { error } from "../../slices/MessagesSlice";
import useDebounce from "../../hooks/Debounce";

import { PullToRefresh } from "src/components/PullToRefresh";
import { WebKeyBanner } from "./WebKeyBanner";
import { getStakeExInfo,getStakeExData, exStake, exStakeApprove } from 'src/slices/StakeExSlice';
import WebKeyCaims from "./WebKeyClaims";
import nftImage from 'src/assets/images/webkey_nft.png'
import phoneImage from 'src/assets/images/webkey_phone.jpg'



const WebKey = () => {
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(1);
    const { provider, address, chainID, connected, connect } = useWeb3Context();
    const SECONDS_TO_REFRESH = 60;
    const daiBal = useAppSelector(state => state.account.balances.dai);
    const stakeExLoading = useAppSelector(state => state.stakeex.loading);

    const unitCost = useAppSelector(state => state.stakeex.unitCost);
    const available = useAppSelector(state => state.stakeex.available);
    const stock = useAppSelector(state => state.stakeex.stock);
    const period = useAppSelector(state => state.stakeex.period);
    const allowance = useAppSelector(state => state.stakeex.allowanceDAI);
    const stakeList = useAppSelector(state=>state.stakeex.stakeList);
    
    const nftCount   = useMemo(()=>{
        if(stakeList?.length>0)return stakeList?.length %10;
        else return 0;
    },[stakeList]) 
    // const nftCount = 3;
    const phoneCount =useMemo(()=>{
        if(stakeList?.length>0)return Math.trunc(stakeList?.length/10);
        else return 0;
        
    },[stakeList]) 
    // const phoneCount = 2;

    const { isSmallScreen } = useMobile();
    const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);

    const pendingTransactions = useAppSelector(state => {
        return state.pendingTransactions;
    });

    const handleStake = async () => {
        await dispatch(exStake({ address, provider, networkID: chainID, quantity }));
        await dispatch(getStakeExData({ address, provider, networkID: chainID }));
    }

    const handleApprove = async () => {
        await dispatch(exStakeApprove({address, provider, networkID: chainID, quantity }));
        await dispatch(getStakeExData({ address, provider, networkID: chainID }));
    }

    const handleRedeem = async ()=>{

    }

    useEffect(() => {
        
        if (address) {
            dispatch(getStakeExData({ address, provider, networkID: chainID }));
        }
        else {
            dispatch(getStakeExInfo({provider,networkID: chainID }))
        }
    }, [address]);

    const renderNFT =()=>{
        const result = [];
        for(let i=0;i<nftCount;i++){
            result.push(
                <Box key={'nkk_'+i}  sx={{mb:1,display:'flex',justifyContent:'center',width:'100%'}}>
                    <img src={nftImage} style={{maxWidth:'300px'}}/>
                </Box>
            )
        }
        return result;
    }
    const renderPhone =()=>{
        const result = [];
        for(let i=0;i<phoneCount;i++){
            result.push(
                <Box key={'pkk_'+i}  sx={{mb:1,display:'flex',justifyContent:'center',width:'100%'}}>
                    <img src={phoneImage} style={{maxWidth:'300px'}}/>
                </Box>
            )
        }
        return result;
    }

    return (
        <PullToRefresh onRefresh={async () => {

        }}>
            <Box
                display="flex"
                flexDirection={"column"}
                alignItems="center"
                sx={{
                    pt: isSmallScreen ? 2.5 : 0,
                    px: isSmallScreen ? 1.875 : 0,
                }}
            >
                <WebKeyBanner />
                <Zoom in={true} timeout={1000}>
                    <Paper
                        className="ohm-card linear-card"
                        sx={{
                            mb: isSmallScreen ? 1.875 : 3.125,
                        }}
                    >
                        <Box
                            display={"flex"}
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                            }}
                        // sx={{ pt: 5.75, pb: 3.75 }}
                        >

                            <Box sx={{ mb: 3.75, pt: 2 }}>
                                <Typography
                                    sx={{
                                        color: "#868B8F",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "18px" : "26px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                    }}
                                    align="center"
                                >
                                    <Trans>WebKey Staking</Trans>
                                </Typography>
                                <Box sx={{ mt: 2, display: "flex", justifyContent: 'center' }}>
                                    {
                                        stakeExLoading ?
                                            <Skeleton variant="text" width={100}></Skeleton> :
                                            <Typography color='#ededed'>
                                                <Trans>Available</Trans>: {Number(available)}/{Number(stock)}
                                            </Typography>
                                    }
                                </Box>
                                {/* <Box>
                                    <Typography color='#ffffff'>
                                        {allowance}
                                    </Typography>
                                    <Typography color='#ffffff'>
                                        {period}
                                    </Typography>
                                    <Typography color='#ffffff'>
                                        {unitCost}
                                    </Typography>
                                </Box> */}

                                {/* <Box sx={{ display: 'flex', mt: 4 }}>
                                    <Button
                                        onClick={() => setQuantity(quantity - 1)}
                                        disabled={quantity <= 1}
                                        sx={{
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                            borderRadius: '48px',
                                            height: '42px',
                                            width: '42px',

                                        }}>
                                        <Typography sx={{
                                            marginTop: '-5px',
                                            color: '#ffffff',
                                            fontFamily: "AGaramond LT",
                                            fontSize: "32px",
                                            fontStyle: "normal",
                                            fontWeight: "700",
                                            lineHeight: "normal",
                                            overflow: "hidden",
                                        }}>
                                            -
                                        </Typography>
                                    </Button>
                                    <OutlinedInput
                                        id="amount-input"
                                        type="number"
                                        placeholder={t`Enter an amount`}
                                        className="stake-input"
                                        value={quantity}

                                        sx={{
                                            height: "42px",
                                            borderRadius: "60px",
                                            border: "1px solid rgba(138, 147, 160, 0.50)",
                                            background: "#3C3F48",
                                            color: "#c7c8cc",
                                            boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                                        }}

                                    />
                                    <Button
                                        onClick={() => setQuantity(quantity + 1)}
                                        disabled={quantity >= 100}
                                        sx={{
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                            borderRadius: '48px',
                                            height: '42px',
                                            width: '42px',

                                        }}>
                                        <Typography sx={{
                                            marginTop: '-5px',
                                            color: '#ffffff',
                                            fontFamily: "AGaramond LT",
                                            fontSize: "32px",
                                            fontStyle: "normal",
                                            fontWeight: "700",
                                            lineHeight: "normal",
                                            overflow: "hidden",
                                        }}>
                                            +
                                        </Typography>
                                    </Button>
                                </Box> */}
                            </Box>
                            <Box sx={{mb:4}}>
                                {
                                    !address?<Skeleton variant="text" width={120} height={45}/>:
                                    Number(allowance) >= (quantity * unitCost) ?
                                        <LoadingButton
                                            className="stake-button"
                                            variant="contained"
                                            onClick={handleStake}
                                            disabled={isPendingTxn(pendingTransactions, "ExStaking") || !connected}
                                            loading={isPendingTxn(pendingTransactions, "ExStaking")}
                                            // loadingPosition="end"
                                            sx={{
                                                // width: "100%",
                                                // height: "40px",

                                                borderRadius: "30px",
                                                border: "1.3px solid #B50C05",
                                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                                color: "#FFFFFF",
                                                fontFamily: "AGaramond LT",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                mb: isSmallScreen ? "20px !important" : 0,
                                                "&:hover": {
                                                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                    boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                                },
                                            }}
                                        >
                                            <Trans>Stake</Trans>
                                        </LoadingButton> :
                                        <LoadingButton
                                            className="stake-button"
                                            variant="contained"
                                            disabled={isPendingTxn(pendingTransactions, "StakeExApproving")}
                                            loading={isPendingTxn(pendingTransactions, "StakeExApproving")||stakeExLoading}
                                            // loadingPosition="end"
                                            onClick={() => {
                                                handleApprove();
                                            }}
                                            sx={{
                                                borderRadius: "30px",
                                                border: "1.3px solid #B50C05",
                                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                color: "#FFFFFF",
                                                fontFamily: "AGaramond LT",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                mb: isSmallScreen ? "20px !important" : 0,
                                                "&:hover": {
                                                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                    boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                },
                                            }}
                                        >
                                            <Trans>Approve</Trans>
                                        </LoadingButton>
                                }
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "1px",
                                    background: "#0B0C0A",
                                    boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                                    mb: 5.75,
                                }}
                            />
                            <Box
                                sx={{ px: isSmallScreen ? 2.5 : 3.75, width: "100%" }}
                                display={"flex"}
                                flexDirection={"column"}
                                // alignItems="center"
                                justifyContent="center"
                            >
                                <Box display="flex" sx={{ mb: 1.25 }}>
                                    <Typography
                                        sx={{
                                            color: "#C7C8CC",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}
                                    >
                                        <Trans>Balance:</Trans>
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#C7C8CC",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}
                                    >
                                        {trim(Number(daiBal), 2)} DAI
                                    </Typography>
                                    <Box sx={{ml:'auto'}}>
                                        
                                        <Typography sx={{
                                            color: "#C7C8CC",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}>
                                        <Trans>Spend</Trans>: {unitCost * quantity} DAI
                                    </Typography>
                                        
                                    </Box>
                                </Box>

                            </Box>
                        </Box>
                    </Paper>

                    
                </Zoom>
                
                <WebKeyCaims claimList={stakeList||[]} handleRedeem = {handleRedeem}/>
                {
                    stakeList?.length > 0 ? <Zoom in={true} timeout={1000}>
                        <Paper
                            className="ohm-card linear-card"
                            sx={{
                                mb: isSmallScreen ? 1.875 : 3.125,
                            }}
                        >
                            <Box
                                display={"flex"}
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: isSmallScreen ? "12px" : "24px",
                                    background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                    // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                                }}
                            // sx={{ pt: 5.75, pb: 3.75 }}
                            >
                                <Box sx={{pt:2,mb:2}}>
                                <Typography
                                    sx={{
                                        color: "#868B8F",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "18px" : "26px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                    }}
                                    align="center"
                                >
                                    <Trans>WebKey NFT</Trans>
                                </Typography>
                                </Box>
                                <Box sx={{display:'flex'}}>
                                    <Grid container>
                                        {
                                            renderPhone()
                                        }
                                        {
                                            renderNFT()
                                        }
                                        
                                    </Grid>
                                    
                                </Box>
                                
                                
                            </Box>
                        </Paper>
                    </Zoom>
                        : null
                }
            </Box>
        </PullToRefresh>
    );
};

export default WebKey;
