import { SvgIcon, Link, Box } from "@mui/material";
import { ReactComponent as Telegram } from "../../assets/icons/telegram.svg";
import { ReactComponent as Github } from "../../assets/icons/github.svg";
// import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import MediumMobile from "../../assets/icons/Medium_mobile.png";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Discord } from "../../assets/icons/discord.svg";
import { ReactComponent as Youtube } from "../../assets/icons/YouTube.svg";
import { ReactComponent as SocialIcon } from "../../assets/icons/aa.svg";
import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
// import { ReactComponent as Facebook } from "../../assets/icons/Facebook.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Social() {
  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  return (
    <Box display="flex" justifyContent={"space-between"} alignItems="center" sx={{ width: "100%", maxWidth: "220px" }}>
      {/* <Link href="" target="_blank">
        <SvgIcon color="primary" component={Github} />
      </Link> */}
      <Link href="https://t.me/SaluteOriginChat" target="_blank">
        <SvgIcon color="primary" component={Telegram} />
      </Link>

      <Link href="https://twitter.com/SaluteOrigin" target="_blank">
        <SvgIcon color="primary" component={Twitter} />
      </Link>
      <Link href="https://medium.com/@saluteorigin" target="_blank">
        <SvgIcon color="primary" component={Medium} />
      </Link>

      <Link href="https://www.youtube.com/@SaluteOrigin" target="_blank">
        <SvgIcon color="primary" component={Youtube} />
      </Link>
    </Box>
  );
}
