import { createTheme } from "@mui/material";
import fonts from "./fonts";


declare module '@mui/material/styles' {
  interface TypographyVariants {
    origin: React.CSSProperties;
    originLabel: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    origin?: React.CSSProperties;
    originLabel?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    originLabel: true;
    origin: true;
    h3: false;
  }
}



const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "@font-face": fonts,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "AGaramond LT",
          textTransform: "none",
        },
      },
      variants: [{
        props: { variant: 'origin' }, // 定义新变体的名称
        style: {
          color: "#c7c8cc",
          fontSize: "14px",
          fontWeight: "700",
          fontFamily: "AGaramond LT"
          // 添加其他你需要的样式
        },
      },
      {
        props: { variant: 'originLabel' }, // 定义新变体的名称
        style: {
          color: "#868b8f",
          fontSize: "14px",
          fontWeight: "700",
          fontFamily: "AGaramond LT"
          // 添加其他你需要的样式
        },
      },
      ],
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: "AGaramond LT !important",
          textTransform: "none",
          "&.Mui-disabled": {
            opacity: 0.5,
            cursor: "not-allowed !important",
            color: "#fff !important",
            "&>.MuiLoadingButton-loadingIndicator": {
              color: "white",
            },
          },
          "&.main-btn": {
            "&:hover": {
              background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
              boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
            },
          },
          "&.second-btn": {
            "&:hover": {
              background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
              boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&.Mui-disabled": {
            opacity: 0.5,
            cursor: "not-allowed",
            "&>.MuiLoadingButton-loadingIndicator": {
              color: "white",
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          zIndex: 20000,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.linear-card": {
            padding: "2px",
            // overflow: "hidden",
            background: "linear-gradient(to bottom , rgb(67, 73, 81), rgb(33, 36, 40))",
            boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
            borderRadius: "26px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // backgroundImage: "linear-gradient(to bottom , rgb(67, 73, 81), rgb(33, 36, 40))",
            // backgroundOrigin: "border-box",
            // backgroundClip: "padding-box, border-box",
          },
          "@media (max-width: 680px)": {
            "&.linear-card": {
              padding: "1px",
              borderRadius: "14px",
            },
          },
        },
      },
    },
    // MuiLoadingButton: {
    //   styleOverrides: {
    //     root: {},
    //     // "&.Mui-disabled": {
    //     //   opacity: "0.5",
    //     // },
    //   },
    // },
  },
});

export default theme;
