import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses, OHM_INITIAL_PRICE } from "../constants";
import CommunityAbiJson from "../abi/Community.json";
import RewardDistributorAbiJson from "../abi/RewardDistributor.json";
import THAExchangeAbiJson from "../abi/THAExchange.json";
import TurboLotteryAbiJson from "../abi/TurboLottery.json";
import FOMOLotteryAbiJson from "../abi/Lottery.json";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { bigNumber, setAll, getMarketPrice, fetchLotteryDataFromURL, toFullNumberString, toBN } from "../helpers";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import ierc20ABIJson from "../abi/IERC20.json";
import { error, info } from "./MessagesSlice";
import { FuseProxy, IERC20, SOhmv2, WsOHM, OlympusStakingv2 } from "src/typechain";
import { getBalances, loadAccountDetails, fetchAccountSuccess } from "./AccountSlice";
import apollo from "../lib/apolloClient";
import sOHMv2Json from "../abi/sOhmv2.json";
import LotteryAbiJson from "../abi/Lottery.json";
import { fetchQueryStakingStatus, fetchTeamWork } from "../helpers";

import { t } from "@lingui/macro";
import dayjs from "dayjs";
const sOHMv2 = sOHMv2Json.abi;
const CommunityAbi = CommunityAbiJson.abi;
const RewardDistributorAbi = RewardDistributorAbiJson.abi;
const THAExchangeAbi = THAExchangeAbiJson.abi;
const ierc20ABI = ierc20ABIJson.abi;
const LotteryAbi = LotteryAbiJson.abi;
export const getInviteData = createAsyncThunk(
  "community/getInviteData",
  async ({ provider, networkID, address, level }: any, { dispatch }: any) => {
    const protocolInitQuery = `
    query MyQuery {
      communities(where: {member: "${address}"}) {
          level
          referrals(first:1 orderBy: level, orderDirection: desc) {
            id
            level
          }
        }
      }
    `;
    const levelData = await apollo<any>(protocolInitQuery);
    // console.log("levelData", levelData);
    if (!levelData || levelData == null) {
      console.error("Returned a null response when querying TheGraph");
      return;
    }
    const initArray = levelData.data.communities;
    let maxLevel = 0;

    initArray.forEach((item: any) => {
      if (item.referrals && item.referrals.length > 0) {
        item.referrals.forEach((referral: any) => {
          let level = parseInt(referral.level, 10);
          if (level > maxLevel) {
            maxLevel = level;
          }
        });
      }
    });
    // find levelData item referrals level bigger
    // const biggerLevelData = levelData.data.communities.filter((item: any) => item.level > level);
    const accountLevel = levelData.data.communities.length > 0 ? levelData.data.communities[0].level : 0;
    // console.log("maxLevel", maxLevel, accountLevel);
    const protocolInviteQuery = `
    query MyQuery {
        communities(where: {member: "${address}"}) {
          teamQuantity
          teamStakingAmount
          directReferralQuantity
          level
          member
          referrer
          directReferrals {
            level
            id
            referrer
            member
            stakingAmount
          }
           referrals(where: {level: "${level ? level : Number(accountLevel) + 1}"}) {
            level
            member
            referrer
          }
        }
      }
      `;
    const graphData = await apollo<any>(protocolInviteQuery);
    if (!graphData || graphData == null) {
      console.error("Returned a null response when querying TheGraph");
      return;
    }
    let marketPrice: any = await getMarketPrice({ networkID, provider });
    // console.log("aae", OHM_INITIAL_PRICE, marketPrice);
    // console.log("aae", BigNumber.from(String(marketPrice * 1e9)));
    marketPrice = BigNumber.from(String(Math.floor(marketPrice) * 1e9));
    // const times = BigNumber.from(1)
    let times = Number(OHM_INITIAL_PRICE.div(marketPrice));
    let priceDown = true;
    if (marketPrice.gt(OHM_INITIAL_PRICE)) {
      priceDown = false;
      times = Number(marketPrice.div(OHM_INITIAL_PRICE));
    }
    // console.log("aae priceDown", priceDown, times);
    const stakeMinAmount = priceDown
      ? BigNumber.from(100000000000).mul(times)
      : BigNumber.from(100000000000).div(times);
    // console.log("aae stakeMinAmount", stakeMinAmount);

    const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider) as SOhmv2;

    const dataList = graphData.data.communities;
    const teamQuantiry = dataList.reduce((sum: number, item: any) => sum + Number(item.teamQuantity), 0);
    const teamStakingAmount = dataList.reduce(
      (sum: BigNumber, item: any) => sum.add(item.teamStakingAmount),
      ethers.BigNumber.from(0),
    );

    const directReferralQuantity = dataList.reduce(
      (sum: number, item: any) => sum + Number(item.directReferralQuantity),
      0,
    );
    // merge dataList item.referrals to a new array
    const referrals = dataList.reduce((sum: any, item: any) => {
      return sum.concat(item.referrals);
    }, []);
    if (maxLevel > 16 + Number(accountLevel)) maxLevel = 15 + Number(accountLevel);

    return {
      referralsList: referrals,
      maxLevel: maxLevel - accountLevel,
      directReferralQuantity: directReferralQuantity,
      teamNumber: teamQuantiry - dataList.length + 1,
      accountLevel,
      stakeMinGons: Number(stakeMinAmount),
    };
  },
);

export const communityJoin = createAsyncThunk(
  "community/communityJoin",
  async ({ provider, networkID, referer }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    // const signer = provider.getSigner();
    const signer = provider;
    const communityContract = new ethers.Contract(
      addresses[networkID].COMMUNITY_ADDRESS as string,
      CommunityAbi,
      signer,
    );
    let tx;
    try {
      const estimateGas = await communityContract.estimateGas.join(referer);
      tx = await communityContract.join(referer, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      const text = "Join";
      const pendingTxnType = "community_join";
      if (tx) {
        dispatch(fetchPendingTxns({ txnHash: tx.hash, text, type: pendingTxnType }));
        await tx.wait();
        return;
      }
    } catch (e: unknown) {
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (tx) {
        dispatch(clearPendingTxn(tx.hash));
      }
    }
  },
);

export const communityTurbine = createAsyncThunk(
  "community/communityTurbine",
  async ({ provider, networkID, type, address }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    console.log("communityTurbine", type, address);
    // const signer = provider.getSigner();
    const signer = provider;
    const communityRewardContract = new ethers.Contract(
      addresses[networkID].COMMUNITY_REWARD_ADDRESS as string,
      RewardDistributorAbi,
      signer,
    );
    // const stakingRewardContract = new ethers.Contract(
    //   addresses[networkID].STAKING_REWARD_ADDRESS as string,
    //   RewardDistributorAbi,
    //   signer,
    // );
    // const additionStakingRewardContract = new ethers.Contract(
    //   addresses[networkID].ADDITION_STAKING_REWARD_ADDRESS as string,
    //   RewardDistributorAbi,
    //   signer,
    // );
    const mintRewardContract = new ethers.Contract(
      addresses[networkID].MINT_REWARD_ADDRESS as string,
      RewardDistributorAbi,
      signer,
    );
    let tx;
    try {
      if (type === "community") {
        const estimateGas = await communityRewardContract.estimateGas.claim();
        tx = await communityRewardContract.claim({
          gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        });
        // } else if (type === "staking") {
        //   const estimateGas = await stakingRewardContract.estimateGas.claim();

        //   tx = await stakingRewardContract.claim({
        //     gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        //   });
        // } else if (type === "additionStaking") {
        //   const estimateGas = await additionStakingRewardContract.estimateGas.claim();

        //   tx = await additionStakingRewardContract.claim({
        //     gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        //   });
      } else if (type === "mint") {
        const estimateGas = await mintRewardContract.estimateGas.claim();

        tx = await mintRewardContract.claim({
          gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        });
      }
      // tx = await communityContract.join(referer);
      const text = "Turbine";
      const pendingTxnType = "community_turbine";
      if (tx) {
        dispatch(fetchPendingTxns({ txnHash: tx.hash, text, type: pendingTxnType }));
        await tx.wait();
        dispatch(loadAccountDetails({ provider, networkID, address: address }));
        return;
      }
    } catch (e: unknown) {
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        console.log(
          "(e as any).reason",
          (e as any).reason,
          t`${(e as any).reason.replace("execution reverted: ", "")}`,
        );
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (tx) {
        dispatch(clearPendingTxn(tx.hash));
      }
    }
  },
);

export const getCommunityData = createAsyncThunk(
  "community/getCommunityData",
  async ({ provider, networkID, address }: any, { dispatch }: any) => {
    const communityContract = new ethers.Contract(
      addresses[networkID].COMMUNITY_ADDRESS as string,
      CommunityAbi,
      provider,
    );
    // console.log("getCommunityData 1", address);
    try {
      const membersData = await communityContract.members(address);
      // console.log("getCommunityData 2", membersData);
      const quertStakingStatusData = await fetchQueryStakingStatus(address);
      // console.log("getCommunityData 3");
      const totalTeamWork = await fetchTeamWork(address);

      // console.log("queryStakingStatus", quertStakingStatusData);

      return {
        isInvited: membersData.referrer !== "0x0000000000000000000000000000000000000000",
        referrer: membersData.referrer,
        identity: quertStakingStatusData.role || "Normal",
        totalStakeAmount: ethers.utils.formatUnits(quertStakingStatusData.teamStaking, "9") || 0,
        totalLPBond: totalTeamWork.lpBond||0,
        totalStableBond: totalTeamWork.stableBond||0
      };
    } catch (error) {
      console.error("getCommunityData error", error);
      return {
        isInvited: false,
        referrer: "",
      };
    }
  },
);

export const getLotteryHourData = createAsyncThunk(
  "community/getLotteryHourData",
  async (
    { roundSequence, provider, networkID }: { roundSequence?: number; provider: any; networkID: number },
    { dispatch }: any,
  ) => {
    try {
      const lotteryContract = new ethers.Contract(addresses[networkID].LOTTERY_ADDRESS as string, LotteryAbi, provider);
      // console.log("getLotteryData", roundSequence);
      // get roundLength
      const roundLength = await lotteryContract.roundLength("0");
      // console.log("roundLength", roundLength);
      const round = await lotteryContract.roundAt("0", roundLength);
      // console.log("round", round);
      const lotteryList = await fetchLotteryDataFromURL("lottery/lotteryList", "Hour");
      // console.log(lotteryList, "getLotteryData", roundSequence);
      let lotteryWinners: any[] = [];
      if (roundSequence || roundSequence === 0) {
        lotteryWinners = await fetchLotteryDataFromURL("lottery/lotteryWinners", "Hour", roundSequence);
        lotteryWinners = lotteryWinners.map((item: any) => {
          return {
            ...item,
            rewards: item.rewards / Math.pow(10, 18),
            // rewards: ethers.utils.formatUnits(String(item.rewards), "18"),
            clearTime: dayjs(item.clearTime).format("YYYY-MM-DD HH:mm:ss"),
          };
        });
        // console.log(roundSequence, lotteryWinners, "getLotteryData lotteryWinners");
      } else {
        if (lotteryList.length === 0 || !lotteryList) {
        } else if (lotteryList.length < 3) {
          const arr = lotteryList.map((item: any) =>
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Hour", item.roundSequence),
          );
          // console.log(arr, "getLotteryData lotteryWinners");
          lotteryWinners = await Promise.all([...arr]);
          // console.log(lotteryWinners, "getLotteryData lotteryWinners");
          lotteryWinners = lotteryWinners.map((item: any) => {
            return item.map((_: any) => ({
              ..._,
              rewards: _.rewards / Math.pow(10, 18),
              // rewards: ethers.utils.formatUnits(toBN(_.rewards.toString()), "18"),
              clearTime: dayjs(_.clearTime).format("YYYY-MM-DD HH:mm:ss"),
            }));
          });
          // console.log(lotteryWinners, "getLotteryData lotteryWinners");
        } else {
          lotteryWinners = await Promise.all([
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Hour", lotteryList[0].roundSequence),
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Hour", lotteryList[1].roundSequence),
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Hour", lotteryList[2].roundSequence),
          ]);
          // console.log(
          //   lotteryWinners,
          //   "fetchLotteryDataFromURL lotteryWinners",
          //   lotteryList.map((item: any) => item.roundSequence),
          // );
          lotteryWinners = lotteryWinners.map((item: any) => {
            return item.map((_: any) => ({
              ..._,
              rewards: _.rewards / Math.pow(10, 18),
              // rewards: ethers.utils.formatUnits(String(_.rewards), "18"),
              clearTime: dayjs(_.clearTime).format("YYYY-MM-DD HH:mm:ss"),
            }));
          });
        }
      }
      // console.log("final", lotteryWinners);
      // lotteryWinners = lotteryWinners.map((item: any) => ({
      //   ...item,
      //   roundSequence: item.roundSequence,
      //   rewards: ethers.utils.formatUnits(String(item.rewards), "18"),
      // }));
      // console.log("final", lotteryWinners);
      return {
        winnerList: {
          Hour: lotteryWinners,
        },
        roundSequence: {
          Hour: lotteryList.map((item: any) => item.roundSequence),
        },
        hourRewards: ethers.utils.formatUnits(round.rewards, "18"),
        // hourThresholdSnapshot: ethers.utils.formatUnits(round.thresholdSnapshot, "18"),
      };
    } catch (error) {
      console.log("getLotteryData error", error);
      return {
        winnerList: {
          Hour: [],
        },
        roundSequence: {
          Hour: [],
        },
        hourRewards: "0",
        // hourThresholdSnapshot: "0",
      };
    }
  },
);

export const getLotteryWeekData = createAsyncThunk(
  "community/getLotteryWeekData",
  async (
    { roundSequence, provider, networkID }: { roundSequence?: number; provider: any; networkID: number },
    { dispatch }: any,
  ) => {
    try {
      const lotteryContract = new ethers.Contract(addresses[networkID].LOTTERY_ADDRESS as string, LotteryAbi, provider);

      // get roundLength
      const roundLength = await lotteryContract.roundLength("1");
      console.log("fetchLotteryDataFromURL");
      console.log("roundLength", roundLength);
      const round = await lotteryContract.roundAt("1", roundLength);
      console.log("round", round);
      const lotteryList = await fetchLotteryDataFromURL("lottery/lotteryList", "Week");
      console.log(lotteryList, "fetchLotteryDataFromURL");
      let lotteryWinners: any[] = [];
      if (roundSequence || roundSequence === 0) {
        lotteryWinners = await fetchLotteryDataFromURL("lottery/lotteryWinners", "Week", roundSequence);
        lotteryWinners = lotteryWinners.map((item: any) => {
          return {
            ...item,
            rewards: item.rewards / Math.pow(10, 18),
            // rewards: ethers.utils.formatUnits(String(item.rewards), "18"),
            clearTime: dayjs(item.clearTime).format("YYYY-MM-DD HH:mm:ss"),
          };
        });
        console.log(roundSequence, lotteryWinners, "fetchLotteryDataFromURL lotteryWinners");
      } else {
        if (lotteryList.length === 0) {
        } else if (lotteryList.length < 3) {
          const arr = lotteryList.map((item: any) =>
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Week", item.roundSequence),
          );
          console.log(arr, "fetchLotteryDataFromURL lotteryWinners");
          lotteryWinners = await Promise.all([...arr]);
          lotteryWinners = lotteryWinners.map((item: any) => {
            return item.map((_: any) => ({
              ..._,
              rewards: _.rewards / Math.pow(10, 18),
              // rewards: ethers.utils.formatUnits(toBN(_.rewards), "18"),
              clearTime: dayjs(_.clearTime).format("YYYY-MM-DD HH:mm:ss"),
            }));
          });
          console.log(lotteryWinners, "fetchLotteryDataFromURL lotteryWinners");
        } else {
          lotteryWinners = await Promise.all([
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Week", lotteryList[0].roundSequence),
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Week", lotteryList[1].roundSequence),
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Week", lotteryList[2].roundSequence),
          ]);
          lotteryWinners = lotteryWinners.map((item: any) => {
            return item.map((_: any) => ({
              ..._,
              rewards: _.rewards / Math.pow(10, 18),
              // rewards: ethers.utils.formatUnits(String(_.rewards), "18"),
              clearTime: dayjs(_.clearTime).format("YYYY-MM-DD HH:mm:ss"),
            }));
          });
          console.log(lotteryWinners, "fetchLotteryDataFromURL lotteryWinners");
        }
      }
      return {
        winnerList: {
          Week: lotteryWinners,
        },
        roundSequence: {
          Week: lotteryList.map((item: any) => item.roundSequence),
        },
        weekRewards: ethers.utils.formatUnits(round.rewards, "18"),
        // weekThresholdSnapshot: round.thresholdSnapshot?ethers.utils.formatUnits(round.thresholdSnapshot, "18"),
      };
    } catch (error) {
      console.log("getLotteryData error", error);
      return {
        winnerList: {
          Week: [],
        },
        roundSequence: {
          Week: [],
        },
        weekRewards: "0",
        // weekThresholdSnapshot: "0",
      };
    }
  },
);

export const getLotteryMonthData = createAsyncThunk(
  "community/getLotteryMonthData",
  async (
    { roundSequence, provider, networkID }: { roundSequence?: number; provider: any; networkID: number },
    { dispatch }: any,
  ) => {
    try {
      const lotteryContract = new ethers.Contract(addresses[networkID].LOTTERY_ADDRESS as string, LotteryAbi, provider);

      // get roundLength
      const roundLength = await lotteryContract.roundLength("2");
      console.log("month roundLength", roundLength);
      const round = await lotteryContract.roundAt("2", roundLength);
      console.log("month round", round);
      const lotteryList = await fetchLotteryDataFromURL("lottery/lotteryList", "Month");
      console.log(lotteryList, "fetchLotteryDataFromURL");
      let lotteryWinners: any[] = [];
      if (roundSequence || roundSequence === 0) {
        lotteryWinners = await fetchLotteryDataFromURL("lottery/lotteryWinners", "Month", roundSequence);
        lotteryWinners = lotteryWinners.map((item: any) => {
          return {
            ...item,
            rewards: item.rewards / Math.pow(10, 18),
            // rewards: ethers.utils.formatUnits(String(item.rewards), "18"),
            clearTime: dayjs(item.clearTime).format("YYYY-MM-DD HH:mm:ss"),
          };
        });
        console.log(roundSequence, lotteryWinners, "fetchLotteryDataFromURL lotteryWinners");
      } else {
        if (lotteryList.length === 0) {
        } else if (lotteryList.length < 3) {
          const arr = lotteryList.map((item: any) =>
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Month", item.roundSequence),
          );
          console.log(arr, "fetchLotteryDataFromURL lotteryWinners");
          lotteryWinners = await Promise.all([...arr]);
          lotteryWinners = lotteryWinners.map((item: any) => {
            return item.map((_: any) => ({
              ..._,
              rewards: _.rewards / Math.pow(10, 18),
              // rewards: ethers.utils.formatUnits(String(_.rewards), "18"),
              clearTime: dayjs(_.clearTime).format("YYYY-MM-DD HH:mm:ss"),
            }));
          });
          console.log(lotteryWinners, "fetchLotteryDataFromURL lotteryWinners");
        } else {
          lotteryWinners = await Promise.all([
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Month", lotteryList[0].roundSequence),
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Month", lotteryList[1].roundSequence),
            fetchLotteryDataFromURL("lottery/lotteryWinners", "Month", lotteryList[2].roundSequence),
          ]);
          lotteryWinners = lotteryWinners.map((item: any) => {
            return item.map((_: any) => ({
              ..._,
              rewards: _.rewards / Math.pow(10, 18),
              // rewards: ethers.utils.formatUnits(String(_.rewards), "18"),
              clearTime: dayjs(_.clearTime).format("YYYY-MM-DD HH:mm:ss"),
            }));
          });
          console.log(lotteryWinners, "fetchLotteryDataFromURL lotteryWinners");
        }
      }
      return {
        winnerList: {
          Month: lotteryWinners,
        },
        roundSequence: {
          Month: lotteryList.map((item: any) => item.roundSequence),
        },
        monthRewards:
          Number(ethers.utils.formatUnits(round.rewards, "18")) <= 0.0001
            ? "0"
            : ethers.utils.formatUnits(round.rewards, "18"),
        monthThresholdSnapshot: ethers.utils.formatUnits(round.thresholdSnapshot, "18"),
      };
    } catch (error) {
      console.log("getLotteryData error", error);
      return {
        winnerList: {
          Month: [],
        },
        roundSequence: {
          Month: [],
        },
        monthRewards: "0",
        monthThresholdSnapshot: "0",
      };
    }
  },
);

export const getAccountLotteryData = createAsyncThunk(
  "community/getAccountLotteryData",
  async ({ provider, networkID, address }: any, { dispatch }: any) => {
    try {
      const lotteryContract = new ethers.Contract(addresses[networkID].LOTTERY_ADDRESS as string, LotteryAbi, provider);
      const accountLotteryDataList = await Promise.all([
        lotteryContract.claimable("0", address),
        lotteryContract.claimable("1", address),
        lotteryContract.claimable("2", address),
      ]);
      // console.log("getAccountLotteryData", accountLotteryDataList);
      //   // const fomoLotteryTurnInfos = await fomoLotteryContract.claimableRewards(address);
      //   // const turboLotteryTurnInfos = await turboLotteryContract.claimableRewards(address);
      //   const accountLotteryDataList = await Promise.all([
      //     turboLotteryContract.claimableRewards(address),
      //     fomoLotteryContract.claimableRewards(address),
      //   ]);
      //   console.log("getAccountLotteryData", accountLotteryDataList);
      //   return {
      //     turboReward: ethers.utils.formatUnits(accountLotteryDataList[0], 18),
      //     fomoReward: ethers.utils.formatUnits(accountLotteryDataList[1], 18),
      //     // winnerList: fomoLotteryWinnerList,
      //   };、
      return {
        accountHourReward: ethers.utils.formatUnits(accountLotteryDataList[0], 18),
        accountWeekReward: ethers.utils.formatUnits(accountLotteryDataList[1], 18),
        accountMonthReward: ethers.utils.formatUnits(accountLotteryDataList[2], 18),
      };
    } catch (error) {
      console.log("getLotteryData error", error);
    }
  },
);

export const lotteryClaimReward = createAsyncThunk(
  "community/lotteryClaimReward",
  async ({ provider, networkID, address, type, amount }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    // const signer = provider.getSigner();
    const signer = provider;
    const lotteryContract = new ethers.Contract(addresses[networkID].LOTTERY_ADDRESS as string, LotteryAbi, signer);
    const claimAmount = ethers.utils.parseUnits(amount, "18");
    let tx;
    try {
      //   if (type === "turbo") {
      const estimateGas = await lotteryContract.estimateGas.claim(type, claimAmount);

      tx = await lotteryContract.claim(type, claimAmount, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      //   } else {
      //     const estimateGas = await fomoLotteryContract.estimateGas.claimRewards();

      //     tx = await fomoLotteryContract.claimRewards({
      //       gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      //     });
      //   }
      //   // console.log("tx", tx);
      const text = "ClaimRewards";
      const pendingTxnType = "reward_pool_claim";
      if (tx) {
        dispatch(fetchPendingTxns({ txnHash: tx.hash, text, type: pendingTxnType }));
        dispatch(getBalances({ provider, networkID, address }));
        await tx.wait();
        return;
      }
    } catch (e: unknown) {
      console.log("cliam reward error", error);
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (tx) {
        dispatch(clearPendingTxn(tx.hash));
      }
    }
  },
);
export const approveTHAExchange = createAsyncThunk(
  "community/approveTHAExchange",
  async ({ provider, networkID, address }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    // const signer = provider.getSigner();
    const signer = provider;
    const usdtContract = new ethers.Contract(addresses[networkID].USDT_ADDRESS as string, ierc20ABI, signer) as IERC20;
    let approveTx;
    try {
      const estimateGas = await usdtContract.estimateGas.approve(
        addresses[networkID].THA_EXCHANGE_ADDRESS,
        ethers.utils.parseUnits("100000"),
      );

      approveTx = await usdtContract.approve(
        addresses[networkID].THA_EXCHANGE_ADDRESS,
        ethers.utils.parseUnits("100000"),
        {
          gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        },
      );
      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_tha_exchange";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        // dispatch(getBalances({ provider, networkID, address }));
        // return;
      }
    } catch (e: unknown) {
      // console.log(e, "error", (e as any).code, (e as any).message);
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      // dispatch(error((e as any).message));
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
    const USDTContract = new ethers.Contract(
      addresses[networkID].USDT_ADDRESS as string,
      ierc20ABI,
      provider,
    ) as SOhmv2;

    const usdtAllowanceInTHAExchange = await USDTContract.allowance(address, addresses[networkID].THA_EXCHANGE_ADDRESS);
    return dispatch(
      fetchAccountSuccess({
        usdtAllowanceInTHAExchange,
      }),
    );
  },
);

export const thaExchange = createAsyncThunk(
  "community/thaExchange",
  async ({ provider, networkID, address, amount }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    // const signer = provider.getSigner();
    const signer = provider;
    const thaExchangeContract = new ethers.Contract(
      addresses[networkID].THA_EXCHANGE_ADDRESS as string,
      THAExchangeAbi,
      signer,
    );
    let tx;
    try {
      const estimateGas = await thaExchangeContract.estimateGas.exchange(bigNumber(String(amount), 18));

      tx = await thaExchangeContract.exchange(bigNumber(String(amount), 18), {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      // console.log("tx", tx);
      const text = "Exchange";
      const pendingTxnType = "tha_exchange";
      if (tx) {
        dispatch(fetchPendingTxns({ txnHash: tx.hash, text, type: pendingTxnType }));
        dispatch(getBalances({ provider, networkID, address }));
        await tx.wait();
        return;
      }
    } catch (e: unknown) {
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (tx) {
        dispatch(clearPendingTxn(tx.hash));
      }
    }
  },
);

interface ICommunitySlice {
  referralsList?: any[];
  fomoLotteryWinnerList?: any[];
  turboLotteryWinnerList?: any[];
  isInvited?: boolean;
  referrer?: string;
  turboReward?: string;
  fomoReward?: string;
  directReferralQuantity?: number;
  teamNumber?: string;
  totalStakeAmount?: number;
  totalStableBond?:number;
  totalLPBond?:number;
  accountLevel?: string;
  maxLevel?: string;
  stakeMinGons?: string;
  stakeMinAmount?: string;
  needWhitelist?: boolean;
  winnerList?: {
    Hour: any[];
    Week: any[];
    Month: any[];
  };
  roundSequence?: {
    Hour: any[];
    Week: any[];
    Month: any[];
  };
  hourRewards?: string;
  hourThresholdSnapshot?: string;
  weekRewards?: string;
  weekThresholdSnapshot?: string;
  monthRewards?: string;
  monthThresholdSnapshot?: string;
  accountHourReward?: string;
  accountWeekReward?: string;
  accountMonthReward?: string;
  identity?: string;
}

const initialState: ICommunitySlice = {
  // invitationList: [],
  // winnerList: [],
  // isInvited: false,
};

const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    fetchCommunitySliceSuccess(state: any, action: { payload: any }) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getCommunityData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getCommunityData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getCommunityData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getLotteryHourData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getLotteryHourData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getLotteryHourData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getLotteryWeekData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getLotteryWeekData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getLotteryWeekData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getLotteryMonthData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getLotteryMonthData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getLotteryMonthData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getAccountLotteryData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getAccountLotteryData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAccountLotteryData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getInviteData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getInviteData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getInviteData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default communitySlice.reducer;

export const { fetchCommunitySliceSuccess } = communitySlice.actions;

const baseInfo = (state: any) => state.communitySlice;

export const getCommunitySliceState = createSelector(baseInfo, (community: any) => community);
