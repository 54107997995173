import { makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@mui/material";
import NavContent from "./NavContent.jsx";

const drawerWidth = 230;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: 0,
  },
}));

function NavDrawer({ mobileOpen, handleDrawerToggle }) {
  const classes = useStyles();

  return (
    <Drawer
      variant="temporary"
      anchor={"left"}
      open={mobileOpen}
      onClose={handleDrawerToggle}
      onClick={handleDrawerToggle}
      classes={{
        paper: classes.drawerPaper,
      }}
      // sx={{ background: "transpar" }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <NavContent mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      {/* {isV2 ? <NavContentV2 /> : <NavContent />} */}
    </Drawer>
  );
}

export default NavDrawer;
