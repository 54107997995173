import {
  Typography,
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Button,
} from "@mui/material";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { t, Trans } from "@lingui/macro";
import "./turbineSettings.scss";
import { useMobile } from "src/hooks";

function TurbineSettings({
  open,
  handleClose,
  slippage,
  deadline,
  onDeadlineChange,
  onSlippageChange,
  onSlippageBlur,
  isBtn,
}) {
  const { isSmallScreen } = useMobile();

  return (
    <Modal
      id="hades"
      open={open}
      onClose={handleClose}
      hideBackdrop
      sx={{
        px: isSmallScreen ? 1.875 : 0,
      }}
    >
      <Paper className="ohm-card ohm-popover linear-card" sx={{}}>
        <Box
          sx={{
            borderRadius: isSmallScreen ? "12px" : "24px",
            background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
            // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
            px: isSmallScreen ? 2.5 : 3.75,
            py: 2.5,
          }}
        >
          <Box display="flex">
            <IconButton
              onClick={handleClose}
              sx={{
                p: 0,
              }}
            >
              <SvgIcon sx={{ color: "#C7C8CC" }} component={XIcon} />
            </IconButton>
            <Typography
              sx={{
                color: "#C7C8CC",
                fontFamily: "AGaramond LT",
                fontSize: "21px",
                fontStyle: "normal",
                fontWeight: "700",
                width: "86%",
              }}
              align="center"
              // variant="h3"
            >
              <Trans>Transaction Settings</Trans>
            </Typography>
          </Box>

          <Box className="card-content">
            <InputLabel
              htmlFor="slippage"
              sx={{
                color: "#FFF",
                fontFamily: "AGaramond LT",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
              }}
            >
              <Trans>Slippage</Trans>
            </InputLabel>
            <FormControl variant="outlined" color="primary" fullWidth>
              <Box display="flex" alignItems="center" justifyContent="space-around" className="slippage-container">
                <Button
                  variant="contained"
                  className={`slippage-item`}
                  onClick={() => onSlippageChange("0.1", "btn")}
                  style={{
                    color: "#fff",
                    borderRadius: "30px",
                    width: "100%",
                    maxWidth: isSmallScreen ? "65px" : "110px",
                    height: isSmallScreen ? "25px" : "40px",
                    fontSize: isSmallScreen ? "12px" : "20px",
                    background:
                      isBtn == "btn" && slippage == "0.1"
                        ? "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)"
                        : "linear-gradient(0deg, #464B55 0%, #101114 100%)",
                    border: isBtn == "btn" && slippage == "0.1" ? "1px solid #FF8555" : "1px solid #5F6674",
                    boxShadow:
                      isBtn == "btn" && slippage == "0.1"
                        ? "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059"
                        : "0px 2px 8px 0px rgba(26, 28, 31, 0.80)",
                  }}
                >
                  0.1%
                </Button>
                <Button
                  variant="contained"
                  className="slippage-item"
                  onClick={() => onSlippageChange("0.5", "btn")}
                  style={{
                    color: "#fff",
                    borderRadius: "30px",
                    width: "100%",
                    maxWidth: isSmallScreen ? "65px" : "110px",
                    height: isSmallScreen ? "25px" : "40px",
                    fontSize: isSmallScreen ? "12px" : "20px",
                    background:
                      isBtn == "btn" && slippage == "0.5"
                        ? "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)"
                        : "linear-gradient(0deg, #464B55 0%, #101114 100%)",
                    border: isBtn == "btn" && slippage == "0.5" ? "1px solid #FF8555" : "1px solid #5F6674",
                    boxShadow:
                      isBtn == "btn" && slippage == "0.5"
                        ? "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059"
                        : "0px 2px 8px 0px rgba(26, 28, 31, 0.80)",
                  }}
                >
                  0.5%
                </Button>
                <Button
                  variant="contained"
                  className="slippage-item"
                  onClick={() => onSlippageChange("1.0", "btn")}
                  style={{
                    color: "#fff",
                    borderRadius: "30px",
                    width: "100%",
                    maxWidth: isSmallScreen ? "65px" : "110px",
                    height: isSmallScreen ? "25px" : "40px",
                    fontSize: isSmallScreen ? "12px" : "20px",
                    background:
                      isBtn == "btn" && slippage == "1.0"
                        ? "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)"
                        : "linear-gradient(0deg, #464B55 0%, #101114 100%)",
                    border: isBtn == "btn" && slippage == "1.0" ? "1px solid #FF8555" : "1px solid #5F6674",
                    boxShadow:
                      isBtn == "btn" && slippage == "1.0"
                        ? "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059"
                        : "0px 2px 8px 0px rgba(26, 28, 31, 0.80)",
                  }}
                >
                  1.0%
                </Button>
                <OutlinedInput
                  id="slippage"
                  value={slippage}
                  onChange={e => onSlippageChange(e.target.value, "ipt")}
                  onBlur={e => onSlippageBlur(e.target.value)}
                  type="number"
                  // max="100"
                  // min="0"
                  className="slippage-ipt"
                  style={{
                    marginRight: isSmallScreen ? "0" : "12px",
                    pr: isSmallScreen ? "4px" : "14px",
                    height: isSmallScreen ? "25px" : "40px",
                    color: "#fff",
                    textAlign: "center",
                    borderRadius: "60px",
                    border: "1px solid rgba(138, 147, 160, 0.50)",
                    background: "#3C3F48",
                    boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                  }}
                  endAdornment={
                    <InputAdornment
                      className="last-icon"
                      position="end"
                      sx={{
                        "& > p": {
                          color: "#fff",
                        },
                      }}
                    >
                      %
                    </InputAdornment>
                  }
                />
                <Typography
                  className={`slippage-precent`}
                  sx={{
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: "700",
                  }}
                >
                  %
                </Typography>
              </Box>
              <div className="help-text">
                <Typography
                  sx={{
                    color: "#A3A3A3",
                    fontFamily: "AGaramond LT",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "700",
                  }}
                >
                  <Trans>Transaction may revert if price changes by more than slippage %</Trans>
                </Typography>
              </div>
            </FormControl>

            <InputLabel
              htmlFor="recipient"
              sx={{
                color: "#FFF",
                fontFamily: "AGaramond LT",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
              }}
            >
              <Trans>Transaction deadline</Trans>
            </InputLabel>
            <FormControl variant="outlined" color="primary" fullWidth>
              <OutlinedInput
                id="recipient"
                value={deadline}
                onChange={onDeadlineChange}
                type="text"
                className="recipient-ipt"
                sx={{
                  height: "42px",
                  borderRadius: "60px",
                  border: "1px solid rgba(138, 147, 160, 0.50)",
                  background: "#3C3F48",
                  color: "#c7c8cc",
                  boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#B50C05 !important",
                    borderWidth: "1px !important",
                  },
                  "& > input": {
                    height: "100% !important",
                    padding: "0 12px !important",
                    fontSize: isSmallScreen ? "12px !important" : "16px !important",
                  },
                }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    sx={{
                      color: "#FFF",
                      fontFamily: "AGaramond LT",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: "700",
                    }}
                  >
                    <Trans>minutes</Trans>
                  </InputAdornment>
                }
              />
              <div className="help-text">
                <Typography sx={{ color: "#A3A3A3", fontSize: "12px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                  <Trans>Transaction may revert if it is pending for more than this long.</Trans>
                </Typography>
              </div>
            </FormControl>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

export default TurbineSettings;
