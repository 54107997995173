import { useEffect, useState } from 'react';
import axios from 'axios';
import { useWeb3Context } from 'src/hooks';
import { Box, Zoom,Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { t, Trans } from "@lingui/macro";
import dayjs from "dayjs";

export const TurbineHistory = ({ isSmallScreen }) => {

    const [history, setTurbineRecords] = useState([]);
    const { address } = useWeb3Context();

    const TurbineType = {
        'TurbineReceived': t`TurbineReceived`,
        'TurbineSilenced': t`TurbineSilenced`,
        'TurbineRedeemed': t`TurbineRedeemed`
    }

    useEffect(() => {
        if (address) {
            axios.get(`${process.env.REACT_APP_ADMIN_API_URL}/turbine/${address}`).then(ret => {
                setTurbineRecords(ret.data);
            })
        }
    }, [address])

    return (
        <Zoom in={true} timeout={1000}>
            <Paper
                className="ohm-card linear-card"
                sx={{
                    mb: 3.125,
                }}
            >
                <Box display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ width: "100%", mb: 5 }}
                // ref={scrollTarget}
                >
                    <Paper className={`ohm-card token-card linear-card`} sx={{}}>
                        <Box
                            sx={{
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                // border: "2px solid #434952",
                                // background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                                px: isSmallScreen ? 2.5 : 3.75,
                                py: isSmallScreen ? 1.875 : 2.5,
                            }}
                        >
                            <Typography sx={{
                                color: "#C7C8CC",
                                fontFamily: "AGaramond LT",
                                fontSize: "21px",
                                fontStyle: "normal",
                                fontWeight: "700",
                            }}>
                                <Trans>Turbine History</Trans>
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="originLabel">
                                                <Trans>Datetime</Trans>
                                            </Typography>

                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="originLabel">
                                                <Trans>Type</Trans>
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography variant="originLabel">
                                                <Trans>Amount</Trans>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        history.map((h, idx) => {
                                            return (
                                                <TableRow key={'srk_' + idx}>
                                                    <TableCell padding="none">
                                                        <Typography variant="originLabel">
                                                            {dayjs(h.timestamp * 1000).format('MM-DD HH:mm')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="originLabel" sx={{
                                                            color:h.category=='TurbineRedeemed'?'#00ed00':h.category=='TurbineSilenced'?'#ed0000':'#fff'
                                                        }}>
                                                            {TurbineType[h.category]}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Typography variant="origin">
                                                            {Number(h.amount).toFixed(4)}
                                                        </Typography>
                                                    </TableCell>

                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>

                        </Box>
                    </Paper>

                </Box>
            </Paper>
        </Zoom>
    )
}