import { Box, Paper, Skeleton, Typography } from "@mui/material";
import React from "react";
import CardBg from "../../assets/images/homeDataCardBg.png";
import { Trans } from "@lingui/macro";
import { trim } from "src/helpers";

const HomeDataCardList = ({ treasuryMarketValue, stakingAPY, tokenBalInStaking, marketValue }: any) => {
  return (
    <Box
      sx={{
        width: "100%",
        // px:2.5,
        mb: 6.25,
      }}
    >
      <Paper
        className="linear-card"
        sx={{
          height: "100px",
          // overflow: "hidden",
          mb: 1,
        }}
      >
        <Box
          sx={{
            background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
            // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
            borderRadius: "12px",
            // border: "1px solid #434952",
            width: "100%",
            height: "100%",
            position: "relative",
            py: 3,
          }}
        >
          <Box sx={{ position: "relative", zIndex: 1, width: "100%" }} display={"flex"}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: "14px",
                  fontWeight: "400",
                  mb: 0.125,
                }}
                align="center"
              >
                <Trans>Total LGNS Staked</Trans>
              </Typography>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: "24px",
                  fontWeight: "500",
                }}
                align="center"
              >
                {tokenBalInStaking ? trim(tokenBalInStaking, 2) : <Skeleton width="100px" />}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ position: "absolute", right: "0px", bottom: "-10px", zIndex: "0" }}>
            <img src={CardBg} />
          </Box>
        </Box>
      </Paper>
      <Paper
        sx={{
          borderRadius: "12px",
          border: "1px solid #434952",
          background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
          boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
          height: "100px",
          overflow: "hidden",
          mb: 1,
        }}
      >
        <Box sx={{ width: "100%", height: "100%", position: "relative", py: 3 }}>
          <Box sx={{ position: "relative", zIndex: 1, width: "100%" }} display={"flex"}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: "14px",
                  fontWeight: "400",
                  mb: 0.125,
                }}
                align="center"
              >
                <Trans>Treasury Balance</Trans>
              </Typography>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: "24px",
                  fontWeight: "500",
                }}
                align="center"
              >
                {typeof treasuryMarketValue == "number" ? (
                  `${new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(treasuryMarketValue)}`
                ) : (
                  <Skeleton width="200px" />
                )}
              </Typography>
            </Box>
            {/* <Box sx={{ width: "50%" }}>1</Box>
          <Box sx={{ width: "50%" }}>2</Box> */}
          </Box>
          <Box sx={{ position: "absolute", right: "0px", bottom: "-10px", zIndex: "0" }}>
            <img src={CardBg} />
          </Box>
        </Box>
      </Paper>
      <Paper
        sx={{
          borderRadius: "12px",
          border: "1px solid #434952",
          background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
          boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
          height: "100px",
          overflow: "hidden",
          mb: 1,
        }}
      >
        <Box sx={{ width: "100%", height: "100%", position: "relative", py: 3 }}>
          <Box sx={{ position: "relative", zIndex: 1, width: "100%" }} display={"flex"}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: "14px",
                  fontWeight: "400",
                  mb: 0.125,
                }}
                align="center"
              >
                <Trans>MarketCap</Trans>
              </Typography>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: "24px",
                  fontWeight: "500",
                }}
                align="center"
              >
                {marketValue ? (
                  `${new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(marketValue)}`
                ) : (
                  <Skeleton width="100px" />
                )}
              </Typography>
            </Box>
            {/* <Box sx={{ width: "50%" }}>1</Box>
          <Box sx={{ width: "50%" }}>2</Box> */}
          </Box>
          <Box sx={{ position: "absolute", right: "0px", bottom: "-10px", zIndex: "0" }}>
            <img src={CardBg} />
          </Box>
        </Box>
      </Paper>
      <Paper
        sx={{
          borderRadius: "12px",
          border: "1px solid #434952",
          background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
          boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
          height: "100px",
          overflow: "hidden",
          mb: 1,
        }}
      >
        <Box sx={{ width: "100%", height: "100%", position: "relative", py: 3 }}>
          <Box sx={{ position: "relative", zIndex: 1, width: "100%" }} display={"flex"}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: "14px",
                  fontWeight: "400",
                  mb: 0.125,
                }}
                align="center"
              >
                <Trans>Current APY</Trans>
              </Typography>
              <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "Neue Haas Grotesk Text Pro",
                  fontSize: "24px",
                  fontWeight: "500",
                }}
                align="center"
              >
                {stakingAPY ? (
                  `${new Intl.NumberFormat("en-US").format(Number(stakingAPY))}%`
                ) : (
                  <Skeleton width="100px" />
                )}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ position: "absolute", right: "0px", bottom: "-10px", zIndex: "0" }}>
            <img src={CardBg} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default HomeDataCardList;
