import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import {
  Box,
  Button,
  Paper,
  Typography,
  Zoom,
  SvgIcon,
  InputBase
} from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { LoadingButton } from "@mui/lab";

import { trim, formatMoney, formatCurrency } from "../../helpers";
import { useUnmount, useThrottleFn, useDebounceEffect, useDebounceFn } from "ahooks";
import { getAmountsOut, swapToken, approveSwap, getSelectToken, clearAmount } from "../../slices/SwapThunk";
import { getBalances } from "../../slices/AccountSlice";
import "./swap.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@material-ui/lab";
import { error } from "../../slices/MessagesSlice";
import { ethers } from "ethers";
import { useAppSelector, useMobile } from "src/hooks";
import { ReactComponent as DAIIcon } from "../../assets/tokens/DAI.svg";
import { ReactComponent as LGNSIcon } from "../../assets/tokens/token.svg";
import { ReactComponent as ExchangeIcon } from "../../assets/icons/exchange.svg";
import { loadAccountDetails, calculateUserBondDetails } from "../../slices/AccountSlice";
import { ReactComponent as DownIcon } from "../../assets/icons/arrow-down.svg";
import TokenList from "./TokenList.tsx";

function Swap({ theme }) {
  const { isSmallScreen } = useMobile();

  const dispatch = useDispatch();
  const { provider, address, connected, connect, chainID } = useWeb3Context();
  const [amountIn, setAmountIn] = useState("");
  const [amountOut, setAmountOut] = useState("");
  const [zoomed, setZoomed] = useState(false);
  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [slippage, setSlippage] = useState(localStorage.getItem("slippage") || 0.1);
  const [deadline, setDeadline] = useState(5);
  const [price, setPrice] = useState("");
  const [amountOutMin, setAmountOutMin] = useState("");
  const [topToken, setTopToken] = useState("DAI");
  const [topIcon, setTopIcon] = useState(DAIIcon);
  const [bottomToken, setBottomToken] = useState("LGNS");
  const [bottomIcon, setBottomIcon] = useState(LGNSIcon);
  const [isBtn, setIsBtn] = useState(localStorage.getItem("isBtn") || "btn");
  // const [topBal, setTopBal] = useState("");

  // const [bottomBal, setBottomBal] = useState("");
  const [open, setOpen] = useState(false);
  const [tokenListOpen, setTokenListOpen] = useState(false);
  const [top2Bottom, setTop2Bottom] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [fee, setFee] = useState("");
  const [inOrOut, setInorOut] = useState("");
  // const [recipientAddress, setrecipientAddress] = useState("");

  const isAppLoading = useAppSelector(state => state.app.loading);
  const amountsOut = useAppSelector(state => state.swap.amountsOut);
  const amountsIn = useAppSelector(state => state.swap.amountsIn);
  const loading = useAppSelector(state => state.swap.loading);
  const topBal = useAppSelector(state => state.swap.topBal);
  const bottomBal = useAppSelector(state => state.swap.bottomBal);
  

  const LGNSBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.LGNS;
  });
  const busdBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.busd;
  });
  const LGNSAllowanceInRouter = useAppSelector(state => {
    return state.account.ohmAllowanceInRouter;
  });
  const busdAllowanceInRouter = useAppSelector(state => {
    return state.account.busdAllowanceInRouter;
  });
  const usdtBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.usdt;
  });

  const usdtAllowanceInRouter = useAppSelector(state => {
    return state.account.usdtAllowanceInRouter;
  });

  const daiAllowanceInRouter = useAppSelector(state => {
    return state.account.daiAllowanceInRouter;
  });

  const marketPrice = useAppSelector(state => {
    return state.app.marketPrice;
  });
  const sLGNSBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.sOHM;
  });

  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });

  // console.log("avc", topBal, bottomBal, LGNSAllowanceInRouter);
  // useEffect(() => {
  //   dispatch(getExchangeBalance({ provider, address, networkID: chainID }));
  //   // dispatch(getBalances({ provider, address, networkID: chainID }));
  // }, [provider, dispatch, pendingTransactions.length > 0]);
  // useEffect(() => {
  //   if (topToken == "BUSD") {
  //     setTopBal(busdBalance);
  //     setBottomBal(LGNSBalance);
  //   }
  // }, [busdBalance, LGNSBalance, topToken, bottomToken]);
  // }, [busdBalance, LGNSBalance]);

  useUnmount(() => {
    dispatch(clearAmount());
  });

  useEffect(() => {
    dispatch(loadAccountDetails({ address, provider, networkID: chainID }));
    if (address && provider) {
      console.log("topToken", topToken);
      dispatch(getSelectToken({ provider, address, networkID: chainID, topToken, bottomToken }));
    }
  }, [isAppLoading, bottomToken, topToken, address]);

  useEffect(() => {
    setAmountOut(trim(amountsOut, 8));
    const amountOutsMin = amountsOut * (1 - slippage / 100);
    if ((topToken == "BNB" && bottomToken == "WBNB") || (topToken == "WBNB" && bottomToken == "BNB")) {
      setAmountOutMin(amountsOut);
    } else {
      setAmountOutMin(amountOutsMin);
    }
  }, [amountsOut, slippage]);

  useEffect(() => {
    setAmountIn(trim(amountsIn, 4));
  }, [amountsIn]);

  const setMax = () => {
    if (view === 0) {
      setQuantity(Number(LGNSBalance));
    } else {
      setQuantity(Number(sLGNSBalance));
    }
  };

  const hasAllowance = useCallback(
    token => {
      console.log("token", token, usdtAllowanceInRouter);
      if (token === "LGNS") return Number(LGNSAllowanceInRouter);
      if (token === "DAI") return Number(daiAllowanceInRouter);
      if (token === "USDT") return Number(usdtAllowanceInRouter);

      return 0;
    },
    [LGNSAllowanceInRouter, usdtAllowanceInRouter, connected, provider, address, daiAllowanceInRouter],
  );

  const onChangeTop = async value => {
    // console.log("val", value);
    if (!value) {
      setAmountIn("");
      setAmountOut("");
      setPrice("");
      dispatch(clearAmount());
      // cancel();
      return;
    }
    setIsChanged(true);
    setAmountIn(value);

    // run(value);
    await dispatch(
      getAmountsOut({
        address,
        provider,
        networkID: chainID,
        amountsIn: value,
        type: topToken != "LGNS",
        topToken,
        bottomToken,
      }),
    );

    setIsChanged(false);
  };

  const onBlurIpt = async value => {
    //   console.log("hangetopo", value);
    if (!value) {
      setAmountIn("");
      setAmountOut("");
    }
  };

  const approveForSwap = async () => {
    // console.log("approve");
    await dispatch(
      approveSwap({ address, provider, networkID: chainID, type: topToken == "BUSD", topToken, bottomToken }),
    );
    await Promise.all([
      dispatch(getBalances({ address, provider, networkID: chainID })),
      dispatch(loadAccountDetails({ address, provider, networkID: chainID })),
    ]);
  };

  const exchangeTokenForToken = async () => {
    if (!amountIn || !amountOut) {
      setAmountOut("");
      setAmountIn("");
      return;
    }
    await dispatch(
      swapToken({
        address,
        provider,
        networkID: chainID,
        amountsIn: amountIn,
        amountsOut: amountOut,
        // type: topToken == "BUSD",
        topToken,
        bottomToken,
      }),
    );
    // dispatch(getBalances({ address, provider, networkID: chainID }));
    setAmountOut("");
    setAmountIn("");
    dispatch(getSelectToken({ address, provider, networkID: chainID, topToken, bottomToken }));
    // setTopBal();
    // setBottomBal();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const exchangeToken = () => {
    setTopToken(bottomToken);
    setBottomToken(topToken);
    setAmountIn(amountOut);
    setAmountOut(amountIn);
    setTopIcon(bottomIcon);
    setBottomIcon(topIcon);
    dispatch(getSelectToken({ address, provider, networkID: chainID, topToken: bottomToken, bottomToken: topToken }));
  };

  // useDebounceEffect(
  //   () => {
  useEffect(
    () => {
      // console.log(amountOut, "amountOut", amountsOut);
      if (!!amountIn && !!amountOut && !isChanged) {
        const tempPrice = !top2Bottom ? amountOut / amountIn : amountIn / amountOut;
        setPrice(trim(tempPrice, 4));
        const tempFee = (amountIn * 2.5) / 1000;
        setFee(trim(tempFee, 2));
      }
    },
    [amountOut, amountIn, top2Bottom, isChanged],
    // { wait: 100 },
  );

  const handleCloseTokenList = () => {
    setTokenListOpen(false);
  };

  const handleOpenTokenList = type => {
    if (!connected) connect();
    else {
      // if (busdBalance && LGNSBalance) {
      setInorOut(type);
      setTokenListOpen(true);
    }
  };

  const selectToken = (token, tokenIcon, bal, type) => {
    setTokenListOpen(false);
    setAmountIn("");
    setAmountOut("");
    setPrice("");
    setFee("");
    if (type == "in") {
      if (token == bottomToken) {
        exchangeToken();
        return;
      }
      setTopToken(token);
      setTopIcon(tokenIcon);
      // setTopBal(bal);
      dispatch(getSelectToken({ address, provider, networkID: chainID, topToken: token, bottomToken }));
    } else {
      if (token == topToken) {
        exchangeToken();
        return;
      }
      setBottomToken(token);
      setBottomIcon(tokenIcon);
      dispatch(getSelectToken({ address, provider, networkID: chainID, topToken, bottomToken: token }));
    }
  };
  // useEffect(() => {
  //   // console.log(top);
  //   if (!amountIn || !amountOut) {
  //     setAmountOut("");
  //     setAmountIn("");
  //   }
  // }, [amountIn, amountOut]);

  return (
    <Box
      sx={{
        px: isSmallScreen ? "15px" : 0,
        pt: isSmallScreen ? "40px" : "0",
      }}
      id="swap-view"
    >
      <Zoom in={true} onEntered={() => setZoomed(true)}>
        <Paper
          className="ohm-card linear-card"
          sx={{
            width: "100%",
            maxWidth: "480px",
            // height: "545px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: isSmallScreen ? "12px" : "24px",
              background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
              // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
              // py: 6,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              justifyContent="center"
              alignItems="center"
              // style={{ paddingTop: "60px" }}
              sx={{
                px: isSmallScreen ? "21px" : "30px",
                py: isSmallScreen ? "27px" : "38px",
              }}
            >
              {/* <Paper className={`ido-card`}> */}
              <Box
                className="swap-header"
                sx={{
                  mb: isSmallScreen ? "28px" : "40px",
                }}
              >
                <Box display="flex" justifyContent="flex-start" alignItems="center" style={{position:'relative'}}>
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontSize: isSmallScreen ? "15px" : "21px",
                      fontStyle: "normal",
                      fontWeight: "700",
                    }}
                  >
                    Swap On LGNS
                  </Typography>
                  <Box sx={{position:'absolute',right:0,display:'flex',color:'#c1c2c3'}}>
                  <Typography style={{fontWeight:600}}>{marketPrice?Number(marketPrice).toFixed(3):'--'}
                  </Typography>
                  <Typography style={{fontSize:12,marginTop:4,fontWeight:400,marginLeft:8}}>DAI/LGNS</Typography>
                </Box>
                </Box>
              </Box>
              {/* top token */}
              <Box
                sx={{
                  width: "100%",
                  height: isSmallScreen ? "72px" : "100px",
                  borderRadius: "20px",
                  border: "1px solid rgba(138, 147, 160, 0.50)",
                  background: "#3C3F48",
                  boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset,",
                  px: isSmallScreen ? "15px" : "20px",
                  py: isSmallScreen ? "20px" : "30px",
                }}
              >
                <Box display="flex" height="100%" justifyContent="space-between" alignItems="center">
                  <Box display="flex" height="100%" justifyContent="space-between" alignItems="center">
                    <SvgIcon
                      color="primary"
                      component={topIcon}
                      style={{
                        width: isSmallScreen ? "28px" : "40px",
                        height: isSmallScreen ? "28px" : "40px",
                        marginRight: isSmallScreen ? "8px" : "10px",
                      }}
                      viewBox="0 0 40 40"
                    />
                    <Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleOpenTokenList("in")}
                        sx={{
                          mb: "2px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: isSmallScreen ? "15px" : "21px",
                            fontWeight: "700",
                            color: "#fff",
                          }}
                        >
                          {topToken}
                        </Typography>
                        {/* <SvgIcon
                          color="primary"
                          component={DownIcon}
                          style={{ width: "15px", height: "8px", color: "#fff" }}
                          viewBox="0 0 15 8"
                          className="icon-down"
                        /> */}
                      </Box>
                      {address && (
                        <Box
                          display="flex"
                          // justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#a3a3a3",
                            }}
                          >
                            Balance:
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#a3a3a3",
                            }}
                          >
                            {isAppLoading ? <Skeleton width="100px" /> : trim(Number(topBal), 4)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    style={{ position: "relative" }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-end"
                    height="100%"
                  >
                    <InputBase
                      variant="filled"
                      size="medium"
                      placeholder="0.0"
                      value={amountIn}
                      labelWidth={0}
                      autoComplete={undefined}
                      // onBlur={e => onBlurTop(e.target.value)}
                      // onChange={run}
                      onChange={e => onChangeTop(e.target.value)}
                      onBlur={e => onBlurIpt(e.target.value)}
                      className="swap-input"
                    />
                    {/* <Typography variant="h5">{busd}</Typography> */}
                    {address && (
                      <Button
                        style={{
                          padding: "0 5px",
                          color: "#B50C05",
                          border: "1px solid #B50C05",
                          height: isSmallScreen ? "14px" : "22px",
                          lineHeight: "15px",
                          fontSize: "12px",
                          borderRadius: "6px",
                          minWidth: isSmallScreen ? "28px" : "38px",
                        }}
                        onClick={() => onChangeTop("" + Math.trunc(Number(topBal) * 10000) / 10000)}
                      >
                        Max
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
              {/* exchange icon */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width:'100%',
                  my: isSmallScreen ? "18px" : "25px",
                  cursor: "pointer",
                }}
              >
                <SvgIcon
                  color="primary"
                  component={ExchangeIcon}
                  // component={ExchangeIcon}
                  style={{ width: isSmallScreen ? "28px" : "40px", height: isSmallScreen ? "28px" : "40px" }}
                  viewBox="0 0 40 40"
                  // onMouseEnter={e => setIsHovered(true)}
                  // onMouseLeave={e => setIsHovered(false)}
                  onClick={exchangeToken}
                />
              </Box>

              {/* bottom token */}
              <Box
                sx={{
                  width: "100%",
                  height: isSmallScreen ? "72px" : "100px",
                  borderRadius: "20px",
                  border: "1px solid rgba(138, 147, 160, 0.50)",
                  background: "#3C3F48",
                  boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset,",
                  px: isSmallScreen ? "15px" : "20px",
                  py: isSmallScreen ? "20px" : "30px",
                  mb: "33px",
                }}
              >
                <Box display="flex" height="100%" justifyContent="space-between" alignItems="center">
                  <Box display="flex" height="100%" justifyContent="space-between" alignItems="center">
                    <SvgIcon
                      color="primary"
                      component={bottomIcon}
                      style={{
                        width: isSmallScreen ? "28px" : "40px",
                        height: isSmallScreen ? "28px" : "40px",
                        marginRight: "10px",
                      }}
                      viewBox="0 0 40 40"
                    />
                    <Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ cursor: "pointer" }}
                        sx={{
                          mb: "2px",
                        }}
                        // onClick={() => handleOpenTokenList("out")}
                      >
                        <Typography
                          style={{
                            fontSize: isSmallScreen ? "15px" : "21px",
                            fontWeight: "700",
                            color: "#fff",
                          }}
                        >
                          {bottomToken}
                        </Typography>
                        {/* <SvgIcon
                          color="primary"
                          component={DownIcon}
                          style={{ width: "15px", height: "8px", color: "#fff" }}
                          viewBox="0 0 15 8"
                          className="icon-down"
                        /> */}
                      </Box>
                      {address && (
                        <Box display="flex" alignItems="center">
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#a3a3a3",
                            }}
                          >
                            Balance:
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#a3a3a3",
                            }}
                          >
                            {isAppLoading ? <Skeleton width="100px" /> : trim(Number(bottomBal), 4)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    style={{ position: "relative" }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    alignItems="flex-end"
                    height="100%"
                  >
                    <InputBase
                      hiddenLabel
                      variant="filled"
                      size="medium"
                      placeholder="0.0"
                      value={amountOut}
                      // onChange={e => onChangeBottom(e.target.value)}
                      // onBlur={e => onBlurIpt(e.target.value)}
                      className="swap-input"
                    />
                    {/* {address && (
                          <Button
                            style={{
                              padding: "0 5px",
                              color: "#00A664",
                              border: "1px solid #00A664",
                              height: "15px",
                              lineHeight: "15px",
                              fontSize: "12px",
                            }}
                            onClick={() => onChangeBottom(bottomBal)}
                          >
                            Max
                          </Button>
                        )} */}
                  </Box>
                </Box>
              </Box>
              {/* {price && (
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginBottom: "12px" }}>
                  <Typography variant="body1">Price</Typography>
                  <Box display="flex" alignItems="center">
                    {loading ? (
                      <Skeleton width="100px" />
                    ) : (
                      <Typography variant="body1">{`${price} ${top2Bottom ? topToken : bottomToken} per ${
                        top2Bottom ? bottomToken : topToken
                      }`}</Typography>
                    )}
                    <SvgIcon
                      color="primary"
                      component={TransformIcon}
                      style={{ width: "10px", height: "10px", margin: "0 7px", cursor: "pointer" }}
                      viewBox="0 0 10 10"
                      onClick={() => {
                        setTop2Bottom(!top2Bottom);
                      }}
                    />
                  </Box>
                </Box>
              )} */}
              {isAppLoading ? (
                <Skeleton width="100%" height="46px" />
              ) : !address ? (
                <LoadingButton
                  sx={{
                    width: isSmallScreen ? "100%" : "240px",
                    height: isSmallScreen ? "34px" : "40px",
                    borderRadius: "30px",
                    border: "1.3px solid #B50C05",
                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                    boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                  }}
                  variant="contained"
                  color="primary"
                  className="approve-button"
                  onClick={connect}
                  key={1}
                >
                  <Trans>Connect Wallet</Trans>
                </LoadingButton>
              ) : // ) : Number(hasAllowance(topToken)) ? (
              Number(hasAllowance(topToken)) >= Number(amountIn) ? (
                <LoadingButton
                  sx={{
                    width: isSmallScreen ? "100%" : "240px",
                    height: isSmallScreen ? "34px" : "40px",
                    borderRadius: "30px",
                    border: "1.3px solid #B50C05",
                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                    boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                  }}
                  onClick={exchangeTokenForToken}
                  className="approve-button"
                  variant="contained"
                  color="primary"
                  loading={isPendingTxn(pendingTransactions, "Swap_TOKEN")}
                  disabled={
                    isPendingTxn(pendingTransactions, "Swap_TOKEN") ||
                    !amountIn ||
                    !amountOut ||
                    Number(amountIn) > Number(topBal)
                  }
                >
                  {!amountIn && !amountOut
                    ? "Enter an amount"
                    : Number(amountIn) > Number(topBal)
                    ? `Insufficient ${topToken} balance`
                    : txnButtonText(pendingTransactions, "Swap_TOKEN", `Swap`)}
                </LoadingButton>
              ) : (
                // )
                <LoadingButton
                  sx={{
                    width: isSmallScreen ? "100%" : "240px",
                    height: isSmallScreen ? "34px" : "40px",
                    borderRadius: "30px",
                    border: "1.3px solid #B50C05",
                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                    boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                  }}
                  onClick={approveForSwap}
                  className="approve-button"
                  variant="contained"
                  color="primary"
                  loading={isPendingTxn(pendingTransactions, "approve_swap")}
                  disabled={isPendingTxn(pendingTransactions, "approve_swap")}
                >
                  {txnButtonText(pendingTransactions, "approve_swap", `Approve`)}
                </LoadingButton>
              )}
              <Box
                sx={{
                  pt: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: isSmallScreen ? "12px" : "16px",
                  }}
                >
                  <Trans>$12</Trans>
                </Typography>
              </Box>
              {/* Slippage tolerance */}
              {/* <Box>
                      <Typography variant="h6">Slippage tolerance: {slippage}%</Typography>
                      <Typography variant="h6">Transaction deadline: {deadline} Minutes</Typography>
                      {!!amountOutMin && (
                        <Typography variant="h6">
                          Minimum received: {trim(amountOutMin, 4)} {bottomToken}
                        </Typography>
                      )}
                    </Box> */}
              {/* </Paper> */}
              {/* {!!amountOutMin && ( */}
              {/* {price && !!amountOutMin && fee && (
                <Paper className={`ido-card swap-tip`}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6">Minimum received</Typography>
                            <InfoTooltip
                              message={`Your transaction will revert if there is a large, unfavorable price movement before it is confirmed`}
                            />
                          </Box>
                          <Typography variant="h6">
                            {trim(amountOutMin, 6)} {bottomToken}
                          </Typography>
                        </Box>

                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6">Liquidity Provider Fee </Typography>
                            <InfoTooltip message={`For each trade a 0.25% fee is paid`} />
                          </Box>
                          <Typography variant="h6">
                            {trim(fee, 2)} {topToken}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              )} */}
            </Box>

            {/* <AdvancedSettings
            open={open}
            handleClose={handleClose}
            slippage={slippage}
            deadline={deadline}
            onDeadlineChange={onDeadlineChange}
            onDeadlineBlur={onDeadlineBlur}
            onSlippageChange={onSlippageChange}
            onSlippageBlur={onSlippageBlur}
            theme={theme}
            isBtn={isBtn}
          /> */}
            <TokenList
              open={tokenListOpen}
              handleClose={handleCloseTokenList}
              selectToken={selectToken}
              inOrOut={inOrOut}
              topToken={topToken}
              bottomToken={bottomToken}
            />
          </Box>
        </Paper>
      </Zoom>
    </Box>
  );
}

export default Swap;
