import React, { useState, ReactElement, useContext, useEffect, useMemo, useCallback } from "react";
import Web3Modal from "web3modal";
import { StaticJsonRpcProvider, JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { IFrameEthereumProvider } from "@ledgerhq/iframe-provider";
import { EnvHelper } from "../helpers/Environment";
import { NodeHelper } from "src/helpers/NodeHelper";
import { useAccount, useChainId, useWalletClient, usePublicClient, useSignMessage } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
// import { useDispatch } from "react-redux";
// import { error } from "../slices/MessagesSlice";
import { useEthersSigner } from "./wagmi";
import { WEB3_SIGN_MESSAGE } from "src/auth/auth-web3";

export const DEFAULT_PROVIDER = new StaticJsonRpcProvider(process.env.REACT_APP_RPC_URL);



export const useWeb3Context = () => {
    const chainID = useChainId();
    // const provider = usePublicClient();
    const { address, isConnected: connected, connector }: any = useAccount();
    // https://bsc-testnet.nodereal.io/v1/d4361c5900564fbea8fde35a8e050f6d
    // https://data-seed-prebsc-2-s1.bnbchain.org:8545
    //
    const signer = useEthersSigner();
    // const { connect } = useConnect();
    const [provider, setProvider] = useState<any>(DEFAULT_PROVIDER);
    // console.error('[debug]checkcheck', new Error('debug error'));
    useEffect(() => {
        // if (connected) {
        const _provider = connected ? signer : DEFAULT_PROVIDER;
        setProvider(_provider);
    }, [connected, address]);
    const { openConnectModal: connect } = useConnectModal();
    const { signMessageAsync } = useSignMessage();

    const signMessage = useCallback(
        () => {
            return signMessageAsync({ message: WEB3_SIGN_MESSAGE });
        },
        []
    )
    return {
        address,
        chainID: connected ? chainID : Number(process.env.REACT_APP_CHAIN_ID),
        provider: signer || provider,
        connected,
        connect,
        signMessage
    };
};

export const useAddress = () => {
    const { address } = useWeb3Context();
    return address;
};
