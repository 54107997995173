import { useCallback, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Social from "./Social";
import externalUrls from "./externalUrls";
import { ReactComponent as IDOIcon } from "../../assets/icons/ido.svg";
import { ReactComponent as SwapIcon } from "../../assets/icons/swapIcon.svg";
import { ReactComponent as StakeIcon } from "../../assets/icons/stake.svg";
import { ReactComponent as BondIcon } from "../../assets/icons/bond.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as OlympusIcon } from "../../assets/icons/olympus-nav-header.svg";
import { ReactComponent as PoolTogetherIcon } from "../../assets/icons/33-together.svg";
import { Trans, t } from "@lingui/macro";
import { trim, shorten } from "../../helpers";
// import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import useBonds from "../../hooks/Bonds";
import { Paper, Link, Box, Typography, SvgIcon } from "@mui/material";
import { Skeleton } from "@mui/material";
import "./sidebar.scss";
import { useIsPath, useMobile, useWeb3Context } from "../../hooks";
import { useChainId } from "wagmi";
import MenuDrop from "../TopBar/MenuDrop";
import { addresses, TOKEN_DECIMALS } from "src/constants";



const NoDropMenu = () => {
  const [currentTitle, setCurrentTitle] = useState("Origin");
  const { chainID } = useWeb3Context();
  const isHome = useIsPath("/home");
  const isDashboard = useIsPath("/dashboard");
  const isBond = useIsPath("/bonds");
  const isStake = useIsPath("/stake");
  const isWebKey = useIsPath("/webkey");
  const isReward = useIsPath("/reward");
  const isCommunity = useIsPath("/community");
  const isInvite = useIsPath("/invite");
  const isTurbine = useIsPath("/turbine");
  const isMint = useIsPath("/mint");
  // const isSwap = useIsPath("/swap");

  useEffect(() => {
    setCurrentTitle(
      isHome
        ? t`Home`
        : isDashboard
          ? t`Dashboard`
          : isBond
            ? t`Bond`
            : isStake
              ? t`Stake`
              : isReward
                ? t`Reward Pool`
                : isCommunity
                  ? t`Community`
                  : isInvite
                    ? t`Invite`
                    : isTurbine
                      ? t`Turbine`
                      : isMint
                        ? t`Mint`
                        : "Origin",
    );
  }, [
    isHome,
    isDashboard,
    isBond,
    isStake,
    isReward,
    isCommunity,
    isInvite,
    isTurbine,
    isMint,
    isWebKey,
    // isSwap,
    setCurrentTitle,
  ]);
  return (
    <Box
      component="div"
      sx={{
        alignItems: 'center',
        borderRadius: "8px",
        background: "linear-gradient(138deg, #32373A 2.23%, #131416 97.6%)",
        boxShadow: "0px 0px 4px 0px #33383C inset",
      }}
    >

      <Link href="/#/home" onClick={() => setCurrentTitle("Home")}>
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isHome ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
          <Typography
            sx={{
              color: isHome ? "#B50C05" : "#c7c8cc",
              textShadow: "0px 1px 0px #000",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "16px", lineHeight:"27px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            <Trans>Home</Trans>
          </Typography>
        </Box>
      </Link>


      <Link href="/#/dashboard" onClick={() => setCurrentTitle("Dashboard")}>
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isDashboard ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
          <Typography
            sx={{
              color: isDashboard ? "#B50C05" : "#c7c8cc",
              textShadow: "0px 1px 0px #000",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "16px", lineHeight:"27px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            <Trans>Dashboard</Trans>
          </Typography>
        </Box>
      </Link>

      <Link href="/#/webkey" onClick={() => setCurrentTitle("Stake")}>
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isWebKey ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
          <Typography
            sx={{
              color: isWebKey ? "#B50C05" : "#c7c8cc",
              textShadow: "0px 1px 0px #000",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "16px", lineHeight:"27px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            <Trans>WebKey</Trans>
          </Typography>
        </Box>
      </Link>

      <Link href="/#/stake" onClick={() => setCurrentTitle("Stake")}>
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isStake ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
          <Typography
            sx={{
              color: isStake ? "#B50C05" : "#c7c8cc",
              textShadow: "0px 1px 0px #000",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "16px", lineHeight:"27px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            <Trans>Stake</Trans>
          </Typography>
        </Box>
      </Link>


      <Link href="/#/bonds" onClick={() => setCurrentTitle("Bond")}>
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isBond ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
          <Typography
            sx={{
              color: isBond ? "#B50C05" : "#c7c8cc",
              textShadow: "0px 1px 0px #000",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "16px", lineHeight:"27px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            <Trans>Bond</Trans>
          </Typography>
        </Box>
      </Link>


      <Link href="/#/reward" onClick={() => setCurrentTitle("Reward")}>
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isReward ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
          <Typography
            sx={{
              color: isReward ? "#B50C05" : "#c7c8cc",
              textShadow: "0px 1px 0px #000",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "16px", lineHeight:"27px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            <Trans>Reward Pool</Trans>
          </Typography>
        </Box>
      </Link>


      <Link href="/#/community" onClick={() => setCurrentTitle("Community")}>
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isCommunity ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
          <Typography
            sx={{
              color: isCommunity ? "#B50C05" : "#c7c8cc",
              textShadow: "0px 1px 0px #000",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "16px", lineHeight:"27px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            <Trans>Community</Trans>
          </Typography>
        </Box>
      </Link>


      <Link href="/#/invite" onClick={() => setCurrentTitle("Invite")}>
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isInvite ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
          <Typography
            sx={{
              color: isInvite ? "#B50C05" : "#c7c8cc",
              textShadow: "0px 1px 0px #000",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "16px", lineHeight:"27px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            <Trans>Invite</Trans>
          </Typography>
        </Box>
      </Link>


      <Link href="/#/turbine" onClick={() => setCurrentTitle("Turbine")}>
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isTurbine ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
          <Typography
            sx={{
              color: isTurbine ? "#B50C05" : "#c7c8cc",
              textShadow: "0px 1px 0px #000",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "16px", lineHeight:"27px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            <Trans>Turbine</Trans>
          </Typography>
        </Box>
      </Link>


      <Link href="/#/mint" onClick={() => setCurrentTitle("Mint")}>
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1, ...(isMint ? { border: "1px solid #E93F25", borderRadius: 15 } : {}) }}>
          <Typography
            sx={{
              color: isMint ? "#B50C05" : "#c7c8cc",
              textShadow: "0px 1px 0px #000",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "16px", lineHeight:"27px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            <Trans>Mint</Trans>
          </Typography>
        </Box>
      </Link>


      <Link
        href={`https://quickswap.exchange/#/swap?chain=mainnet&inputCurrency=${addresses[chainID].USDT_ADDRESS}&outputCurrency=${addresses[chainID].OHM_ADDRESS}`}
        target="_blank"
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 1.675, py: 1 }}>
          {/* <Link href="/#/swap" onClick={() => setCurrentTitle("Swap")}> */}
          <Typography
            sx={{
              color: "#c7c8cc",
              textShadow: "0px 1px 0px #000",
              fontFamily: "Neue Haas Grotesk Text Pro",
              fontSize: "16px", lineHeight:"27px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            <Trans>Swap</Trans>
          </Typography>
        </Box>
      </Link>

    </Box>
  )
}


function NavContent({ mobileOpen, handleDrawerToggle }) {
  const { isSmallScreen } = useMobile();
  const [isActive] = useState();
  // const { chainID } = useWeb3Context();
  const chainID = useChainId();
  const { bonds } = useBonds(chainID);
  const isHome = useIsPath("/home");
  const isDashboard = useIsPath("/dashboard");
  const isWebKey = useIsPath("/webkey");
  const isBond = useIsPath("/bonds");
  const isStake = useIsPath("/stake");
  const isReward = useIsPath("/reward");
  const isCommunity = useIsPath("/community");
  const isInvite = useIsPath("/invite");
  const isTurbine = useIsPath("/turbine");
  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");
    if (currentPath.indexOf("dashboard") >= 0) {
      return true;
    }
    if (currentPath.indexOf("stake") >= 0) {
      return true;
    }
    if (currentPath.indexOf("ido") >= 0) {
      return true;
    }
    if (currentPath.indexOf("swap") >= 0) {
      return true;
    }
    if (currentPath.indexOf("home") >= 0) {
      return true;
    }
    if (currentPath.indexOf("unlock") >= 0) {
      return true;
    }
    if (currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) {
      return true;
    }
    if (currentPath.indexOf("33-together") >= 0) {
      return true;
    }
    return false;
  }, []);
  let history = useHistory();
  const goPancakeSwap = () => {
    // window.open(
    //   `https://quickswap.exchange/#/swap?chain=mainnet&inputCurrency=${addresses[chainID].USDT_ADDRESS}&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
    //   "_blank",
    // );
    // window.open(`https://quickswap.exchange/#/swap`, "_blank");
    // window.open(
    //   `https://pancakeswap.finance/swap?chain=${chainID === 56 ? "bsc" : "bscTestnet"}&inputCurrency=${
    //     addresses[chainID].USDT_ADDRESS
    //   }&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
    //   "_blank",
    // );
    history.push("/swap");
    // window.open('/swap','_blank')
  };

  const goV1Version = () => {
    window.open(`https://v1.origindefi.io`, "_blank");
  };

  return (
    <Paper className="dapp-sidebar linear-card">
      <Box
        className="dapp-sidebar-inner"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        sx={{
          width: "100%",
          height: "100%",
          background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
        }}
      >
        <div className="dapp-menu-top">
          <Box className="branding-header">
            <Link href="https://origindao.io/" target="_blank">
              <SvgIcon
                color="primary"
                component={OlympusIcon}
                viewBox="0 0 81 88"
                style={{ minWidth: "81px", minHeight: "88px", width: "81px" }}
              />
            </Link>

            {/* {address && (
              <div className="wallet-link">
                <Link href={`https://bscscan.io/address/${address}`} target="_blank">
                  {shorten(address)}
                </Link>
              </div>
            )} */}
          </Box>

          <Box alignItems="flex-start" className="dapp-menu-links">
            <Box className="dapp-nav" id="navbarNav" sx={{ width: "100%", px: 1.25 }}>
              <Box sx={{ height: "44px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <Box
                  sx={{
                    px: 1,
                    py: 0.25,
                    ml: 1.25,
                    borderRadius: "14px",
                    border: "1px solid #E93F25",
                    height: "30px",
                    width: isSmallScreen ? "80px" : "44px",
                  }}
                  onClick={goPancakeSwap}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    sx={{
                      background: "linear-gradient(287deg, #E93F25 4.37%, #FF7F43 94.15%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontFamily: "Neue Haas Grotesk Text Pro",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    <Trans>Buy</Trans>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    px: 1,
                    py: 0.25,
                    ml: 1.25,
                    borderRadius: "14px",
                    border: "1px solid #E93F25",
                    height: "30px",
                    width: isSmallScreen ? "80px" : "44px",
                  }}
                  onClick={goV1Version}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    sx={{
                      background: "linear-gradient(287deg, #E93F25 4.37%, #FF7F43 94.15%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontFamily: "Neue Haas Grotesk Text Pro",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    <Trans>V1</Trans>
                  </Typography>
                </Box>
              </Box>
              <NoDropMenu />
              {/* <MenuDrop mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} /> */}
              {/* <Box sx={{ px: 1.675, py: 1, height: "44px", mb: 1 }}>
                <Link href="https://lend.originworld.org/" target="_blank" rel="noopener">
                  <Typography
                    sx={{
                      color: "#c7c8cc",
                      textShadow: "0px 1px 0px #000",
                      fontFamily: "Neue Haas Grotesk Text Pro",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    <Trans>Lending</Trans>
                  </Typography>
                </Link>
              </Box>
              <Box sx={{ px: 1.675, py: 1, height: "44px", mb: 1 }}>
                <Link href="https://swap.originworld.org/#/" target="_blank" rel="noopener">
                  <Typography
                    sx={{
                      color: "#c7c8cc",
                      textShadow: "0px 1px 0px #000",
                      fontFamily: "Neue Haas Grotesk Text Pro",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    <Trans>Bridge</Trans>
                  </Typography>
                </Link>
              </Box>
              <Box sx={{ px: 1.675, py: 1, height: "44px", mb: 1 }}>
                <Link href="" target="_blank" rel="noopener">
                  <Typography
                    sx={{
                      color: "#c7c8cc",
                      textShadow: "0px 1px 0px #000",
                      fontFamily: "Neue Haas Grotesk Text Pro",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      textTransform: "uppercase",
                    }}
                  >
                    <Trans>Dex</Trans>
                  </Typography>
                </Link>
              </Box> */}

              {/* <Link
                component={NavLink}
                id="dash-nav"
                to="/home"
                isActive={(match, location) => {
                  return checkPage(match, location, "home");
                }}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  height: "44px",
                  borderRadius: isHome ? "8px" : "0",
                  border: isHome ? "1px solid #303437" : "none",
                  px: 1.25,
                  mb: 1,
                  background: isHome ? "linear-gradient(180deg, #131418 0%, #242528 100%)" : "none",
                }}
                className={`button-dapp-menu ${isHome ? "active" : ""} `}
              >
                <Typography
                  sx={{
                    color: isHome ? "#B50C05" : "#C7C8CC",
                    textShadow: "0px 1px 0px #000",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                  }}
                >
                  <Trans>Home</Trans>
                </Typography>
              </Link> */}
              {/* <Link
                component={NavLink}
                id="dash-nav"
                to="/dashboard"
                isActive={(match, location) => {
                  return checkPage(match, location, "dashboard");
                }}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  height: "44px",
                  borderRadius: isDashboard ? "8px" : "0",
                  border: isDashboard ? "1px solid #303437" : "none",
                  px: 1.25,
                  mb: 1,

                  background: isDashboard ? "linear-gradient(180deg, #131418 0%, #242528 100%)" : "none",
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""} `}
              >
                <Typography
                  sx={{
                    color: isDashboard ? "#B50C05" : "#C7C8CC",
                    textShadow: "0px 1px 0px #000",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                  }}
                >
                  <Trans>Dashboard</Trans>
                </Typography>
              </Link>

              <Link
                component={NavLink}
                id="stake-nav"
                to="/stake"
                isActive={(match, location) => {
                  return checkPage(match, location, "stake");
                }}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  height: "44px",
                  borderRadius: isStake ? "8px" : "0",
                  border: isStake ? "1px solid #303437" : "none",
                  px: 1.25,
                  mb: 1,

                  background: isStake ? "linear-gradient(180deg, #131418 0%, #242528 100%)" : "none",
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography
                  sx={{
                    color: isStake ? "#B50C05" : "#C7C8CC",
                    textShadow: "0px 1px 0px #000",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                  }}
                >
                  <Trans>Stake</Trans>
                </Typography>
              </Link>

              <Link
                component={NavLink}
                id="bond-nav"
                to="/bonds"
                isActive={(match, location) => {
                  return checkPage(match, location, "bonds");
                }}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  height: "44px",
                  borderRadius: isBond ? "8px" : "0",
                  border: isBond ? "1px solid #303437" : "none",
                  px: 1.25,
                  mb: 1,

                  background: isBond ? "linear-gradient(180deg, #131418 0%, #242528 100%)" : "none",
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography
                  sx={{
                    color: isBond ? "#B50C05" : "#C7C8CC",
                    textShadow: "0px 1px 0px #000",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                  }}
                >
                  <Trans>Bond</Trans>
                </Typography>
              </Link>

              <Link
                component={NavLink}
                id="bond-nav"
                to="/reward"
                isActive={(match, location) => {
                  return checkPage(match, location, "bonds");
                }}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  height: "44px",
                  borderRadius: isReward ? "8px" : "0",
                  border: isReward ? "1px solid #303437" : "none",
                  px: 1.25,
                  mb: 1,

                  background: isReward ? "linear-gradient(180deg, #131418 0%, #242528 100%)" : "none",
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography
                  sx={{
                    color: isReward ? "#B50C05" : "#C7C8CC",
                    textShadow: "0px 1px 0px #000",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                  }}
                >
                  <Trans>Reward</Trans>
                </Typography>
              </Link>

              <Link
                component={NavLink}
                id="bond-nav"
                to="/community"
                isActive={(match, location) => {
                  return checkPage(match, location, "bonds");
                }}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  height: "44px",
                  borderRadius: isCommunity ? "8px" : "0",
                  border: isCommunity ? "1px solid #303437" : "none",
                  px: 1.25,
                  mb: 1,

                  background: isCommunity ? "linear-gradient(180deg, #131418 0%, #242528 100%)" : "none",
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography
                  sx={{
                    color: isCommunity ? "#B50C05" : "#C7C8CC",
                    textShadow: "0px 1px 0px #000",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                  }}
                >
                  <Trans>Community</Trans>
                </Typography>
              </Link>

              <Link
                component={NavLink}
                id="bond-nav"
                to="/invite"
                isActive={(match, location) => {
                  return checkPage(match, location, "bonds");
                }}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  height: "44px",
                  borderRadius: isInvite ? "8px" : "0",
                  border: isInvite ? "1px solid #303437" : "none",
                  px: 1.25,
                  mb: 1,

                  background: isInvite ? "linear-gradient(180deg, #131418 0%, #242528 100%)" : "none",
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography
                  sx={{
                    color: isInvite ? "#B50C05" : "#C7C8CC",
                    textShadow: "0px 1px 0px #000",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                  }}
                >
                  <Trans>Invite</Trans>
                </Typography>
              </Link>

              <Link
                component={NavLink}
                id="bond-nav"
                to="/turbine"
                isActive={(match, location) => {
                  return checkPage(match, location, "bonds");
                }}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  height: "44px",
                  borderRadius: isTurbine ? "8px" : "0",
                  border: isTurbine ? "1px solid #303437" : "none",
                  px: 1.25,
                  mb: 1,

                  background: isTurbine ? "linear-gradient(180deg, #131418 0%, #242528 100%)" : "none",
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography
                  sx={{
                    color: isTurbine ? "#B50C05" : "#C7C8CC",
                    textShadow: "0px 1px 0px #000",
                    fontFamily: "Neue Haas Grotesk Text Pro",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                  }}
                >
                  <Trans>Turbine</Trans>
                </Typography>
              </Link> */}

              {/* <Box sx={{ height: "44px" }} display={"flex"} alignItems="center">
                
              </Box> */}

              {/* <div className="dapp-menu-data discounts">
                <div className="bond-discounts">
                  <Typography variant="body2">
                    <Trans>Bond discounts</Trans>
                  </Typography>
                  {bonds.map((bond, i) => (
                    <Link component={NavLink} to={`/bonds/${bond.name}`} key={i} className={"bond"}>
                      {!bond.bondDiscount ? (
                        <Skeleton variant="text" width={"150px"} />
                      ) : (
                        <Typography variant="body2">
                          {bond.displayName}

                          <span className="bond-pair-roi">
                            {!bond.isAvailable[chainID]
                              ? "Sold Out"
                              : `${bond.bondDiscount && trim(bond.bondDiscount * 100, 2)}%`}
                            {bond.bondDiscount && trim(bond.bondDiscount * 100, 2)}%
                          </span>
                        </Typography>
                      )}
                    </Link>
                  ))}
                </div>
              </div> */}

              {/* <Link
                component={NavLink}
                id="ido-nav"
                to="/swap"
                isActive={(match, location) => {
                  return checkPage(match, location, "swap");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""} ido`}
              >
                <Typography variant="h6">
                  <SvgIcon color="primary" component={SwapIcon} />
                  <Trans>Swap</Trans>
                </Typography>
              </Link> */}

              {/* <Link
                component={NavLink}
                id="ido-nav"
                to="/ido"
                isActive={(match, location) => {
                  return checkPage(match, location, "ido");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""} ido`}
              >
                <Typography variant="h6">
                  <SvgIcon color="primary" component={IDOIcon} />
                  <Trans>IDO Exchange</Trans>
                </Typography>
              </Link> */}
            </Box>
          </Box>
        </div>
        {/* <Box className="dapp-menu-bottom" display="flex" justifyContent="space-between" flexDirection="column">
          <div className="dapp-menu-external-links">
            {Object.keys(externalUrls).map((link, i) => {
              return (
                <Link key={i} href={`${externalUrls[link].url}`} target="_blank">
                  <Typography variant="h6">{externalUrls[link].icon}</Typography>
                  <Typography variant="h6">{externalUrls[link].title}</Typography>
                </Link>
              );
            })}
          </div>
          <div className="dapp-menu-social">
            <Social />
          </div>
        </Box> */}
      </Box>
    </Paper>
  );
}

export default NavContent;
