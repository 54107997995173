/* eslint-disable global-require */
import { FC, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// import { Web3ContextProvider } from "./hooks/web3Context";
import { wagmiClient } from "src/hooks/wagmi";
import { WagmiConfig } from "wagmi";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { initLocale } from "./locales";
// import { connectorsForWallets, lightTheme, getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
// import "@rainbow-me/rainbowkit/styles.css";
// import { configureChains, createConfig, WagmiConfig } from "wagmi";
// import { alchemyProvider } from "wagmi/providers/alchemy";
// import { publicProvider } from "wagmi/providers/public";
// import { goerli, bsc, mainnet, localhost, polygonMumbai } from "wagmi/chains";
import App from "./App.jsx";
import store from "./store";
// const ALCHEMY_API_KEY = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY || "";

// const { chains, publicClient } = configureChains(
//   [bsc, polygonMumbai],
//   [
//     alchemyProvider({
//       apiKey: ALCHEMY_API_KEY,
//     }),
//     publicProvider(),
//   ],
// );

// const { wallets } = getDefaultWallets({
//   appName: "Origin",
//   chains,
//   projectId: "fb1a11cef57409e7a6240f4dd42671cb",
// });

// const demoAppInfo = {
//   appName: "Orange Dex",
// };

// const connectors = connectorsForWallets(wallets);

// const wagmiConfig = createConfig({
//   // autoConnect: true,
//   connectors,
//   publicClient,
// });
const Root: FC = () => {
  useEffect(() => {
    initLocale();
  }, []);

  return (
    // <Web3ContextProvider>
    //   <Provider store={store}>
    //     <I18nProvider i18n={i18n}>
    //       <BrowserRouter basename={"/#"}>
    //         <App />
    //       </BrowserRouter>
    //     </I18nProvider>
    //   </Provider>
    // </Web3ContextProvider>
    <WagmiConfig config={wagmiClient}>
      {/* <Web3ContextProvider> */}
      <Provider store={store}>
        <I18nProvider i18n={i18n}>
          <BrowserRouter basename={"/#"}>
            <App />
          </BrowserRouter>
        </I18nProvider>
      </Provider>
      {/* </Web3ContextProvider> */}
    </WagmiConfig>
  );
};

export default Root;
