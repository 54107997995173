import { Box, IconButton, SvgIcon } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import TurbineSettings from "./TurbineSettings.jsx";

const TurbineHeader = ({ slippage, handleSlippageChange, onSlippageBlur, isBtn, onDeadlineChange, deadline }: any) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ width: "100%", px: 3.125, pt: 3.125 }} display="flex" justifyContent={"flex-end"}>
      <IconButton onClick={handleOpen} sx={{ p: 0 }}>
        <SvgIcon sx={{ color: "#C7C8CC" }} component={SettingsIcon} />
      </IconButton>
      <TurbineSettings
        open={open}
        handleClose={handleClose}
        slippage={slippage}
        onSlippageChange={handleSlippageChange}
        onSlippageBlur={onSlippageBlur}
        deadline={deadline}
        onDeadlineChange={onDeadlineChange}
        isBtn={isBtn}
      />
    </Box>
  );
};

export default TurbineHeader;
