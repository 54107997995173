import { ChangeEvent, Fragment, ReactNode, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { formatCurrency, trim } from "../../helpers";
import { Backdrop, Box, Fade, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import TabPanel from "../../components/TabPanel";
import BondHeader from "./BondHeader";
import BondRedeem from "./BondRedeem";
import BondPurchase from "./BondPurchase";
import "./bond.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { Skeleton } from "@mui/material";
import { useAppSelector } from "src/hooks";
import { IAllBondData } from "src/hooks/Bonds";
import { NetworkID } from "src/lib/Bond";
import { useMobile } from "src/hooks";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Bond = ({ theme, bond }) => {
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();
  const { isSmallScreen } = useMobile();

  const [slippage, setSlippage] = useState(localStorage.getItem("bondSlippage") || 0.1);
  const [recipientAddress, setRecipientAddress] = useState(address);
  const [isBtn, setIsBtn] = useState(localStorage.getItem("isBtnBond") || "btn");

  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState();

  const isBondLoading = useAppSelector(state => state.bonding.loading ?? true);
  const marketPrice = useAppSelector(state => {
    return state.app.marketPrice;
  });
  const onRecipientAddressChange = e => {
    if (!e.target.value) setRecipientAddress(address);
    return setRecipientAddress(e.target.value);
  };

  const onSlippageChange = (value, isBtn) => {
    if (isBtn == "ipt") {
      if (value == "0.1" || value == "0.5" || value == "1.0") {
        setIsBtn("btn");
        setSlippage(value);
        localStorage.setItem("bondSlippage", value);
        localStorage.setItem("isBtnBond", "btn");
        return;
      } else {
        localStorage.setItem("isBtnBond", isBtn);
        localStorage.setItem("bondSlippage", value);

        setIsBtn(isBtn);
        return setSlippage(value);
      }
    } else {
      localStorage.setItem("isBtnBond", isBtn);
      localStorage.setItem("bondSlippage", value);

      setIsBtn(isBtn);
      return setSlippage(value);
    }
  };

  const onSlippageBlur = value => {
    if (value <= 0) {
      setIsBtn("btn");
      setSlippage("0.1");
      localStorage.setItem("slippage", "0.1");
      localStorage.setItem("isBtn", "btn");
    }
  };

  useEffect(() => {
    if (address) setRecipientAddress(address);
  }, [provider, quantity, address]);

  const changeView = (event, value) => {
    setView(Number(value));
  };

  return (
    <Fade in={true} mountOnEnter unmountOnExit>
      <Grid container id="bond-view">
        <Backdrop
          open={true}
          sx={{
            px: isSmallScreen ? 1.875 : 0,
          }}
        >
          <Fade in={true}>
            <Paper
              className="ohm-card ohm-modal"
              sx={{
                borderRadius: isSmallScreen ? "12px" : "24px",
                border: isSmallScreen ? "1px solid #434952" : "2px solid #434952",
                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                px: isSmallScreen ? 2.5 : 3.75,
                py: 2.5,
              }}
            >
              <BondHeader
                bond={bond}
                slippage={slippage}
                recipientAddress={recipientAddress}
                onSlippageChange={onSlippageChange}
                onSlippageBlur={onSlippageBlur}
                onRecipientAddressChange={onRecipientAddressChange}
                theme={theme}
                isBtn={isBtn}
              />

              <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} className="bond-price-data-row">
                <Box className="bond-price-data" sx={{ mb: isSmallScreen ? 2.5 : 0 }}>
                  <Typography
                    sx={{
                      color: "#8C8C99",
                      fontFamily: "AGaramond LT",
                      fontSize: isSmallScreen ? "14px" : "20px",
                      fontStyle: "normal",
                      fontWeight: "400",
                    }}
                  >
                    <Trans>Bond Price</Trans>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#C7C8CC",
                      fontFamily: "AGaramond LT",
                      fontSize: isSmallScreen ? "21px" : "27px",
                      fontStyle: "normal",
                      fontWeight: "700",
                    }}
                    className="price"
                  >
                    {/* <> */}
                    {isBondLoading ? <Skeleton /> : !bond.bondPrice ? "--" : formatCurrency(bond.bondPrice, 4)}
                    {/* </> */}
                    {/* <>{isBondLoading ? <Skeleton width="50px" /> : <DisplayBondPrice key={bond.name} bond={bond} />}</> */}
                  </Typography>
                </Box>
                <Box className="bond-price-data">
                  <Typography
                    sx={{
                      color: "#8C8C99",
                      fontFamily: "AGaramond LT",
                      fontSize: isSmallScreen ? "14px" : "20px",
                      fontStyle: "normal",
                      fontWeight: "400",
                    }}
                  >
                    <Trans>Market Price</Trans>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#C7C8CC",
                      fontFamily: "AGaramond LT",
                      fontSize: isSmallScreen ? "21px" : "27px",
                      fontStyle: "normal",
                      fontWeight: "700",
                    }}
                    className="price"
                  >
                    {isBondLoading ? <Skeleton /> : !marketPrice ? "--" : formatCurrency(marketPrice, 4)}
                    {/* {isBondLoading ? <Skeleton /> : !bond.marketPrice ? "--" : formatCurrency(bond.marketPrice, 2)} */}
                  </Typography>
                </Box>
              </Box>

              {/* <Tabs
                centered
                value={view}
                textColor="primary"
                indicatorColor="primary"
                onChange={changeView}
                aria-label="bond tabs"
                TabIndicatorProps={{ style: { backgroundColor: "#B50C05" } }}
              >
                <Tab
                  aria-label="bond-tab-button"
                  label={t({
                    id: "do_bond",
                    comment: "The action of bonding (verb)",
                  })}
                  {...a11yProps(0)}
                  sx={{
                    color: "#C7C8CC",

                    fontFamily: "AGaramond LT",
                    fontSize: "20px",
                    fontWeight: "700",
                    '&[aria-selected="true"]': {
                      color: "#B50C05",
                    },
                  }}
                />
                <Tab
                  aria-label="redeem-tab-button"
                  label={t`Redeem`}
                  {...a11yProps(1)}
                  sx={{
                    color: "#C7C8CC",

                    fontFamily: "AGaramond LT",
                    fontSize: "20px",
                    fontWeight: "700",
                    '&[aria-selected="true"]': {
                      color: "#B50C05",
                    },
                  }}
                />
              </Tabs> */}

              {/* <TabPanel value={view} index={0}> */}
              <BondPurchase bond={bond} slippage={slippage} recipientAddress={recipientAddress} theme={theme} />
              {/* </TabPanel> */}

              {/* <TabPanel value={view} index={1}>
                <BondRedeem bond={bond} />
              </TabPanel> */}
            </Paper>
          </Fade>
        </Backdrop>
      </Grid>
    </Fade>
  );
};

export const DisplayBondPrice = ({ bond }) => {
  const { chainID } = useWeb3Context();

  if (typeof bond.bondPrice === undefined || !bond.isAvailable[chainID] || !bond.bondPrice) {
    return <Fragment>--</Fragment>;
  }

  return (
    <Fragment>
      {new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 4,
        minimumFractionDigits: 4,
      }).format(bond.bondPrice)}
    </Fragment>
  );
};

export const DisplayBondDiscount = ({ bond }) => {
  const { chainID } = useWeb3Context();

  if (typeof bond.bondDiscount === undefined || !bond.isAvailable[chainID]) {
    return <Fragment>--</Fragment>;
  }

  return <Fragment>{bond.bondDiscount && trim(bond.bondDiscount * 100, 2)}%</Fragment>;
};
export default Bond;
