import { t, Trans } from "@lingui/macro";
import {
    Box,
    Zoom,
    Paper,
    Typography,
    OutlinedInput,
    InputAdornment,
    Button,
    Grid,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Popper,
    SvgIcon,
    Fade,
    Skeleton,
    CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import NoData from "src/assets/images/noData.svg";
import dayjs from "dayjs";
import { ReactComponent as ArrowDownIcon } from "src/assets/icons/arrow-down.svg";
import {
    communityJoin,
    communityTurbine,
    getCommunityData,
    approveTHAExchange,
    thaExchange,
    getInviteData,
} from "src/slices/CommunitySlice";
import { getBalances, loadAccountDetails } from "src/slices/AccountSlice";
import { useDispatch } from "react-redux";
import { DEFAULT_PROVIDER, useWeb3Context } from "src/hooks/web3Context";
import { useAppSelector } from "src/hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { success, info } from "src/slices/MessagesSlice";
import { useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useMobile } from "src/hooks";
import InviteBgLeft from "src/assets/icons/inviteBgLeft.svg";
import InviteBgRight from "src/assets/icons/inviteBgRight.svg";
import InviteBgLeftMobile from "src/assets/icons/inviteBgLeftMobile.svg";
import InviteBgRightMobile from "src/assets/icons/inviteBgRightMobile.svg";
import { isContractAddress, shorten, toolNumber, trim } from "src/helpers";
import { DEFAULT_INVITE_ADDRESS } from "src/constants";
import { error } from "../../slices/MessagesSlice";
// import InviteModal from "../../components/InviteModal";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip.jsx";
import { addresses } from "src/constants";
import { PullToRefresh } from "src/components/PullToRefresh";
import { loadAppDetails } from "src/slices/AppSlice";
import { ethers } from "ethers";
import { useAuthContext } from "src/auth/use-auth";
import { SignForm } from "../auth/SignForm";



const MyCommunityData = ({ isSmallScreen, isLoading, rankingListData }: any) => {
    return (
        <Zoom in={true} timeout={1000}>
            <Paper
                className="ohm-card linear-card"
                sx={{
                    mb: 5,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: isSmallScreen ? "12px" : "24px",
                        background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                        // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                    }}
                >
                    <Box display="flex" alignItems="center" sx={{ px: isSmallScreen ? 2.5 : 3.75, height: "66px" }}>
                        <Typography
                            sx={{
                                color: "#C7C8CC",
                                fontFamily: "AGaramond LT",
                                fontSize: "21px",
                                fontStyle: "normal",
                                fontWeight: "700",
                            }}
                        >
                            <Trans>My Web Reward</Trans>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            height: "1px",
                            background: "#0B0C0A",
                            boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                        }}
                    />
                    <Box>
                        <TableContainer sx={{ minHeight: "330px" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                color: "#868B8F",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "14px" : "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                borderBottom: "none",
                                                pl: isSmallScreen ? 5.25 : 7,
                                            }}
                                        >
                                            <Trans>TX</Trans>
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#868B8F",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "14px" : "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                borderBottom: "none",
                                            }}
                                        >
                                            <Trans>Reward</Trans>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                color: "#868B8F",
                                                fontFamily: "AGaramond LT",
                                                fontSize: isSmallScreen ? "14px" : "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                borderBottom: "none",
                                            }}
                                        >
                                            <Trans>Block</Trans>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {<TableBody>
                                    {isLoading ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={3}
                                                sx={{
                                                    height: "300px",
                                                    borderBottom: "none",
                                                }}
                                            >
                                                <Box
                                                    sx={{ width: "100%", height: "100%" }}
                                                    display="flex"
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                    flexDirection={"column"}
                                                >
                                                    <CircularProgress />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) : rankingListData && rankingListData.length > 0 ? (
                                        rankingListData.map((item: any, index: number) => (
                                            <TableRow sx={{ height: "56px" }} key={index}>
                                                <TableCell sx={{ borderBottom: "none", p: 1.25 }}>
                                                    <Box display="flex" alignItems={"center"}>

                                                        <Box sx={{ height: "24px" }}>
                                                            <a href={"https://polygonscan.com/tx/" + item.tx}>
                                                                <Typography
                                                                    sx={{
                                                                        // color: "#C7C8CC",
                                                                        color: "rgba(0, 112, 215, 1)",
                                                                        fontFamily: "AGaramond LT",
                                                                        fontSize: isSmallScreen ? "16px" : "16px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: "400",
                                                                        textDecoration: 'underline'
                                                                    }}
                                                                >
                                                                    <Trans>{shorten(item.tx)}</Trans>
                                                                </Typography>
                                                            </a>

                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: "none", p: 1.25, width: "40%" }} align="center">
                                                    <Typography
                                                        sx={{
                                                            color: "#C7C8CC",
                                                            fontFamily: "AGaramond LT",
                                                            fontSize: isSmallScreen ? "16px" : "16px",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {trim(item.amount, 4)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: "none", p: 1.25 }} align="right">
                                                    <a href={"https://polygonscan.com/block/" + item.block} >
                                                        <Typography
                                                            sx={{
                                                                // color: "#C7C8CC",
                                                                color: "rgba(0, 112, 215, 1)",
                                                                fontFamily: "AGaramond LT",
                                                                fontSize: isSmallScreen ? "16px" : "16px",
                                                                fontStyle: "normal",
                                                                fontWeight: "400",
                                                                textDecoration: 'underline'
                                                            }}
                                                        >
                                                            {/* {dayjs(item.timestamp * 1000).format("YYYY/MM/DD  ")} */}
                                                            {item.block}
                                                        </Typography></a>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={3}
                                                sx={{
                                                    height: "300px",
                                                    borderBottom: "none",
                                                }}
                                            >
                                                <Box
                                                    sx={{ width: "100%", height: "100%" }}
                                                    display="flex"
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                    flexDirection={"column"}
                                                >
                                                    <img src={NoData} alt="No Data" />
                                                    <Typography
                                                        sx={{
                                                            pt: 2,
                                                            color: "#C7C8CC",
                                                            fontFamily: "AGaramond LT",
                                                            fontSize: "12px",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        <Trans>No data</Trans>
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>}
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Paper>
        </Zoom>
    )
}


const Invite = () => {
    const { isSmallScreen } = useMobile();

    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [referralsList2, setReferralsList2] = useState([]);
    const dispatch = useDispatch();
    const { provider, address, connected, connect, chainID } = useWeb3Context();
    const mintReward = useAppSelector(state => state.account.reward.mint);
    const marketPrice = useAppSelector(state => state.app.marketPrice);
    const isInvited = useAppSelector(state => state.community.isInvited);
    const directReferralQuantity = useAppSelector(state => state.community.directReferralQuantity);
    const teamNumber = useAppSelector(state => state.community.teamNumber);
    const totalStakeAmount = useAppSelector(state => state.community.totalStakeAmount);
    const totalLPBond = useAppSelector(state => state.community.totalLPBond);
    const totalStableBond = useAppSelector(state => state.community.totalStableBond);
    const accountLevel = useAppSelector(state => state.community.accountLevel);
    const referralsList = useAppSelector(state => state.community.referralsList);
    // const maxLevel = useAppSelector(state => state.community.maxLevel);
    const [maxLevel, setMaxLevel] = useState(0);
    const referrer = useAppSelector(state => state.community.referrer);
    const needWhitelist = useAppSelector(state => state.community.needWhitelist);
    const stakeMinAmount = useAppSelector(state => state.app.stakeMinAmount);
    const stakeMinGons = useAppSelector(state => state.community.stakeMinGons);
    const usdtBal = useAppSelector(state => state.account.balances.usdt);
    const gonsBal = useAppSelector(state => {
        return state.account.balances && state.account.balances.gonsBal;
    });
    const usdtAllowanceInTHAExchange = useAppSelector(state => state.account.usdtAllowanceInTHAExchange);
    const pendingTransactions = useAppSelector(state => {
        return state.pendingTransactions;
    });
    const isAppLoading = useAppSelector(state => state.app.loading);

    const sohmBalance = useAppSelector(state => {
        return state.account.balances && state.account.balances.sOHM;
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isWebLoading, setIsWebLoading] = useState(false);
    const [cobwebData, setCobwebData] = useState<any>([]);

    const [inviteAddr, setInviteAddr] = useState("");
    const [quantity, setQuantity] = useState<any>();
    const [validNodeAmount, setValidNodeAmount] = useState<number>();
    // const [modalInviteAddr, setModalInviteAddr] = useState<any>();
    // console.log("usdtAllowanceInTHAExchange", usdtAllowanceInTHAExchange);

    const loadLevel = (level: any) => {
        fetch(`${process.env.REACT_APP_ADMIN_API_URL}/user/${address}/referrals`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json'  // 設置Content-Type為application/json
            },
            body: JSON.stringify({ level })
        })
            .then(response => response.json())
            .then(data => {
                console.log('[debug]userreferral:', data);
                setReferralsList2(data);
                // setCobwebData(data);
                // setIsLoading(false);
            }).finally(() => {
                setIsWebLoading(false);
            })
    }
    useEffect(() => {
        if (address) {
            fetch(`${process.env.REACT_APP_ADMIN_API_URL}/user/${address}/referrallevel`, {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
            })
                .then(response => response.json())
                .then(data => {
                    console.log('[debug]maxlevel:', data);
                    setMaxLevel(data.maxLevel);
                }).finally(() => {
                    setIsWebLoading(false);
                })
        }
    }, [address])

    useEffect(() => {
        if (address) {
            setIsWebLoading(true);

            fetch(`${process.env.REACT_APP_ADMIN_API_URL}/cobweb/${address}`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    setCobwebData(data);
                    setIsLoading(false);
                    // setReferralsList2(data);
                }).finally(() => {
                    setIsWebLoading(false);
                })
        }
    }, [address])

    useEffect(() => {
        if (address) {
            loadLevel(1)
        }
    }, [address])


    useEffect(() => {
        // (async () => {
        if (stakeMinGons && accountLevel) {
            try {
                setIsLoading(true);
                fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/comm/queryValidReferralQuantity`, {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        thresholdAmt: stakeMinGons,
                        sToken: addresses[chainID].SOHM_ADDRESS,
                        member: address,
                        memberLevel: accountLevel,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        setValidNodeAmount(data);

                        setIsLoading(false);
                    });
            } catch (error) {
                setIsLoading(false);

                console.log("error", error);
            }
        } else {
            setValidNodeAmount(0);
        }
    }, [stakeMinGons, address, chainID, accountLevel]);

    useEffect(() => {
        if (location.search.indexOf("code") > -1) {
            let obj: any = {};
            let params = location.search.substring(1).split("&");

            params.map(_ => {
                obj[_.split("=")[0]] = _.split("=")[1];
            });
            // if(referrer) {

            // setModalInviteAddr(obj.code);
            setInviteAddr(referrer !== "0x0000000000000000000000000000000000000000" ? referrer : obj.code);
        } else {
            const _inviteAddr = referrer !== "0x0000000000000000000000000000000000000000" ? referrer : "";
            setInviteAddr(_inviteAddr as string);
        }
    }, [location.search, referrer, address]);

    useEffect(() => {
        if (connected && provider) {
            dispatch(getCommunityData({ provider, networkID: chainID, address }));
            dispatch(getInviteData({ provider, networkID: chainID, address }));
            dispatch(getBalances({ provider, networkID: chainID, address }));
        }
    }, [connected, chainID, dispatch, provider, address]);

    const handleHover = (event: any) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const setMax = () => {
        setQuantity(Number(usdtBal));
    };

    const open = Boolean(anchorEl);
    const id = open ? "invitation-list" : undefined;
    const handleJoin = async () => {
        if (inviteAddr?.toLowerCase() === address?.toLowerCase()) {
            return dispatch(error(t`You can't invite yourself!`));
        }
        await dispatch(
            communityJoin({
                provider,
                referer: inviteAddr,
                networkID: chainID,
                // level:1
            }),
        );
        // setModalOpen(false);
        dispatch(getCommunityData({ provider, networkID: chainID, address }));
    };

    const handleTurbine = (type: string) => {
        dispatch(communityTurbine({ type, provider, networkID: chainID, address }));
        dispatch(loadAccountDetails({ networkID: chainID, address, provider: provider }));
    };

    const handleApprove = async () => {
        await dispatch(approveTHAExchange({ provider, networkID: chainID, address }));
    };

    const handleConfirm = () => {
        if (isNaN(quantity) || quantity === 0) {
            // eslint-disable-next-line no-alert
            return dispatch(error(t`Please enter a value!`));
        } else if (Number(quantity) < 0) {
            return dispatch(error(t`Please enter a valid value!`));
        } else if (Number(quantity) > Number(usdtBal)) {
            return dispatch(error(t`Insufficient balance!`));
        } else if (!needWhitelist) {
            return dispatch(error(t`Sorry, you are not eligible to participate in this event!`));
        }
        dispatch(thaExchange({ provider, networkID: chainID, address, amount: quantity }));
    };

    const copyLink = () => {
        dispatch(success(t`Copy successful!`));
    };

    const handleLevel = (level: number) => {
        setAnchorEl(null);
        loadLevel(level);
        // dispatch(getInviteData({ provider, networkID: chainID, address, level: level + Number(accountLevel) }));
    };

    const onAmountChange = (event: any) => {
        let value = event.target.value.replace(/[^\d.]/g, "");
        if (value < 0) {
            setQuantity("");
            return;
        }
        setQuantity(value);
    };

    const trimmedBalance = Number(
        [sohmBalance, gonsBal]
            .filter(Boolean)
            .map(balance => Number(balance))
            .reduce((a, b) => a + b, 0)
            .toFixed(4),
    );

    const { login, user }: any = useAuthContext();

    // console.log('[debug]user:', user, address,(user?.address || '').toLowerCase() != address?.toLowerCase());

    return (
        <PullToRefresh onRefresh={async () => {
            const defaultProvider = DEFAULT_PROVIDER;
            const works = [];
            works.push(
                dispatch(loadAppDetails({ networkID: 137, provider: defaultProvider }))
            )

            if (address)
                works.push(
                    dispatch(getCommunityData({ networkID: 137, provider: defaultProvider, address }))
                )

            return Promise.all(works);

        }}>
            <Box
                display="flex"
                flexDirection={"column"}
                alignItems={"center"}
                sx={{
                    pt: isSmallScreen ? 2.5 : 0,
                    px: isSmallScreen ? 1.875 : 0,
                }}
            >
                <Zoom in={true} timeout={1000}>
                    <Paper
                        className="ohm-card linear-card"
                        sx={{
                            mb: isSmallScreen ? 1.875 : 3.125,
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                                py: 6,
                            }}
                        >
                            <Grid container rowSpacing={isSmallScreen ? 3 : 0}>
                                <Grid item xs={12} md={4}>
                                    <Typography
                                        align="center"
                                        sx={{
                                            color: "#868B8F",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}
                                    >
                                        {/* 有效節點數 */}
                                        <Trans>Number of active nodes</Trans>
                                    </Typography>

                                    {isLoading ? (
                                        <Box
                                            display="flex"
                                            alignItems={"center"}
                                            justifyContent="center"
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <Skeleton width="80px" />
                                        </Box>
                                    ) : (
                                        <Typography
                                            align="center"
                                            sx={{
                                                color: "#C7C8CC",
                                                fontSize: "21px",
                                                fontStyle: "normal",
                                                fontWeight: "700",
                                            }}
                                        >
                                            {connected ? validNodeAmount : "0"}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <Typography
                                            align="center"
                                            sx={{
                                                color: "#868B8F",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {/* 當前質押數量 */}
                                            <Trans>Current staked amount</Trans>
                                        </Typography>
                                        <InfoTooltip
                                            message={
                                                <div>
                                                    <div>
                                                        {t`Current staked value`}
                                                    </div>
                                                    {isAppLoading ? <Skeleton width="80px" /> : <div>
                                                        ${Number(trimmedBalance * (marketPrice || 0)).toFixed(2)}
                                                    </div>}
                                                </div>

                                            }
                                        // message={t``}
                                        />
                                    </Box>
                                    {isAppLoading ? (
                                        <Box
                                            display="flex"
                                            alignItems={"center"}
                                            justifyContent="center"
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <Skeleton width="80px" />
                                        </Box>
                                    ) : (
                                        <Typography
                                            align="center"
                                            sx={{
                                                color: "#C7C8CC",
                                                fontSize: "21px",
                                                fontStyle: "normal",
                                                fontWeight: "700",
                                            }}
                                        >
                                            {trimmedBalance} sLGNS
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography
                                        align="center"
                                        sx={{
                                            color: "#868B8F",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}
                                    >
                                        {/* 質押最小值 */}
                                        <Trans>Minimum staked amount</Trans>
                                    </Typography>
                                    {isAppLoading ? (
                                        <Box
                                            display="flex"
                                            alignItems={"center"}
                                            justifyContent="center"
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <Skeleton width="80px" />
                                        </Box>
                                    ) : (
                                        <Typography
                                            align="center"
                                            sx={{
                                                color: "#C7C8CC",
                                                fontSize: "21px",
                                                fontStyle: "normal",
                                                fontWeight: "700",
                                            }}
                                        >
                                            {trim(Number(stakeMinAmount), 4)} sLGNS
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Zoom>
                <Zoom in={true} timeout={1000}>
                    <Paper
                        className="ohm-card linear-card"
                        sx={{
                            mb: isSmallScreen ? 1.875 : 3.125,
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                position: "relative",
                                overflow: "hidden",
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                            }}
                        >
                            <Box
                                sx={{ py: 5.25 }}
                                display="flex"
                                alignItems={"center"}
                                justifyContent="center"
                                flexDirection={"column"}
                            >
                                <Box sx={{ mb: 0.75 }}>
                                    <Typography
                                        align="center"
                                        sx={{
                                            color: "#868B8F",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "26px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}
                                    >
                                        <Trans>Cobweb Reward</Trans>
                                    </Typography>
                                </Box>
                                <Box sx={{ mb: 2.5 }}>
                                    <Typography
                                        sx={{
                                            color: "#C7C8CC",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "40px",
                                            fontStyle: "normal",
                                            fontWeight: "700",
                                        }}
                                    >
                                        {trim(mintReward, 4)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <LoadingButton
                                        variant="contained"
                                        onClick={() => handleTurbine("mint")}
                                        disabled={
                                            Number(mintReward) <= 0 || isPendingTxn(pendingTransactions, "community_turbine") || !connected
                                        }
                                        loading={isPendingTxn(pendingTransactions, "community_turbine")}

                                        // loadingPosition="end"
                                        // endIcon={
                                        //   // isPendingTxn(pendingTransactions, "approve_tha_exchange") ? (
                                        //   //   <CircularProgress color="inherit" size={16} />
                                        //   // ) : null
                                        //   isPendingTxn(pendingTransactions, "community_turbine") && (
                                        //     <CircularProgress color="inherit" size={16} />
                                        //   )
                                        // }
                                        sx={{
                                            width: "100%",
                                            borderRadius: "30px",
                                            border: "1.3px solid #B50C05",
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                            "&:hover": {
                                                background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
                                                boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#FFF",
                                                fontFamily: "AGaramond LT",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                textTransform: "none",
                                            }}
                                        >
                                            {txnButtonText(pendingTransactions, "community_turbine", t`Turbine`)}
                                            {/* <Trans>Turbine</Trans> */}
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    backgroundImage: isSmallScreen ? `url(${InviteBgLeftMobile})` : `url(${InviteBgLeft})`,
                                    width: isSmallScreen ? "139px" : "298px",
                                    height: isSmallScreen ? "207px" : "222px",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                }}
                            />
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    backgroundImage: isSmallScreen ? `url(${InviteBgRightMobile})` : `url(${InviteBgRight})`,
                                    width: isSmallScreen ? "139px" : "298px",
                                    height: isSmallScreen ? "207px" : "222px",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                }}
                            />
                        </Box>
                    </Paper>
                </Zoom>
                <Zoom in={true} timeout={1000}>
                    <Paper
                        className="ohm-card linear-card"
                        sx={{
                            mb: isSmallScreen ? 1.875 : 3.125,
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                px: isSmallScreen ? 2.5 : 3.75,
                                py: isSmallScreen ? 1.875 : 3.75,
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                            }}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box sx={{ mb: 0.75, width: "100%" }}>
                                <Typography
                                    sx={{
                                        color: "#C7C8CC",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "16px" : "20px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                    }}
                                    align="left"
                                >
                                    <Trans>Referrer</Trans>
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    borderRadius: "26px",
                                    border: "1px solid #5F6674",
                                    background: "linear-gradient(0deg, #464B55 0%, #101114 100%)",
                                    boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.50)",
                                    p: 1.125,
                                    height: "44px",
                                    mb: 2,
                                }}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography
                                    sx={{
                                        color: "#C7C8CC",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "12px" : "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        width: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "noWrap",
                                    }}
                                >
                                    {inviteAddr ? inviteAddr : ""}
                                </Typography>
                                <LoadingButton
                                    variant="contained"
                                    onClick={handleJoin}
                                    disabled={isInvited || isPendingTxn(pendingTransactions, "community_join") || !connected}
                                    loading={isPendingTxn(pendingTransactions, "community_join")}
                                    loadingPosition="end"
                                    endIcon={
                                        // isPendingTxn(pendingTransactions, "approve_tha_exchange") ? (
                                        //   <CircularProgress color="inherit" size={16} />
                                        // ) : null
                                        isPendingTxn(pendingTransactions, "community_join") && <CircularProgress color="inherit" size={16} />
                                    }
                                    sx={{
                                        px: 3.5,
                                        height: "26px",
                                        // maxWidth: "84px",
                                        width: "auto",
                                        whiteSpace: "nowrap",
                                        opacity: isInvited ? 0.5 : 1,
                                        borderRadius: "30px",
                                        border: "1.3px solid #B50C05",
                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                        "&:hover": {
                                            background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#FFF",
                                            fontFamily: "AGaramond LT",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}
                                    >
                                        {txnButtonText(pendingTransactions, "community_join", t`Join`)}
                                        {/* <Trans>Join</Trans> */}
                                    </Typography>
                                </LoadingButton>
                            </Box>
                            <Box sx={{ mb: 0.75, width: "100%" }}>
                                <Typography
                                    sx={{
                                        color: "#C7C8CC",
                                        fontFamily: "AGaramond LT",
                                        fontSize: "20px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                    }}
                                >
                                    <Trans>Referral Link</Trans>
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    borderRadius: "26px",
                                    border: "1px solid #5F6674",
                                    background: "linear-gradient(0deg, #464B55 0%, #101114 100%)",
                                    boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.50)",
                                    p: 1.125,
                                    height: "44px",
                                }}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {/* <Typography
                sx={{
                  color: "#C7C8CC",
                  fontFamily: "AGaramond LT",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                }}
              >
              </Typography> */}

                                {/* <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center"> */}
                                <Typography
                                    sx={{
                                        color: "#C7C8CC",
                                        fontFamily: "AGaramond LT",
                                        fontSize: isSmallScreen ? "12px" : "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        whiteSpace: "nowrap",
                                        width: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {`${window.location.origin}/invite?code=${address ?? ""}`}
                                </Typography>
                                {/* </Box> */}
                                <CopyToClipboard text={`${window.location.origin}/invite?code=${address ?? ""}`}>
                                    <Button
                                        onClick={copyLink}
                                        variant="contained"
                                        sx={{
                                            px: 3.5,
                                            height: "26px",
                                            // maxWidth: "84px",
                                            maxWidth: "90px",

                                            width: "100%",
                                            borderRadius: "30px",
                                            border: "1.3px solid #B50C05",
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                            "&:hover": {
                                                background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
                                                boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#FFF",
                                                fontFamily: "AGaramond LT",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}
                                        >
                                            <Trans>Copy</Trans>
                                        </Typography>
                                    </Button>
                                </CopyToClipboard>
                            </Box>
                        </Box>
                    </Paper>
                </Zoom>


                {/* {connected && ( */}
                <Zoom in={true} timeout={1000}>
                    <Paper
                        className="ohm-card linear-card"
                        sx={{
                            mb: isSmallScreen ? 1.875 : 3.125,
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: isSmallScreen ? "12px" : "24px",
                                background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                                minHeight: "417px",
                            }}
                        >
                            <Box sx={{ pt: 5.25, pb: 4.625 }}>
                                <Grid container spacing={isSmallScreen ? 2.5 : 2}>
                                    <Grid item xs={12} lg={4}>
                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                            <Typography
                                                sx={{
                                                    color: "#868B8F",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    mb: 1,
                                                }}
                                            >
                                                <Trans>Direct Invitation</Trans>
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "21px" : "22px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                {connected ? (
                                                    typeof directReferralQuantity == "number" ? (
                                                        directReferralQuantity
                                                    ) : (
                                                        <Skeleton width="100px" />
                                                    )
                                                ) : (
                                                    0
                                                )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                            <Typography
                                                sx={{
                                                    color: "#868B8F",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    mb: 1,
                                                }}
                                            >
                                                <Trans>Team members</Trans>
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "21px" : "22px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                {connected ? typeof teamNumber == "number" ? teamNumber : <Skeleton width="100px" /> : 0}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                            <Typography
                                                sx={{
                                                    color: "#868B8F",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    mb: 1,
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                component="div"
                                            >
                                                <Trans>Total Stake</Trans>
                                                <InfoTooltip
                                                    message={
                                                        <div>
                                                            <div>
                                                                {t`Current staked value`}
                                                            </div>
                                                            {isAppLoading ? <Skeleton width="80px" /> : <div>
                                                                ${Number((totalStakeAmount || 0) * (marketPrice || 0)).toFixed(2)}
                                                            </div>}
                                                        </div>

                                                    }
                                                // message={t``}
                                                />
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "21px" : "22px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                {connected ? (
                                                    totalStakeAmount || typeof totalStakeAmount == "number" ? (
                                                        `${trim(Number(totalStakeAmount), 4)} sLGNS`
                                                    ) : (
                                                        <Skeleton width="100px" />
                                                    )
                                                ) : (
                                                    "0 sLGNS"
                                                )}
                                            </Typography>
                                        </Box>
                                        +                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                            <Typography
                                                sx={{
                                                    color: "#868B8F",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    mb: 1,
                                                }}
                                            >
                                                <Trans>Total LP Bond</Trans>
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "21px" : "22px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                {connected ? (
                                                    totalLPBond || typeof totalLPBond == "number" ? (
                                                        `${trim(Number(totalLPBond), 4)} USD`
                                                    ) : (
                                                        <Skeleton width="100px" />
                                                    )
                                                ) : (
                                                    "0 USD"
                                                )}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                            <Typography
                                                sx={{
                                                    color: "#868B8F",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "14px" : "16px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    mb: 1,
                                                }}
                                            >
                                                <Trans>Total Stable Bond</Trans>
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#C7C8CC",
                                                    fontFamily: "AGaramond LT",
                                                    fontSize: isSmallScreen ? "21px" : "22px",
                                                    fontStyle: "normal",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                {connected ? (
                                                    totalStableBond || typeof totalStableBond == "number" ? (
                                                        `${trim(Number(totalStableBond), 4)} USD`
                                                    ) : (
                                                        <Skeleton width="100px" />
                                                    )
                                                ) : (
                                                    "0 USD"
                                                )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ pl: isSmallScreen ? 2.5 : 3.75, mb: 1.25 }}>
                                <Typography
                                    sx={{
                                        color: "#C7C8CC",
                                        fontFamily: "AGaramond LT",
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                    }}
                                >
                                    <Trans>Invitation List</Trans>
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "1px",
                                    background: "#0B0C0A",
                                    boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                                }}
                            />
                            <Box sx={{ px: 1 }}>
                                <TableContainer sx={{ minHeight: "330px" }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        borderBottom: "none",
                                                    }}
                                                >
                                                    <Box
                                                        display={"flex"}
                                                        alignItems="center"
                                                        justifyContent={"flex-start"}
                                                        onClick={e => handleHover(e)}
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: "#ed0000",
                                                                fontFamily: "AGaramond LT",
                                                                fontSize: "16px",
                                                                fontStyle: "normal",
                                                                fontWeight: "400",
                                                                mr: 0.75,
                                                            }}
                                                        >
                                                            <Trans>Level</Trans>
                                                        </Typography>
                                                        <SvgIcon
                                                            viewBox="0 0 15 8"
                                                            sx={{
                                                                width: "15px",
                                                            }}
                                                            component={ArrowDownIcon}
                                                            htmlColor="#ed0000"
                                                        />
                                                    </Box>
                                                </TableCell>

                                                <TableCell
                                                    align="right"
                                                    sx={{
                                                        color: "#868B8F",
                                                        fontFamily: "AGaramond LT",
                                                        fontSize: "16px",
                                                        fontStyle: "normal",
                                                        fontWeight: "400",
                                                        borderBottom: "none",
                                                    }}
                                                >
                                                    <Trans>Address</Trans>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {referralsList2 && referralsList2?.length > 0 ? (
                                                referralsList2.map((item: any, index: number) => (
                                                    <TableRow
                                                        key={'rl2k_' + index}
                                                        sx={{
                                                            height: "56px",
                                                            cursor: "pointer",
                                                            "&:hover": {
                                                                "& > .MuiTableCell-root": {
                                                                    "&:first-of-type": {
                                                                        borderRadius: "16px 0 0 16px",
                                                                        overflow: "hidden",
                                                                        // border: "1px solid #303437",
                                                                        background: "linear-gradient(180deg, #131418 0%, #242528 100%)",
                                                                    },
                                                                    "&:last-of-type": {
                                                                        borderRadius: "0 16px 16px 0",
                                                                        overflow: "hidden",
                                                                        // border: "1px solid #303437",
                                                                        background: "linear-gradient(180deg, #131418 0%, #242528 100%)",
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <TableCell
                                                            sx={{
                                                                borderBottom: "none",
                                                                p: 1.25,
                                                            }}
                                                            align="left"
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: "#C7C8CC",
                                                                    fontFamily: "AGaramond LT",
                                                                    fontSize: "16px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "400",
                                                                }}
                                                            >
                                                                <Trans>Level</Trans>{item.level}
                                                                {/* {Number(item.level) - Number(accountLevel)} */}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                borderBottom: "none",
                                                                p: 1.25,
                                                                // borderRadius: " 0  16px 16px 0",
                                                                // overflow: "hidden",
                                                                // border: "1px solid #303437",
                                                                // background: "linear-gradient(180deg, #131418 0%, #242528 100%)",
                                                            }}
                                                            align="right"
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: "#C7C8CC",
                                                                    fontFamily: "AGaramond LT",
                                                                    fontSize: "16px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "400",
                                                                }}
                                                            >
                                                                {shorten(item.address)}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={3}
                                                        sx={{
                                                            height: "300px",
                                                            borderBottom: "none",
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{ width: "100%", height: "100%" }}
                                                            display="flex"
                                                            alignItems={"center"}
                                                            justifyContent={"center"}
                                                            flexDirection={"column"}
                                                        >
                                                            <img src={NoData} alt="No Data" />
                                                            <Typography
                                                                sx={{
                                                                    pt: 2,
                                                                    color: "#C7C8CC",
                                                                    fontFamily: "AGaramond LT",
                                                                    fontSize: "12px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "400",
                                                                }}
                                                            >
                                                                <Trans>No data</Trans>
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {/* <TableRow
                    sx={{
                      height: "56px",
                      cursor: "pointer",
                      "&:hover": {
                        "& > .MuiTableCell-root": {
                          background: "linear-gradient(180deg, #131418 0%, #242528 100%)",
                          "&:first-child": {
                            borderRadius: "16px 0 0 16px",
                            overflow: "hidden",
                            // border: "1px solid #303437",
                          },
                          "&:last-child": {
                            borderRadius: "0 16px 16px 0",
                            overflow: "hidden",
                            // border: "1px solid #303437",
                            background: "linear-gradient(180deg, #131418 0%, #242528 100%)",
                          },
                        },
                        // overflow: "hidden",
                        // borderRadius: "16px",
                        // border: "1px solid #303437",
                        // background: "linear-gradient(180deg, #131418 0%, #242528 100%)",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        borderBottom: "none",
                      }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "#C7C8CC",
                          fontFamily: "AGaramond LT",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                        }}
                      >
                        <Trans>Level</Trans>1
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        // borderRadius: " 0  16px 16px 0",
                        // overflow: "hidden",
                        // border: "1px solid #303437",
                        // background: "linear-gradient(180deg, #131418 0%, #242528 100%)",
                      }}
                      align="right"
                    >
                      <Typography
                        sx={{
                          color: "#C7C8CC",
                          fontFamily: "AGaramond LT",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                        }}
                      >
                        09/11/2023
                      </Typography>
                    </TableCell>
                  </TableRow> */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Paper>
                </Zoom>
                <MyCommunityData isSmallScreen={isSmallScreen} isLoading={isWebLoading} rankingListData={cobwebData} />
                {/* )} */}
                {maxLevel && Number(maxLevel) > 0 && (
                    <Popper id={id} open={!!anchorEl} anchorEl={anchorEl} placement="bottom-start">
                        {({ TransitionProps }) => {
                            return (
                                <Fade {...TransitionProps} timeout={100}>
                                    <Paper
                                        // className="ohm-card"
                                        sx={{
                                            minWidth: "75px",
                                            px: 0.5,
                                            width: "auto",
                                            height: "auto",
                                            opacity: "1 !important",
                                            visibility: "visible !important",
                                            // py: 2.5,
                                            borderRadius: "6px",
                                            border: "2.4px solid rgba(28, 30, 33, 0.60)",
                                            background: "linear-gradient(136deg, #363B40 9.64%, #1D1E23 89.75%)",
                                            boxShadow: "2px 4px 9px 0px #070707, -3px -2px 16px 0px #455059",
                                        }}
                                        elevation={1}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection={"column"}
                                            alignItems="center"
                                            sx={{
                                                borderRadius: "6px",
                                                background: "linear-gradient(136deg, #32373A 9.64%, #131416 89.75%)",
                                                boxShadow: "0px 0px 4px 0px #33383C inset",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            {maxLevel &&
                                                Array.from({ length: Number(maxLevel) }).map((_, index) => (
                                                    <Button key={index} variant="text" onClick={() => handleLevel(index + 1)}>
                                                        <Typography
                                                            sx={{
                                                                color: "#C7C8CC",
                                                                fontSize: "14px",
                                                                fontStyle: "normal",
                                                                fontWeight: "400",
                                                                lineHeight: "normal",
                                                            }}
                                                        >
                                                            <Trans>Level</Trans> {index + 1}
                                                        </Typography>
                                                    </Button>
                                                ))}

                                            {/* <Button variant="text" onClick={() => handleLevel(2)}>
                    <Typography
                      sx={{
                        color: "#C7C8CC",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}
                    >
                      <Trans>Level 2</Trans>
                    </Typography>
                  </Button>
                  <Button variant="text" onClick={() => handleLevel(3)}>
                    <Typography
                      sx={{
                        color: "#C7C8CC",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}
                    >
                      <Trans>Level 3</Trans>
                    </Typography>
                  </Button> */}
                                        </Box>
                                    </Paper>
                                </Fade>
                            );
                        }}
                    </Popper>
                )}


            </Box>
        </PullToRefresh>
    );
};

export default Invite;
