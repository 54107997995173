import Chart from "src/components/Chart/Chart.jsx";
import { useTheme } from "@material-ui/core/styles";
import { trim, formatCurrency } from "../../../../helpers";
import { useTreasuryMetrics,useTreasuryMetrics2 } from "../../hooks/useTreasuryMetrics";
import { useTreasuryRebases } from "../../hooks/useTreasuryRebases";
import { bulletpoints, tooltipItems, tooltipInfoMessages, itemType } from "../../treasuryData";
import { t } from "@lingui/macro";

export const Graph = ({ children }) => <>{children}</>;

export const TotalValueDepositedGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });
  return (
    <Chart
      type="area"
      data={data}
      itemType={itemType.dollar}
      itemNames={[t`Total value of sLGNS circulating supply`]}
      dataKey={["totalValueLocked"]}
      headerText={t`Total value of sLGNS circulating supply`}
      stopColor={[["#7A88A4", "#889EC9", "#2E3138"]]}
      bulletpointColors={bulletpoints.tvl}
      infoTooltipMessage={t`Total value of sLGNS circulating supply`}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
      headerSubText={`${data && formatCurrency(data[0].totalValueLocked)}`}
    />
  );
};

export const MarketValueGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });

  return (
    <Chart
      type="area"
      data={data}
      dataKey={["treasuryMarketValue"]}
      stopColor={[["#53E1C9", "#4BBFD1", "#2E3138"]]}
      headerText={t`Market Value of Treasury Assets`}
      headerSubText={`${data && formatCurrency(data[0].treasuryMarketValue)}`}
      bulletpointColors={bulletpoints.coin}
      itemNames={tooltipItems.coin}
      itemType={itemType.dollar}
      infoTooltipMessage={tooltipInfoMessages.mvt}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
    />
  );
};

export const RiskFreeValueGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });

  return (
    <Chart
      type="area"
      data={data}
      format="currency"
      dataKey={["treasuryRiskFreeValue"]}
      stopColor={[
        ["#ECA14A", "#E1945F", "#2E3138"],
        ["#53E1C9", "#4BBFD1", "#2E3138"],
        // ["red", "blue", "yellow"],
        // ["#ECA14A", "#E1945F", "#2E3138"],
        // ["#768299", "#98B3E9"],
      ]}
      headerText={t`Risk Free Value of Treasury Assets`}
      headerSubText={`${data && formatCurrency(data[0].treasuryRiskFreeValue)}`}
      bulletpointColors={bulletpoints.rfv}
      itemNames={tooltipItems.rfv}
      itemType={itemType.dollar}
      infoTooltipMessage={tooltipInfoMessages.rfv}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
    />
  );
};

export const ProtocolOwnedLiquidityGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });

  return (
    <Chart
      isPOL
      type="area"
      data={data}
      dataFormat="percent"
      itemNames={[t`Market SLP`]}
      itemType={itemType.percentage}
      dataKey={["treasuryOhmUsdtPOL"]}
      bulletpointColors={bulletpoints.pol}
      infoTooltipMessage={tooltipInfoMessages.pol}
      headerText={t`Protocol Owned Liquidity LGNS-DAI`}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
      headerSubText={`${data && trim(data[0].treasuryOhmUsdtPOL, 2)}% `}
      stopColor={[["#7143F4", "#652AE6", "#2E3138"]]}
    />
  );
};

export const TAOBurningGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });

  return (
    <Chart
      isBurning
      type="area"
      data={data}
      itemNames={t`LGNS Burning`}
      itemType={itemType.dollar}
      dataKey={["totalBurn"]}
      bulletpointColors={bulletpoints.burning}
      infoTooltipMessage={""}
      headerText={t`Total LGNS Burning`}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
      headerSubText={`${data && trim(data[0].totalBurn, 4)}`}
      stopColor={[["#F4E243", "#F4E243", "#2E3138"]]}
    />
  );
};

export const TAOMintingGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });

  return (
    <Chart
      isMinted
      type="area"
      data={data}
      itemNames={t`A Minting`}
      itemType={itemType.dollar}
      dataKey={["tusdbMint"]}
      bulletpointColors={bulletpoints.minting}
      infoTooltipMessage={tooltipInfoMessages.pol}
      headerText={t`Total A Minting`}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
      headerSubText={`${data && trim(data[0].tusdbMint, 4)}`}
      stopColor={[["#DF7A7A", "#DF7A7A", "#2E3138"]]}
    />
  );
};

export const OHMStakedGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });
  // console.log("data", data);
  const staked =
    data &&
    data
      .map(metric => ({
        stakeRate: metric.stakeRate * 100,
        timestamp: metric.timestamp,
      }))
      .filter(metric => metric.stakeRate < 100);

  return (
    <Chart
      isStaked
      type="area"
      data={staked}
      dataKey={["stakeRate"]}
      dataFormat="percent"
      headerText={t`LGNS Staked`}
      stopColor={[["#7ADF8D", "#35C083", "#2E3138"]]}
      bulletpointColors={bulletpoints.staked}
      infoTooltipMessage={tooltipInfoMessages.staked}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
      headerSubText={`${staked && trim(staked[0].stakeRate, 2)}% `}
    />
  );
};

export const APYOverTimeGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryRebases({ refetchOnMount: false });

  let apy =
    data &&
    data
      .map(entry => ({
        timestamp: entry.timestamp,
        apy: Math.pow(parseFloat(entry.percentage) + 1, 365 * 3) * 100,
      }))
      .filter(pm => pm.apy < 300000);

  return (
    <Chart
      type="line"
      scale="log"
      data={apy}
      dataKey={["apy"]}
      dataFormat="percent"
      headerText={t`APY over time`}
      itemNames={tooltipItems.apy}
      itemType={itemType.percentage}
      color={theme.palette.text.primary}
      bulletpointColors={bulletpoints.apy}
      stroke={[theme.palette.text.primary]}
      infoTooltipMessage={tooltipInfoMessages.apy}
      headerSubText={`${data && trim(apy[0].apy, 2)}%`}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
    />
  );
};

export const RunwayAvailableGraph = () => {
    console.log('[debug]graph:');
  const theme = useTheme();
  const { data } = useTreasuryMetrics2({ refetchOnMount: false });
  console.log(data);

  const runway = data?.map(m=>{
    m.id=m.id*1;
    return m;
  });// && data.filter(metric => metric.runway10k > 5);

  const [current, ...others] = bulletpoints.runway;
  const runwayBulletpoints = [{ ...current, background: "#7adf8d" }, ...others];
  const colors = runwayBulletpoints.map(b => b.background);
  // console.log("runwayBulletpoints", runwayBulletpoints);
  return (
    <Chart
      type="multi"
      data={runway}
      dataKey={["runwayCurrent"]}
      color={theme.palette.text.primary}
      stroke={["#7ADF8D"]}
      headerText={t`Runway Available`}
      headerSubText={`${data && trim(data[0].runwayCurrent, 1)} Days`}
      dataFormat="days"
      bulletpointColors={runwayBulletpoints}
      itemNames={tooltipItems.runway}
      itemType={""}
      infoTooltipMessage={tooltipInfoMessages.runway}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
    />
  );
};
