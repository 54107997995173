import { Typography } from "@mui/material";
import OlympusLogo from "../../assets/Olympus Logo.svg";
import "./notfound.scss";
import { Trans } from "@lingui/macro";

export default function NotFound() {
  return (
    <div id="not-found">
      <div className="not-found-header">
        {/* <a href="https://xenophon.finance" target="_blank">
          <img className="branding-header-icon" src={OlympusLogo} alt="OlympusDAO" />
        </a> */}

        <h4>
          <Typography>
            <Trans>Page not found</Trans>
          </Typography>
        </h4>
      </div>
    </div>
  );
}
