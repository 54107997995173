// export { default as useDebounce } from "./Debounce";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";

export { useWeb3Context, useAddress } from "./web3Context";
export const useIsStakeV2 = () => {
  const location = useLocation();
  const [isStakeV2, setIsStakeV2] = useState(location.pathname == "/stakev2");
  useEffect(() => {
    setIsStakeV2(location.pathname == "/stakev2");
  }, [location.pathname]);
  return isStakeV2;
};

export const useIsV2 = () => {
  const location = useLocation();
  const [isV2, setIsV2] = useState(location.pathname == "/stakev2");
  useEffect(() => {
    const v2 = location.pathname == "/stakev2" || location.pathname == "/swapv2" || location.pathname == "/migration";
    setIsV2(v2);
  }, [location.pathname]);
  return isV2;
};

export const useIsSwapV2 = () => {
  const location = useLocation();
  const [isStakeV2, setIsStakeV2] = useState(location.pathname == "/swapv2");
  useEffect(() => {
    setIsStakeV2(location.pathname == "/swapv2");
  }, [location.pathname]);
  return isStakeV2;
};
export const useIsPath = (path: string) => {
  const location = useLocation();
  const [isPath, setIsPath] = useState(location.pathname == path);
  useEffect(() => {
    setIsPath(location.pathname == path);
  }, [location.pathname]);
  return isPath;
};

export const useMobile = () => {
  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 355px)");
  return {
    isSmallScreen,
    isVerySmallScreen,
    isSmallerScreen,
  };
};

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export { default as useBonds } from "./Bonds";
