import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useMobile } from "src/hooks";

function TabPanel(props) {
  const { isSmallScreen } = useMobile();

  const { children, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ overflow: "hidden", margin: 0 }}
    >
      {value === index && <Box p={isSmallScreen ? 0 : 2}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  className: PropTypes.any,
};

export default TabPanel;
