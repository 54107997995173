import { useCallback, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  Typography,
  Zoom,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { t, Trans } from "@lingui/macro";
import SmoothScroll from "smooth-scroll";
import { ethers } from "ethers";
import NewReleases from "@material-ui/icons/NewReleases";
import RebaseTimer from "../../components/RebaseTimer/RebaseTimer";
import TabPanel from "../../components/TabPanel";
import { trim,trim2, toolNumber, isContractAddress } from "../../helpers";
import { changeApproval, changeStake, cliamWarmupBalance } from "../../slices/StakeThunk";
import "./stake.scss";
import { DEFAULT_PROVIDER, useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@mui/material";
import { error } from "../../slices/MessagesSlice";
import { useAppSelector } from "src/hooks";
import useBonds, { IAllBondData } from "../../hooks/Bonds";
import { ReactComponent as CalcIcon } from "../../assets/icons/calc.svg";
import { useMobile } from "../../hooks";
import Calculator from "./Calculator";

import { StakeHistory } from "./StakeHistory";
import { UnstakeHistory } from "./UnstakeHistory";
import { PullToRefresh } from "src/components/PullToRefresh";
import { loadAppDetails } from "src/slices/AppSlice";
import { getBalances } from "src/slices/AccountSlice";


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Stake() {
  const { isSmallScreen } = useMobile();
  const dispatch = useDispatch();
  const [refreshing, setRefreshing] = useState(false);
  // const { address }: { address: any } = useAccount();
  // const chainID = useChainId();
  // const { connect } = useConnect();
  const { provider, address, connected, connect, chainID } = useWeb3Context();
  const scrollTarget = useRef<HTMLDivElement>(null);
  const scroll = useMemo(() => new SmoothScroll(), []);

  const { bonds } = useBonds(chainID);
  const lpBond: any = bonds.filter(_ => _.name === "lgns_dai_lp")[0];
  const tokenPrice = lpBond && lpBond["marketPrice"];
  const [zoomed, setZoomed] = useState(false);
  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState<any>();

  const isAppLoading = useAppSelector(state => state.app.loading);
  const currentIndex = useAppSelector(state => {
    return state.app.currentIndex;
  });
  const fiveDayRate = useAppSelector(state => {
    return state.app.fiveDayRate;
  });
  // console.log("five", fiveDayRate, toolNumber(fiveDayRate));
  const ohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.ohm;
  });
  const gonsBal = useAppSelector(state => {
    return state.account.balances && state.account.balances.gonsBal;
  });
  const isLocked = useAppSelector(state => {
    return state.account && state.account.isLocked;
  });

  const oldSohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.oldsohm;
  });
  const sohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.sOHM;
  });
  const fsohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.fsohm;
  });
  const wsohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.wsohm;
  });
  const wsohmAsSohm = useAppSelector(state => {
    return state.account.balances && state.account.balances.wsohmAsSohm;
  });
  const stakeAllowance = useAppSelector(state => {
    return state.account.staking && state.account.staking.ohmStake;
  });
  

  const unstakeAllowance = useAppSelector(state => {
    return state.account.staking && state.account.staking.ohmUnstake;
  });
  const stakingRebase = useAppSelector(state => {
    return state.app.stakingRebase || 0;
  });
  const tokenBalInStaking = useAppSelector(state => {
    return state.app.tokenBalInStaking || 0;
  });
  const stakingAPY = useAppSelector(state => {
    // return state.app.stakingAPY || 0;
    return state.app.stakingAPY ? toolNumber(state.app.stakingAPY) : 0;
  });

  // const stakingTVL = useAppSelector(state => {
  //   return state.app.stakingTVL;
  // });
  const stakingTVL = tokenPrice * tokenBalInStaking;

  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });

  const setMax = () => {
    if (view === 0) {
      setQuantity(Math.trunc(Number(ohmBalance) * 10000) / 10000);
    } else {
      setQuantity(Number(sohmBalance));
    }
  };

  const onAmountChange = (event: any) => {
    let value = event.target.value.replace(/[^\d.]/g, "");
    if (value < 0) {
      setQuantity("");
      return;
    }
    setQuantity(value);
  };

  const onSeekApproval = async (token: string) => {
    await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
  };

  const onChangeStake = async (action: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(Number(quantity)) || Number(quantity) === 0) {
      // eslint-disable-next-line no-alert
      return dispatch(error(t`Please enter a value!`));
    } else if (quantity < 0) {
      return dispatch(error(t`Please enter a valid value!`));
    }

    // 1st catch if quantity > balance
    let gweiValue = ethers.utils.parseUnits(quantity.toString(), "gwei");
    if (action === "stake" && gweiValue.gt(ethers.utils.parseUnits(ohmBalance, "gwei"))) {
      return dispatch(error(t`You cannot stake more than your LGNS balance.`));
    }

    if (action === "unstake" && gweiValue.gt(ethers.utils.parseUnits(sohmBalance, "gwei"))) {
      return dispatch(error(t`You cannot unstake more than your sLGNS balance.`));
    }

    await dispatch(changeStake({ address, action, value: quantity.toString(), provider, networkID: chainID }));
    setQuantity(0);
  };

  const hasAllowance = useCallback(
    token => {
      if (token === "LGNS") return stakeAllowance ? stakeAllowance > 0 : 0;
      if (token === "sLGNS") return unstakeAllowance ? unstakeAllowance > 0 : 0;
      return 0;
    },
    [stakeAllowance, unstakeAllowance],
  );

  const isAllowanceDataLoading = (stakeAllowance == null && view === 0) || (unstakeAllowance == null && view === 1);

  let modalButton = [];

  modalButton.push(
    <Button
      variant="contained"
      className="connect-button"
      onClick={connect}
      key={1}
      sx={{
        height: "40px",
        borderRadius: "30px",
        border: "1.3px solid #B50C05",
        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
        color: "#FFFFFF",
        fontFamily: "AGaramond LT",
        fontSize: "18px",
        fontWeight: "700",
        "&:hover": {
          background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
          boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
        },
      }}
    >
      <Trans>Connect Wallet</Trans>
    </Button>,
  );

  const changeView = (_event: React.ChangeEvent<{}>, newView: number) => {
    setView(newView);
    (quantity || quantity == 0) && setQuantity("");
  };

  const trimmedBalance = Number(
    [sohmBalance]
      .filter(Boolean)
      .map(balance => Number(balance))
      .reduce((a, b) => a + b, 0)
      .toFixed(4),
  );
  const trimmedStakingAPY = stakingAPY * 100;
  // console.log("stakingRebase", stakingRebase);

  const stakingRebasePercentage = String(trim(stakingRebase * 100, 4));
  // console.log("stakingRebasePercentage", stakingRebasePercentage, trimmedStakingAPY);
  // console.log("trimmedBalance", trimmedBalance);
  // console.log("gonsBal", gonsBal);
  const nextRewardValue = trim((Number(stakingRebasePercentage) / 100) * (trimmedBalance + Number(gonsBal)), 4);
  const formatGonsBal = trim(Number(gonsBal), 4);
  const claimWarmupBal = () => {
    dispatch(cliamWarmupBalance({ address, provider, networkID: chainID }));
  };

  if (address && isContractAddress(address)) {
    return (
      <div id="stake-view">
      
        <Zoom in={true} onEntered={() => setZoomed(true)}>
          <Box
            sx={{
              px: isSmallScreen ? 1.875 : 0,
              pt: isSmallScreen ? 2.5 : 0,
            }}
          >
            <Paper
              className={`ohm-card linear-card`}
              sx={{
                mb: 3.125,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  px: isSmallScreen ? 0 : 3.75,
                  py: 2.5,
                  borderRadius: isSmallScreen ? "12px" : "24px",
                  // border: "2px solid #434952",
                  // background: "pink",
                  background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                  // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                }}
              >
                <Grid container direction="column" spacing={isSmallScreen ? 0 : 2}>
                  <Grid item>
                    <Box
                      sx={{
                        px: isSmallScreen ? 2.5 : 0,
                      }}
                      className="card-header"
                    >
                      <Box display="flex" alignItems={"center"} justifyContent={"space-between"}>
                        <Typography
                          sx={{
                            color: "#C7C8CC",
                            fontFamily: "AGaramond LT",
                            fontSize: "21px",
                            fontStyle: "normal",
                            fontWeight: "700",
                          }}
                        >
                          System Contract Address
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

            </Paper>
          </Box>
        </Zoom>
      
      </div>
    )
  }

  return (
    <PullToRefresh onRefresh={async () => {
      setRefreshing(true);
      try {
        const defaultProvider = DEFAULT_PROVIDER;
        await dispatch(loadAppDetails({ networkID: chainID, provider: defaultProvider }));
        if (address) {
          await dispatch(getBalances({ address, networkID: chainID, provider }));
        }
      }
      catch (err) {

      }
      finally {
        setRefreshing(false);
      }



    }}>


      <div id="stake-view">
        <Zoom in={true} onEntered={() => setZoomed(true)}>
          <Box
            sx={{
              px: isSmallScreen ? 1.875 : 0,
              pt: isSmallScreen ? 2.5 : 0,
            }}
          >
            <Paper
              className={`ohm-card linear-card`}
              sx={{
                mb: 3.125,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  px: isSmallScreen ? 0 : 3.75,
                  py: 2.5,
                  borderRadius: isSmallScreen ? "12px" : "24px",
                  // border: "2px solid #434952",
                  // background: "pink",
                  background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                  // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                }}
              >
                <Grid container direction="column" spacing={isSmallScreen ? 0 : 2}>
                  <Grid item>
                    <Box
                      sx={{
                        px: isSmallScreen ? 2.5 : 0,
                      }}
                      className="card-header"
                    >
                      <Box display="flex" alignItems={"center"} justifyContent={"space-between"}>
                        <Typography
                          sx={{
                            color: "#C7C8CC",
                            fontFamily: "AGaramond LT",
                            fontSize: "21px",
                            fontStyle: "normal",
                            fontWeight: "700",
                          }}
                        >
                          <Trans>Stake</Trans>
                        </Typography>
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            scroll.animateScroll(scrollTarget.current, undefined, {
                              easing: "easeInOutCubic",
                            });
                          }}
                        >
                          <CalcIcon />
                        </Box>
                      </Box>

                      <RebaseTimer />
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box
                      sx={{
                        px: isSmallScreen ? "20px !important" : 0,
                      }}
                      className="stake-top-metrics"
                    >
                      <Grid container spacing={2} alignItems="flex-start">
                        {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          <div className="stake-apy">
                            <Typography
                              sx={{
                                color: "#868B8F",
                                fontFamily: "AGaramond LT",
                                fontSize: isSmallScreen ? "14px" : "16px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                width: "100%",
                                mb: isSmallScreen ? 1 : 0,
                              }}
                              align={"center"}
                            >
                              <Trans>APY</Trans>
                            </Typography>
                            {stakingAPY ? (
                              <Typography
                                sx={{
                                  color: "#c7c8cc",
                                  fontFamily: "AGaramond LT",
                                  fontSize: isSmallScreen ? "21px" : "22px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  width: "100%",
                                  maxWidth: isSmallScreen ? "328px" : "300px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  wordWrap: "break-word",
                                }}
                                align={"center"}
                              >
                                {/* <span data-testid="apy-value"> */}
                                {new Intl.NumberFormat("en-US").format(Number(trimmedStakingAPY))}%{/* </span> */}
                              </Typography>
                            ) : (
                              <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
                                <Skeleton width="150px" data-testid="apy-loading" />
                              </Box>
                            )}
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          <div className="stake-tvl">
                            <Typography
                              sx={{
                                color: "#868B8F",
                                fontFamily: "AGaramond LT",
                                fontSize: isSmallScreen ? "14px" : "20px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                width: "100%",
                                mb: isSmallScreen ? 1 : 0,
                              }}
                              align={"center"}
                            >
                              <Trans>Total Value Deposited</Trans>
                            </Typography>
                            {stakingTVL ? (
                              <Typography
                                sx={{
                                  color: "#c7c8cc",
                                  fontFamily: "AGaramond LT",
                                  fontSize: isSmallScreen ? "21px" : "22px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  width: "100%",
                                }}
                                align={"center"}
                              >
                                <span data-testid="tvl-value">
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                  }).format(stakingTVL)}
                                </span>
                              </Typography>
                            ) : (
                              <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
                                <Skeleton width="150px" data-testid="tvl-loading" />
                              </Box>
                            )}
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          <div className="stake-index">
                            <Typography
                              sx={{
                                color: "#868B8F",
                                fontFamily: "AGaramond LT",
                                fontSize: isSmallScreen ? "14px" : "20px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                width: "100%",
                                mb: isSmallScreen ? 1 : 0,
                              }}
                              align={"center"}
                            >
                              <Trans>Current Index</Trans>
                            </Typography>
                            {currentIndex ? (
                              <Typography
                                sx={{
                                  color: "#c7c8cc",
                                  fontFamily: "AGaramond LT",
                                  fontSize: isSmallScreen ? "21px" : "22px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  width: "100%",
                                }}
                                align={"center"}
                              >
                                <span data-testid="index-value">{trim(Number(currentIndex), 2)} LGNS</span>
                              </Typography>
                            ) : (
                              <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
                                <Skeleton width="150px" data-testid="index-loading" />
                              </Box>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Box className="staking-area" sx={{ width: "100%" }}>
                    {!address ? (
                      <div className="stake-wallet-notification">
                        <div className="wallet-menu" id="wallet-menu">
                          {modalButton}
                        </div>
                        <Typography
                          align="center"
                          sx={{
                            color: "#C7C8CC",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "700",
                          }}
                        >
                          <Trans>Connect your wallet to stake LGNS</Trans>
                        </Typography>
                      </div>
                    ) : (
                      <>
                        <Box className="stake-action-area">
                          <Tabs
                            key={String(zoomed)}
                            centered
                            value={view}
                            textColor="primary"
                            // indicatorColor="yellow"
                            className="stake-tab-buttons"
                            onChange={changeView}
                            aria-label="stake tabs"
                            sx={{
                              mb: isSmallScreen ? "20px !important" : 0,
                              px: isSmallScreen ? "20px !important" : 0,
                            }}
                            //hides the tab underline sliding animation in while <Zoom> is loading
                            TabIndicatorProps={
                              !zoomed ? { style: { display: "none" } } : { style: { backgroundColor: "#B50C05" } }
                            }
                          >
                            <Tab
                              sx={{
                                color: "#C7C8CC",
                                fontFamily: "AGaramond LT",
                                fontSize: "20px",
                                fontWeight: "700",
                                '&[aria-selected="true"]': {
                                  color: "#B50C05",
                                },
                              }}
                              label={t({
                                id: "do_stake",
                                comment: "The action of staking (verb)",
                              })}
                              {...a11yProps(0)}
                            />
                            <Tab
                              label={t`Unstake`}
                              {...a11yProps(1)}
                              sx={{
                                color: "#C7C8CC",
                                fontFamily: "AGaramond LT",
                                fontSize: "20px",
                                fontWeight: "700",
                                '&[aria-selected="true"]': {
                                  color: "#B50C05",
                                },
                              }}
                            />
                          </Tabs>
                          <Box className="stake-action-row " display="flex" alignItems="center">
                            {address && !isAllowanceDataLoading ? (
                              (!hasAllowance("LGNS") && view === 0) || (!hasAllowance("sLGNS") && view === 1) ? (
                                <Box
                                  className="help-text"
                                  sx={{
                                    mb: isSmallScreen ? 2.5 : 0,
                                    px: isSmallScreen ? 2.5 : 0,
                                  }}
                                >
                                  <Typography
                                    className="stake-note"
                                    sx={{ color: "#868b8f", fontSize: "14px", fontWeight: "400" }}
                                  >
                                    {view === 0 ? (
                                      <>
                                        <Trans>First time staking</Trans> <b>LGNS</b>?
                                        <br />
                                        <Trans>Please approve Origin to use your</Trans> <b>LGNS</b>{" "}
                                        <Trans>for staking</Trans>.
                                      </>
                                    ) : (
                                      <>
                                        <Trans>First time unstaking</Trans> <b>sLGNS</b>?
                                        <br />
                                        <Trans>Please approve Origin to use your</Trans> <b>sLGNS</b>{" "}
                                        <Trans>for unstaking</Trans>.
                                      </>
                                    )}
                                  </Typography>
                                </Box>
                              ) : (
                                <FormControl
                                  className="ohm-input"
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    px: isSmallScreen ? 2.5 : 0,
                                    "&.ohm-input": {
                                      m: isSmallScreen ? "0 !important" : 0,
                                      mb: isSmallScreen ? "20px !important" : 0,
                                    },
                                  }}
                                >
                                  <OutlinedInput
                                    id="amount-input"
                                    type="number"
                                    placeholder={t`Enter an amount`}
                                    className="stake-input"
                                    value={quantity}
                                    onChange={onAmountChange}
                                    sx={{
                                      height: "42px",
                                      borderRadius: "60px",
                                      border: "1px solid rgba(138, 147, 160, 0.50)",
                                      background: "#3C3F48",
                                      color: "#c7c8cc",
                                      boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                                    }}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Button
                                          sx={{
                                            color: "#B50C05",
                                            textAlign: "right",
                                            fontFamily: "Euclid Square",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                          }}
                                          variant="text"
                                          onClick={setMax}
                                          color="inherit"
                                        >
                                          <Trans>Max</Trans>
                                        </Button>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              )
                            ) : (
                              <Skeleton width="150px" />
                            )}

                            <TabPanel value={view} index={0} className="stake-tab-panel">
                              {isAllowanceDataLoading ? (
                                <Skeleton />
                              ) : address && hasAllowance("LGNS") && (Number(ethers.utils.formatUnits(BigInt(stakeAllowance || 0), 9)) > (quantity || 0)) ? (
                                <Box
                                  sx={{
                                    p: isSmallScreen ? 2.5 : 0,
                                  }}
                                >
                                  <LoadingButton
                                    className="stake-button main-btn"
                                    variant="contained"
                                    disabled={isPendingTxn(pendingTransactions, "staking")}
                                    loading={isPendingTxn(pendingTransactions, "staking")}
                                    loadingPosition="end"
                                    onClick={() => {
                                      onChangeStake("stake");
                                    }}
                                    sx={{
                                      // width: "100%",
                                      borderRadius: "30px",
                                      border: "1.3px solid #B50C05",
                                      background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                      boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                      color: "#FFFFFF",
                                      fontFamily: "AGaramond LT",
                                      fontSize: "18px",
                                      fontWeight: "700",
                                      mb: isSmallScreen ? "20px !important" : 0,
                                      overflow: "hidden",
                                      "&:hover": {
                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                      },
                                    }}
                                  >
                                    {txnButtonText(pendingTransactions, "staking", t`Stake LGNS`)}
                                  </LoadingButton>
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    p: isSmallScreen ? 2.5 : 0,
                                  }}
                                >
                                  <LoadingButton
                                    className="stake-button"
                                    variant="contained"
                                    disabled={isPendingTxn(pendingTransactions, "approve_staking")}
                                    loading={isPendingTxn(pendingTransactions, "approve_staking")}
                                    loadingPosition="end"
                                    onClick={() => {
                                      onSeekApproval("ohm");
                                    }}
                                    sx={{
                                      borderRadius: "30px",
                                      border: "1.3px solid #B50C05",
                                      background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                      boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                      color: "#FFFFFF",
                                      fontFamily: "AGaramond LT",
                                      fontSize: "18px",
                                      fontWeight: "700",
                                      mb: isSmallScreen ? "20px !important" : 0,
                                      "&:hover": {
                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                      },
                                    }}
                                  >
                                    {txnButtonText(pendingTransactions, "approve_staking", t`Approve`)}
                                  </LoadingButton>
                                </Box>
                              )}
                            </TabPanel>

                            <TabPanel value={view} index={1} className="stake-tab-panel">
                              {isAllowanceDataLoading ? (
                                <Skeleton />
                              ) : address && hasAllowance("sLGNS") && Number(unstakeAllowance) >= Number(ethers.utils.parseUnits((quantity || 0) + '', 9)) ?
                                (
                                  <Box
                                    sx={{
                                      p: isSmallScreen ? 2.5 : 0,
                                    }}
                                  >
                                    <LoadingButton
                                      className="stake-button"
                                      variant="contained"
                                      disabled={isPendingTxn(pendingTransactions, "unstaking")}
                                      loading={isPendingTxn(pendingTransactions, "unstaking")}
                                      loadingPosition="end"
                                      onClick={() => {
                                        onChangeStake("unstake");
                                      }}
                                      sx={{
                                        borderRadius: "30px",
                                        border: "1.3px solid #B50C05",
                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                        color: "#FFFFFF",
                                        fontFamily: "AGaramond LT",
                                        fontSize: "18px",
                                        fontWeight: "700",
                                        mb: isSmallScreen ? "20px !important" : 0,
                                        "&:hover": {
                                          background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                          boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                        },
                                      }}
                                    >
                                      {txnButtonText(pendingTransactions, "unstaking", t`Unstake LGNS`)}
                                    </LoadingButton>
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      p: isSmallScreen ? 2.5 : 0,
                                    }}
                                  >
                                    <LoadingButton
                                      className="stake-button"
                                      variant="contained"
                                      disabled={isPendingTxn(pendingTransactions, "approve_unstaking")}
                                      loading={isPendingTxn(pendingTransactions, "approve_unstaking")}
                                      loadingPosition="end"
                                      onClick={() => {
                                        onSeekApproval("sOhm");
                                      }}
                                      sx={{
                                        borderRadius: "30px",
                                        border: "1.3px solid #B50C05",
                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                        color: "#FFFFFF",
                                        fontFamily: "AGaramond LT",
                                        fontSize: "18px",
                                        fontWeight: "700",
                                        mb: isSmallScreen ? "20px !important" : 0,
                                        "&:hover": {
                                          background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                          boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                        },
                                      }}
                                    >
                                      {txnButtonText(pendingTransactions, "approve_unstaking", t`Approve`)}
                                    </LoadingButton>
                                  </Box>
                                )}
                            </TabPanel>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            px: isSmallScreen ? "20px !important" : 0,
                          }}
                          className={`stake-user-data`}
                        >
                          <div className="data-row">
                            <Typography
                              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                            >
                              <Trans>Unstaked Balance</Trans>
                            </Typography>
                            <Typography
                              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                              id="user-balance"
                            >
                              {isAppLoading ? <Skeleton width="80px" /> : <>{trim2(Number(ohmBalance), 4)} LGNS</>}
                            </Typography>
                          </div>

                          <div className="data-row">
                            <Typography
                              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                            >
                              <Trans>Staked Balance</Trans>
                            </Typography>
                            <Typography
                              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                              id="user-staked-balance"
                            >
                              {isAppLoading ? <Skeleton width="80px" /> : <>{trimmedBalance} sLGNS</>}
                            </Typography>
                          </div>

                          <div className="data-row">
                            <Typography
                              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                            >
                              <Trans>Your Warmup Balance(2 epochs)</Trans>
                            </Typography>
                            <div className="warmup-container">
                              {isAppLoading ? (
                                <Skeleton width="80px" />
                              ) : (
                                <>
                                  <Box
                                    className="warmup-container"
                                    sx={{
                                      color: "#c7c8cc",
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      fontFamily: "AGaramond LT",
                                    }}
                                  >
                                    {trim(Number(gonsBal), 4)} sLGNS
                                    <Box style={{ marginLeft: Number(gonsBal) !== 0 ? "10px" : "0" }}>
                                      {Number(gonsBal) != 0 ? (
                                        isLocked ? (
                                          "(Locked)"
                                        ) : (
                                          <LoadingButton
                                            sx={{
                                              height: "32px",
                                              borderRadius: "30px",
                                              border: "1.3px solid #B50C05",
                                              background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                              boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                            }}
                                            variant="contained"
                                            disabled={isPendingTxn(pendingTransactions, "claim")}
                                            loading={isPendingTxn(pendingTransactions, "claim")}
                                            loadingPosition="end"
                                            onClick={claimWarmupBal}
                                          >
                                            <Typography
                                              sx={{
                                                color: "#FFF",
                                                textAlign: "center",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {txnButtonText(pendingTransactions, "claim", "Claim")}
                                              {/* Claim */}
                                            </Typography>
                                          </LoadingButton>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </div>
                          </div>
                          <Box
                            sx={{
                              width: "100%",
                              height: "1px",
                              background: "#0B0C0A",
                              boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                            }}
                          />

                          <div className="data-row">
                            <Typography
                              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                            >
                              <Trans>Next Reward Amount</Trans>
                            </Typography>
                            <Typography
                              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                            >
                              {isAppLoading ? <Skeleton width="80px" /> : <>{nextRewardValue} sLGNS</>}
                            </Typography>
                          </div>

                          <div className="data-row">
                            <Typography
                              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                            >
                              <Trans>Next Reward Yield</Trans>
                            </Typography>
                            <Typography
                              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                            >
                              {isAppLoading ? <Skeleton width="80px" /> : <>{stakingRebasePercentage}%</>}
                            </Typography>
                          </div>

                          <Box
                            className="data-row"
                            sx={{
                              mb: "20px!important",
                            }}
                          >
                            <Typography
                              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                            >
                              <Trans>ROI (5-Day Rate)</Trans>
                            </Typography>
                            <Typography
                              sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                            >
                              {isAppLoading ? (
                                <Skeleton width="80px" />
                              ) : (
                                <>{trim(toolNumber(Number(fiveDayRate) * 100), 4)}%</>
                              )}
                            </Typography>
                          </Box>
                          <div className="data-row" style={{ textAlign: "center" }}>
                            <Typography
                              sx={{ color: "#868B8F", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                            >
                              <Trans>
                                Note:There is a 2 epochs warm-up staking period, where users must be staked for more than
                                2 epochs before claiming your funds. Users will accumulate rewards while in the warm-up
                                period but will not be able to claim them for 2 epochs. When 2 epochs has elapsed your
                                warmup balance can be claimed from the warmup contract to your wallet and receive the
                                rebase rewards continuously.
                              </Trans>
                            </Typography>
                          </div>
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Paper>
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ width: "100%", mb: 5 }}
              ref={scrollTarget}
            >
              <Calculator stakingRebasePercentage={stakingRebasePercentage} />
            </Box>

            {
              connected ?
                view == 0 ?
                  <StakeHistory address={address} refreshing={refreshing} /> : <UnstakeHistory address={address} refreshing={refreshing} />
                : null
            }

          </Box>
        </Zoom>
      </div>
    </PullToRefresh>
  );
}

export default Stake;
